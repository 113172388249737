import React from "react";

import i18n from "translations/config";

export const getTableColumns = params => {

    const { isCashierReport, formatAmount } = params;
    

    return [
        {
            title: isCashierReport ? i18n.t("common.cashierUsername") : i18n.t("common.betshopName"),
            key: isCashierReport ? "cashierUserName" : "betShopName",
            render: (value) => <span className='rt--title rt--font-normal rt--font-regular'>{value || "-"}</span>
        },
        {
            title: isCashierReport ? i18n.t("common.cashierId") : i18n.t("common.betShopId"),
            key: isCashierReport ? "cashierId" : "id",
            copy: true,
            sorting: !isCashierReport
        },
        ...(
            !isCashierReport ? [{
                title: i18n.t("common.currency"),
                key: "currencyCode"
            }] : []
        ),
        {
            title: i18n.t("common.depositCount"),
            key: "depositCount",
            sorting: !isCashierReport,
            isNumeric: true
        },
        {
            title: i18n.t("common.depositAmount"),
            key: "depositAmount",
            sorting: !isCashierReport,
            render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(value, record.currencyCode)}</span>
        },
        {
            title: i18n.t("common.withdrawCount"),
            key: "withdrawCount",
            sorting: !isCashierReport,
            isNumeric: true,
        },
        {
            title: i18n.t("common.withdrawalAmount"),
            key: "withdrawAmount",
            sorting: !isCashierReport,
            render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(value, record.currencyCode)}</span>
        },
        {
            title: i18n.t("common.netDepositAmount"),
            key: "netDepositAmount",
            sorting: !isCashierReport,
            render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(value, record.currencyCode)}</span>
        },
        {
            title: i18n.t("common.betCount"),
            key: "betCount",
            sorting: !isCashierReport,
            isNumeric: true,
        },
        {
            title: i18n.t("common.betAmount"),
            key: "betAmount",
            sorting: !isCashierReport,
            render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(value, record.currencyCode)}</span>
        },
        {
            title: i18n.t("common.winBetCount"),
            key: "wonCount",
            sorting: !isCashierReport,
            isNumeric: true,
        },
        {
            title: i18n.t("common.winAmount"),
            key: "wonAmount",
            sorting: !isCashierReport,
            render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(value, record.currencyCode)}</span>
        },
        {
            title: i18n.t("common.payoutCount"),
            key: "paidoutCount",
            sorting: !isCashierReport,
            isNumeric: true,
        },
        {
            title: i18n.t("common.payoutAmount"),
            key: "paidoutAmount",
            sorting: !isCashierReport,
            render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(value, record.currencyCode)}</span>
        },
        {
            title: i18n.t("common.bonusBetAmount"),
            key: "bonusBetAmount",
            sorting: !isCashierReport,
            canBeIncluded: true,
            render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(value, record.currencyCode)}</span>
        },
        {
            title: i18n.t("common.bonusWin"),
            key: "bonusWonAmount",
            sorting: !isCashierReport,
            canBeIncluded: true,
            render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(value, record.currencyCode)}</span>
        },
        {
            title: i18n.t("common.bonusRedeem"),
            key: "bonusRedeemAmount",
            sorting: !isCashierReport,
            isNumeric: true,
            canBeIncluded: true
        },
        {
            title: i18n.t("common.wonNotClaimedBetCount"),
            key: "wonNotClaimedBetCount",
            sorting: !isCashierReport,
            render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(value, record.currencyCode)}</span>,
            canBeIncluded: true
        },
        {
            title: i18n.t("common.wonNotClaimedBetAmount"),
            key: "wonNotClaimedBetAmount",
            sorting: !isCashierReport,
            render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(value, record.currencyCode)}</span>,
            canBeIncluded: true
        },
        {
            title: i18n.t("common.cancelledBetCount"),
            key: "cancelledBetsCount",
            sorting: !isCashierReport,
            isNumeric: true,
            canBeIncluded: true
        },
        {
            title: i18n.t("common.cancelledBetAmount"),
            key: "cancelledBetsAmount",
            sorting: !isCashierReport,
            render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(value, record.currencyCode)}</span>,
            canBeIncluded: true
        },
        {
            title: i18n.t("common.registeredPlayers"),
            key: "playerSignups",
            isNumeric: true,
            canBeIncluded: true
        },
        {
            title: i18n.t("common.taxAmount"),
            key: "taxAmount",
            sorting: !isCashierReport,
            render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(value, record.currencyCode)}</span>,
            canBeIncluded: true,
        },
        {
            title: i18n.t("common.totalIn"),
            key: "totalInAmount",
            sorting: !isCashierReport,
            render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(value, record.currencyCode)}</span>,
            canBeIncluded: true,
        },
        {
            title: i18n.t("common.totalOut"),
            key: "totalOutAmount",
            sorting: !isCashierReport,
            render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(value, record.currencyCode)}</span>,
            canBeIncluded: true,
        },
        {
            title: i18n.t("common.ggr"),
            key: "ggr",
            sorting: !isCashierReport,
            render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(value, record.currencyCode)}</span>,
            sortingKey: "gGR"
        },
        {
            title: i18n.t("common.ngr"),
            key: "ngr",
            sorting: !isCashierReport,
            render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(value, record.currencyCode)}</span>,
            sortingKey: "nGR"
        },
    ]
};
