import React from 'react';
import ReactDOM from 'react-dom/client';

import './assets/css/typography.css';
import './assets/css/scss/style.scss';

import './assets/css/ant.less';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);
