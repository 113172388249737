import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import BetshopPerformanceReport from "./betshopPerformanceReport";
import CashierPerformanceReport from "./cashierPerformanceReport";
import Filters from "./filters";

import useIncludedColumns from "hooks/useIncludedColumns";
import useFormat from 'hooks/useFormat';

import { getTableColumns } from "./columns";

/** Betshop Manager Betshop Performance Report Page */

const BetshopManagerBetshopPerformanceReport = ({
    isCashierReport
}) => {

    const { formatAmount } = useFormat();

    const tableName = isCashierReport ? "cashierPerformanceReport" : "betshopPerformanceReport"

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ tableName: tableName });

    /** Table columns */
    const allColumns = getTableColumns({isCashierReport, formatAmount});

    const columnsThatCanBeIncluded = allColumns
        .filter(columnData => columnData.canBeIncluded)
        .map(columnData => ({
            title: columnData.title,
            key: columnData.key,
        }));

    const mainTableColumns = allColumns.filter(columnData => {

        if (columnData.canBeIncluded) {
            return includedColumns.includes(columnData.key);
        }

        return true;
    });

    const columns = {
        mainTableColumns,
        columnsThatCanBeIncluded,
        keepAppliedColumns,
        includedColumns
    }

    return (
        <Fragment>
            {
                !isCashierReport && (
                    <Filters
                        columns={columns}
                    />
                )
            }
            {
                isCashierReport ? (
                    <CashierPerformanceReport
                        columns={columns}
                    />
                ) : (
                    <BetshopPerformanceReport
                        columns={columns}
                    />
                )
            }
        </Fragment>
    )
}

/** BetshopManagerBetshopPerformanceReport propTypes
    * PropTypes
*/
BetshopManagerBetshopPerformanceReport.propTypes = {
    /** Is the report page for cashier or  for betshop */
    isCashierReport: PropTypes.bool
}

export default BetshopManagerBetshopPerformanceReport;