import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Pagination as AntPagination, Select } from 'antd';

/** Pagination Component */
const Pagination = ({
    total,
    onChange,
    page,
    limit,
    loadFn
}) => {

    const { t } = useTranslation();

    const [size, setSize] = useState(20);

    /** Function which will fire on pagination change
       * @function
       * @param { number } e - selected page
       * @memberOf Pagination
   */
    const handleChange = e => {
        onChange({ page: e });
        setTimeout(() => {
            loadFn()
        }, 0)
    }

    /** Function which will fire on pagination size dropdown change
       * @function
       * @param { number } e - size
       * @memberOf Pagination
   */
    const handleSizeChange = e => {
        setSize(parseInt(e));
        onChange({ limit: parseInt(e), page: 1 });
        setTimeout(() => {
            loadFn()
        }, 0)
    }

    return (
        <div className="rt--pagination rt--flex rt--flex-row rt--justify-between rt--align-center rt--pt-24 rt--pb-16">
            <div className="rt--pagination-size rt--flex rt--flex-row rt--align-center">
                <Select 
                    value={limit} 
                    suffixIcon={<i className="icon-down"></i>} 
                    onChange={handleSizeChange}
                    getPopupContainer={trigger => trigger.parentNode}
                >
                    <Select.Option value="20">20</Select.Option>
                    <Select.Option value="50">50</Select.Option>
                    <Select.Option value="100">100</Select.Option>
                    <Select.Option value="200">200</Select.Option>
                    <Select.Option value="300">300</Select.Option>
                    <Select.Option value="500">500</Select.Option>
                </Select>
                {
                    total > 0 && <span className="rt--title rt--font-regular rt--font-normal rt--ml-16">{`${(page - 1)*limit + 1}-${Math.min(page*limit, total)} ${t("common.of")} ${total}`}</span>
                }
                
            </div>
            <AntPagination
                total={total}
                showTitle={false}
                showSizeChanger={false}
                onChange={handleChange}
                pageSize={size}
                current={page}
                itemRender={(page, type, originalElement) => {
                    if (type === "prev") {
                        return <i className="icon-left rt--font-big" />
                    } else if (type === "next") {
                        return <i className="icon-right rt--font-big" />
                    } else return originalElement;
                }}
            />
        </div>
    )
}

/** Pagination propTypes
    * PropTypes
*/
Pagination.propTypes = {
    /** Total count of items */
    total: PropTypes.number,
    /** Current pagination page */
    page: PropTypes.number,
    /** Current pagination perpage limit */
    limit: PropTypes.number,
    /** Function which will fire on any change of pagination */
    onChange: PropTypes.func,
    /** Function to load data on pagination change */
    loadFn: PropTypes.func
}

export default Pagination;