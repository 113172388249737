export default {
    /** Set in localstorage
       * @function
       * @param {string} key - key
       * @param {Object} value - value
   */
    set: (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    },

    /** Clear localstorage
       * @function
   */
    clear: () => {
        localStorage.clear();
    },

    /** Remove from localstorage
       * @function
       * @param {string} key - key
   */
    remove: key => {
        localStorage.removeItem(key);
    },

    /** Get from localstorage
       * @function
       * @param {string} key - key
       * @returns {Object}
   */
    get: key => {
        var data = localStorage.getItem(key);
        if(data === "undefined") return undefined;
        return data ? JSON.parse(data) : data;
    },
    /** Subscribe to  localstorage
       * @function
       * @param {string} key - key
       * @returns {undefined}
   */
    subscribe: (key,callback) => {
        window.addEventListener('storage', (event) => {
            if (event.storageArea != localStorage) return;
            if(event.key === key){
                callback(event.newValue)
            }
            
           });
    }
}