import React, { Fragment } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import BetHistoryTable from "./betHistory";
import Filters from "./filters";

import { getColumns } from "./helpers/getColumns";

import useFormat from "hooks/useFormat";

import { BETSHOP_BET_TYPE } from "constants/common.constants";

import userInfoType from "types/userInfo.type";

/** Bet History Component */
const BetHistory = ({ userInfo }) => {
  const { t } = useTranslation();
  const { formatAmount } = useFormat();

  const canPlaceAnonymousBets =
    BETSHOP_BET_TYPE.ANONYMOUS === userInfo.bettingType;
  const columns = getColumns({ canPlaceAnonymousBets, formatAmount, t });

  return (
    <Fragment>
      <Filters
        columns={columns}
        canPlaceAnonymousBets={canPlaceAnonymousBets}
      />
      <BetHistoryTable columns={columns} userInfo={userInfo} />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.profile.userInfo,
  };
};

BetHistory.propTypes = {
  /** Redux state property, current user info */
  userInfo: userInfoType,
};

export default connect(mapStateToProps)(BetHistory);
