import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import {
    PASSWORD_PATTERN,
    PASSWORD_PATTERN_CONTAINS,
    PASSWORD_PATTERN_RULE
} from 'constants/password.constants';

import passwordSettingsType from 'types/passwordSettings.type';

/** Password Validation Tooltip Component */
const PasswordValidationTooltip = ({ 
    password , 
    passwordSettings 
}) => {

    const { t } = useTranslation();
    
    /** Function to get icon depend on validation result
       * @function
       * @param {bool} result - validation result
       * @memberOf PasswordValidationTooltip
   */
    const getIconContainerClass = useCallback(result => result === null ? '' : result ? 'rt--auth-password-rules-checked' : 'rt--auth-password-rules-wrong', []);
    
    /** Function to get icon container class name on validation result
       * @function
       * @param {bool} result - validation result
       * @memberOf PasswordValidationTooltip
   */
    const getIconClass = useCallback(bool => bool === null ? 'icon-check' + getIconContainerClass(bool) : bool ? `icon-check ${getIconContainerClass(bool)}` : `icon-warning ${getIconContainerClass(bool)}`, []);
    
    /** Function to validate field
       * @function
       * @param {string} value - value to validate
       * @param {object} valObj - pattern object
       * @memberOf PasswordValidationTooltip
   */
    const getResultAndMassage = useCallback((value, valObj) => {
        const retVal = { result: null, massage: null, key: valObj.type}

        if (valObj.value === PASSWORD_PATTERN_RULE.NONE) {
            retVal.massage = t('validation.passwordRulesMustNotContain').replace("%X%", t(`common.passwordSettingsContainType${valObj.type}`));
        } else if (valObj.value === PASSWORD_PATTERN_RULE.MUST) {
            retVal.massage = t('validation.passwordRulesMustContain').replace("%X%", t(`common.passwordSettingsContainType${valObj.type}`))
        }
        if (value === '') { return retVal; }

        switch (valObj.type) {
            case PASSWORD_PATTERN_CONTAINS.UPPERCASE:
                retVal.result = (/[A-Z]/.test(value));
                break;
            case PASSWORD_PATTERN_CONTAINS.LOWERCASE:
                retVal.result = (/[a-z]/.test(value));
                break;
            case PASSWORD_PATTERN_CONTAINS.DIGITS:
                retVal.result = (/[0-9]/.test(value));
                break;
            case PASSWORD_PATTERN_CONTAINS.DASH:
                retVal.result = (/-/.test(value));
                break;
            case PASSWORD_PATTERN_CONTAINS.UNDERSCORE:
                retVal.result = (/_/.test(value));
                break;
            case PASSWORD_PATTERN_CONTAINS.SPACE:
                retVal.result = (/\s/.test(value));
                break;
            case PASSWORD_PATTERN_CONTAINS.SPECIAL:
                retVal.result = (/[!#$%&*+,./:;=?@\\^`|~'"]/.test(value));
                break;
            case PASSWORD_PATTERN_CONTAINS.BRACKETS:
                retVal.result = (/[{([<>\])}]/.test(value));
                break;
        }

        if (valObj.value === PASSWORD_PATTERN_RULE.NONE) { retVal.result = !retVal.result }
        
        return retVal
    }, [])

    /** The list of validation results */
    const validatorList = [
        {
            result: password === '' ? null : password.length >= passwordSettings.passwordMinLimit && password.length <= passwordSettings.passwordMaxLimit,
            massage: t('validation.passwordRulesMustBeBetween').replace("%X%", passwordSettings.passwordMinLimit).replace("%Y%", passwordSettings.passwordMaxLimit),
            key: 0
        }
    ]

    if (passwordSettings.pattern?.type === PASSWORD_PATTERN.CONTAINS) {
        (passwordSettings.pattern?.contain ?? []).forEach(valObj => {
            if (PASSWORD_PATTERN_RULE.MAY === valObj.value) { return }
            validatorList.push(getResultAndMassage(password, valObj))
        })
        if (passwordSettings.pattern?.alsoIncludeCharacters) {
            validatorList.push({
                key: 'alsoIncludeCharacters',
                result: password === '' ? null : passwordSettings.pattern?.alsoIncludeCharacters.split('').every(char => password.includes(char)),
                massage: t('validation.passwordRulesMustInclude').replace("%X%", passwordSettings.pattern?.alsoIncludeCharacters)
            })
        }
    } else if(passwordSettings.pattern?.type === PASSWORD_PATTERN.PATTERN) {
        const reg = new RegExp((passwordSettings.pattern?.regularExpression ?? ""), 'g');
        validatorList.push({
            key: 'regularExpression',
            result: reg.test(password),
            massage: t('validation.passwordRulesMustMatchRegularExpression').replace("%X%", (passwordSettings.pattern?.regularExpression ?? ""))
        })
    }

    return (
        <label className="rt--auth-password-rules">
            <div className='rt--mb-10'>
                <span className='rt--title rt--auth-password-rules-title vs--font-normal vs--font-medium '> {t('common.passwordMustContain')} </span>
            </div>
            <div>
                {
                    validatorList.map(validator => (
                        <div className='rt--auth-password-rules-tooltip-item rt--mb-10 rt--flex rt--align-center' key={validator.key}>
                            <div className={`${getIconContainerClass(validator.result)} rt--auth-password-rules-cont vs--flex vs--align-center vs--justify-center vs--mr-8`}>
                                <i className={getIconClass(validator.result) + " rt--font-bigest"}/>
                            </div>
                            <span className='rt--title rt--font-regular rt--font-normal rt--pl-8'>{validator.massage}</span>
                        </div>
                    ))
                }
            </div>
        </label>
    )
};

/** PasswordValidationTooltip propTypes
    * PropTypes
*/
PasswordValidationTooltip.propTypes = {
    /** The password to validate */
    password: PropTypes.string,
    /** Password settings */
    passwordSettings : passwordSettingsType
}

export default PasswordValidationTooltip;