import PropTypes from 'prop-types';

import { PLAYER_STATE } from 'constants/common.constants';
import { USER_GENDER } from 'constants/user.constants';

export default PropTypes.shape({
    id: PropTypes.string,
    longId: PropTypes.number,
    externalId: PropTypes.string,
    projectId: PropTypes.string,
    parentId: PropTypes.string,
    parentName: PropTypes.string,
    currencyCode: PropTypes.string,
    state: PropTypes.oneOf(Object.values(PLAYER_STATE)),
    stateName: PropTypes.string,
    firstName: PropTypes.string,
    userName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    createdAt: PropTypes.string,
    createdBy: PropTypes.string,
    modifiedAt: PropTypes.string,
    modifiedBy: PropTypes.string,
    gender: PropTypes.oneOf(Object.values(USER_GENDER)),
    balance: PropTypes.number,
    withdrawableBalance: PropTypes.number,
    countryCode: PropTypes.string,
    city: PropTypes.string
})