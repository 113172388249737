import axios from "axios";

import Methods from "constants/httpMethods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { showSuccess } from "utils/message";

import {
    SET_CASHIERS_ACTION_BEFORE,
    SET_CASHIERS_ACTION_FINISH,
    SET_CASHIERS_SAVE_ACTION_BEFORE,
    SET_CASHIERS_SAVE_ACTION_FINISH,
    SET_CASHIERS,
    SET_CASHIERS_SORTING,
    SET_CASHIERS_FILTERS,
    SET_AVAILABLE_CASHIERS_ACTION_BEFORE,
    SET_AVAILABLE_CASHIERS_ACTION_FINISH,
    SET_AVAILABLE_CASHIERS
} from "store/actionTypes";

const setCashiersActionBefore = () => ({
    type: SET_CASHIERS_ACTION_BEFORE,
});

const setCashiersActionFinished = () => ({
    type: SET_CASHIERS_ACTION_FINISH,
});

const setCashiersSaveActionBefore = () => ({
    type: SET_CASHIERS_SAVE_ACTION_BEFORE,
});

const setCashiersSaveActionFinished = () => ({
    type: SET_CASHIERS_SAVE_ACTION_FINISH,
});

const setCashiers = cashiers => ({
    type: SET_CASHIERS,
    payload: { cashiers },
});

export const setCashiersSorting = sorting => ({
    type: SET_CASHIERS_SORTING,
    payload: { sorting },
});

export const setCashiersFilters = filters => ({
    type: SET_CASHIERS_FILTERS,
    payload: { filters },
});


export const getCashiers = id => {
    return (dispatch, getState) => {
        
        let params = {
            betShopId: id,
            ...getState().cashiers.sorting,
            ...getState().cashiers.filters
        };
        
        dispatch(setCashiersActionBefore());

        return axios({
            url: ApiUrls.GET_CASHIERS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: cashiers } }) => {
                dispatch(setCashiers(cashiers));
                dispatch(setCashiersActionFinished());
            })
            .catch((ex) => {
                dispatch(setCashiersActionFinished());
            });
    }
}

export const addBetshopAccess = (betShopId, cashierId, onSuccess) => {
    return dispatch => {
        
        dispatch(setCashiersSaveActionBefore());

        return axios({
            url: ApiUrls.CASHIER_ADD_BETSHOP_ACCESS,
            method: Methods.POST,
            data: {
                betShopId,
                cashierIds: [cashierId]
            },
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    showSuccess(data.message);
                    onSuccess && onSuccess();
                    dispatch(getCashiers(betShopId));
                }
                dispatch(setCashiersSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setCashiersSaveActionFinished());
            });
    }
    
}

export const removeBetshopAccess = (betShopId, cashierId) => {
    return dispatch => {
        
        dispatch(setCashiersSaveActionBefore());

        return axios({
            url: ApiUrls.CASHIER_REMOVE_BETSHOP_ACCESS,
            method: Methods.POST,
            data: {
                id: cashierId,
                betShopIds: [betShopId]
            },
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    showSuccess(data.message);
                    dispatch(getCashiers(betShopId));
                }
                dispatch(setCashiersSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setCashiersSaveActionFinished());
            });
    }
    
}

export const changeCashierState = (id, enabled, betShopId) => {
    return dispatch => {
        
        dispatch(setCashiersSaveActionBefore());

        return axios({
            url: ApiUrls.CASHIER_CHANGE_STATE,
            method: Methods.POST,
            data: {
                id,
                enabled
            },
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    showSuccess(data.message);
                    dispatch(getCashiers(betShopId));
                }
                dispatch(setCashiersSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setCashiersSaveActionFinished());
            });
    }
    
}


const setManagerAvailableCashiersActionBefore = () => ({
    type: SET_AVAILABLE_CASHIERS_ACTION_BEFORE,
});

const setManagerAvailableCashiersActionFinished = () => ({
    type: SET_AVAILABLE_CASHIERS_ACTION_FINISH,
});

const setManagerAvailableCashiers = cashiers => ({
    type: SET_AVAILABLE_CASHIERS,
    payload: { cashiers },
});

export const getManagerAvailableCashiers = id => {
    return dispatch => {
        
        dispatch(setManagerAvailableCashiersActionBefore());

        return axios({
            url: ApiUrls.GET_MANAGER_AVAILABLE_CASHIERS,
            method: Methods.GET,
            params: { id },
        })
            .then(({ data: { value: cashiers } }) => {
                dispatch(setManagerAvailableCashiers(cashiers));
                dispatch(setManagerAvailableCashiersActionFinished());
            })
            .catch((ex) => {
                dispatch(setManagerAvailableCashiersActionFinished());
            });
    }
}