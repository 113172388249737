import React from 'react';
import { useTranslation } from 'react-i18next';

/** Auth Sidebar component */
const Sidebar = () => {
    const { t } = useTranslation();

    return (
        <div className='rt--auth-sidebar rt--flex rt--justify-center'>
            <div className='rt--auth-sidebar-wrap rt--align-center'>
                
            </div>
        </div>
    )
}

export default Sidebar;
