import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setProvider } from 'store/actions/dashboard/providers.action';

import Dropdown from 'components/common/dropdown';

import { PROJECT_PROVIDER_TYPE } from 'constants/common.constants';

import userInfoType from 'types/userInfo.type';

const providersMapping = {
    [PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS]: "common.virtualSport",
    [PROJECT_PROVIDER_TYPE.SPORTBOOK]: "common.sportsBook",
    [PROJECT_PROVIDER_TYPE.GOLDEN_RACE]: "common.goldenRace"
}

/** Providers section in header */
const Providers = ({
    provider,
    setProvider,
    userInfo
}) => {
    const { t } = useTranslation();

    let navigate = useNavigate();

    /** Set default provider */
    useEffect(() => {
        if(userInfo.providers){
            const providerFromQueryString = Number((new URLSearchParams(window.location.search)).get("provider"));
            if(providerFromQueryString){
                if(userInfo.providers.includes(providerFromQueryString)){
                    setProvider(providerFromQueryString);
                } else {
                    setProvider(userInfo.providers[0]);
                }
            } else {
                if(userInfo.providers.length > 0){
                    setProvider(userInfo.providers[0]);
                }
            }
        }
    }, [userInfo.providers])

    /** Function to call on provider click
		 * @function
         * @param {number} type - provider type
		 * @memberOf Providers
    */
    const clickHandler = type => {
        setProvider(type)
        navigate({ pathname: '', search: '?provider=' + type })
    }

    const providers = userInfo?.providers ?? [];

    return (
        <div className='rt--cashier-header-list rt--flex rt--flex-col'>
            <div className='rt--cashier-header-list-header rt--flex rt--align-center rt--justify-center'>
                <span className='rt--title rt--font-normal rt--font-regular'>{t('common.providers')}</span>
            </div>
            <div className='rt--cashier-header-list-content rt--justify-between rt--flex rt--flex-equal'>
                {
                    providers.length <= 2 ? (
                        <Fragment>
                            {
                                providers.map(item => (
                                    <div
                                        className={'rt--cashier-header-list-content-item rt--flex rt--align-center rt--justify-center rt--flex-equal rt--cursor-pointer' + (provider === item ? " rt--cashier-header-list-content-item-active" : "")}
                                        onClick={() => clickHandler(item)}
                                        key={item}
                                    >
                                        <span className='rt--title rt--font-normal rt--font-big rt--text-center'>{t(providersMapping[item])}</span>
                                    </div>
                                ))
                            }
                        </Fragment>
                    ) : (
                        <div
                            className='rt--cashier-header-list-content-item rt--flex rt--align-center rt--justify-center rt--flex-equal rt--cursor-pointer'
                        >
                            <Dropdown
                                element={
                                    <Fragment>
                                        <span className='rt--title rt--font-normal rt--font-big rt--text-center'>{t(providersMapping[provider])}</span>
                                        <i className='icon-down rt--font-bigest' />
                                    </Fragment>
                                }
                                items={
                                    providers.map(item => ({
                                        key: item,
                                        label: t(providersMapping[item])
                                    }))
                                }
                                onClick={clickHandler}
                                overlayClassName="rt--cashier-header-list-providers-dropdown"
                            />
                        </div>
                    )
                }
                
            </div>
        </div>
    )

}

/** Providers propTypes
    * PropTypes
*/
Providers.propTypes = {
    /** Redux action to set provider */
    setProvider: PropTypes.func,
    /** Redux state property, current provider */
    provider: PropTypes.oneOf(Object.values(PROJECT_PROVIDER_TYPE)),
    /** Redux state property, current user info */
    userInfo: userInfoType
}

const mapStateToProps = state => {
    return {
        provider: state.providers.provider,
        userInfo: state.profile.userInfo
    }
}
const mapDispatchToProps = dispatch => (
    {
        setProvider: id => {
            dispatch(setProvider(id))
        }
    }
)
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Providers
);
