import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';

import { Select, Input } from 'antd';

import DateRangePicker from "components/common/dateRangePicker";

import { isFormChanged } from "utils/form";
import {
    yesterday,
    getNow,
    monthAgo,
    tomorrow,
    firstDayOfCurrentMonth,
    firstDayOfCurrentYear,
    getDefaultDate
} from 'utils/dateTime';

import { TIME_PICKER_MODES } from 'components/common/dateRangePicker/constants';

import { DATE_PICKER_RANGES, DATE_FORMAT, TIME_FORMAT } from 'constants/common.constants';

/** Table Filters Component */
const Filters = ({
    loadFn,
    setFiltersFn,
    filters,
    useDateWithTime,
    controls,
    visible,
}) => {

    const { t } = useTranslation();

    /** Fields current values */
    const [fieldsValue, setFieldsValue] = useState({ ...filters })

    /** Initial filters */
    const [initial, setInitial] = useState(filters);

    useEffect(() => {
        return () => {
            resetFilters();
        }
    }, [])

    /** Set default filters on filters open */
    useEffect(() => {
        if (visible && useDateWithTime) {
            if (moment(filters.to) - moment(filters.from) === 1000 * 60 * 60 * 24) { // last 24 hours
                let fltrs = {
                    ...filters,
                    ...getDefaultDate()
                }

                setFiltersFn(fltrs);
            }
        }
    }, [visible])

    /** Reset to initial on component unmount */
    useEffect(() => {
        return () => resetFilters()
    }, [])

    /** Reset Filters
       * @function
       * @memberOf Filters
   */
    const resetFilters = () => {
        const FORMAT = useDateWithTime ? `${DATE_FORMAT} ${TIME_FORMAT}` : DATE_FORMAT;
        const TIME = moment(getNow(true), TIME_FORMAT);
        let from_d, to_d;
        if (useDateWithTime) {
            from_d = yesterday(true);
            to_d = moment(getNow(true), FORMAT);
            from_d.set({ hour: TIME.get('hour'), minute: TIME.get('minute') });
            to_d.set({ hour: TIME.get('hour'), minute: TIME.get('minute') })
        } else {
            from_d = moment(getNow(true), FORMAT);
            to_d = tomorrow()
        }


        setFiltersFn({
            ...initial,
            from: useDateWithTime ? from_d.toDate() : from_d.format(DATE_FORMAT),
            to: useDateWithTime ? to_d.toDate() : to_d.format(DATE_FORMAT),
        });
    }

    /** Handle period change */
    useEffect(() => {
        const FORMAT = useDateWithTime ? `${DATE_FORMAT} ${TIME_FORMAT}` : DATE_FORMAT;
        let from_d, to_d;

        let today = moment(getNow(true), FORMAT);

        if (useDateWithTime) {
            to_d = today;
        } else {
            to_d = tomorrow()
        }

        switch (fieldsValue.period) {
            case DATE_PICKER_RANGES.TODAY:
                from_d = today.clone().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                to_d = tomorrow().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                break;
            case DATE_PICKER_RANGES.YESTERDAY:
                from_d = yesterday().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                to_d = today.clone().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                break;
            case DATE_PICKER_RANGES.THIS_MONTH:
                from_d = firstDayOfCurrentMonth().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                to_d = tomorrow().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                break;
            case DATE_PICKER_RANGES.LAST_MONTH:
                from_d = today.clone().subtract(1, 'months').startOf('month').set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                to_d = today.clone().subtract(1, 'months').endOf('month').add(1, 'days').set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                break;
            case DATE_PICKER_RANGES.LAST_3_MONTH:
                from_d = monthAgo(3).add(1, 'days').set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                to_d = tomorrow().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                break;
            case DATE_PICKER_RANGES.YTD:
                from_d = firstDayOfCurrentYear().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                to_d = tomorrow().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                break;
            default:
                from_d = yesterday(true);
        }

        setFieldsValue({
            ...fieldsValue,
            from: from_d ? useDateWithTime ? from_d.toDate() : from_d.format(DATE_FORMAT) : "",
            to: to_d ? useDateWithTime ? to_d.toDate() : to_d.format(DATE_FORMAT) : "",
        });

    }, [fieldsValue.period])

    /** Function, fires on reset button click
       * @function
       * @memberOf Filters
   */
    const doReset = () => {
        if (!isFormChanged(fieldsValue, initial)) return;
        resetFilters();
        setTimeout(() => {
            const values = {
                ...initial
            }
            setFieldsValue(values);
            loadFn();
        })
    }

    /** Function, fires on apply button click
       * @function
       * @memberOf Filters
   */
    const doApply = () => {
        if (!isFormChanged(fieldsValue, filters)) return;
        setFiltersFn(fieldsValue);
        setTimeout(() => {
            loadFn()
        }, 0)
    }

    return (
        <div
            className={'rt--filters rt--flex rt--justify-between rt--align-end rt--mt-24'}
            data-open={visible}
        >
            <div className='rt--flex rt--align-center rt--flex-wrap'>
                {
                    controls.map(control => (
                        <div
                            key={control.name}
                            className={'rt--filters-item rt--mr-16 rt--mb-10' + (control.small ? " rt--filters-item-small" : "")}
                        >
                            <div className='rt--mb-8'>
                                <span className="rt--filters-item-title rt--title vs--font-regular vs--font-normal rt--font-capitalize">
                                    {
                                        control.title
                                    }
                                </span>
                            </div>
                            {
                                control.type === "SELECT" ? (
                                    <Select
                                        className='rt--select'
                                        placeholder={t('common.selectRange')}
                                        value={fieldsValue[control.name]}
                                        suffixIcon={<i className="icon-down rt--font-bigest"></i>}
                                        onChange={e => {
                                            setFieldsValue({ ...fieldsValue, [control.name]: e });
                                            control.onChange && control.onChange(e, (field, value) => {
                                                setFieldsValue(prevState => ({
                                                    ...prevState,
                                                    [field]: value
                                                }))
                                            })
                                        }}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        showSearch={false}
                                    >
                                        {
                                            control.items.map(item => (
                                                <Select.Option key={item.value} value={item.value}>{item.title}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                ) : control.type === "RANGEPICKER" ? (
                                    <DateRangePicker
                                        showTime={useDateWithTime}
                                        timePickerMode={control.mode}
                                        value={[moment(fieldsValue["from"]), moment(fieldsValue["to"])]}
                                        onChange={e => {
                                            setFieldsValue({
                                                ...fieldsValue,
                                                from: useDateWithTime ? e[0].toDate() : e[0].format(control.mode === TIME_PICKER_MODES.ONLY_HOURS ? `${DATE_FORMAT} ${TIME_FORMAT}` : DATE_FORMAT),
                                                to: useDateWithTime ? e[1].toDate() : e[1].format(control.mode === TIME_PICKER_MODES.ONLY_HOURS ? `${DATE_FORMAT} ${TIME_FORMAT}` : DATE_FORMAT),
                                            })
                                        }}
                                        initialValues={{
                                            from: filters.from ? moment(filters.from) : null,
                                            to: filters.to ? moment(filters.to) : null
                                        }}
                                    />
                                ) : control.type === "INPUT" ? (
                                    <Input
                                        className='rt--input'
                                        value={fieldsValue[control.name]}
                                        onChange={e => {
                                            setFieldsValue({ ...fieldsValue, [control.name]: e.target.value });
                                        }}
                                        placeholder={control.placeholder}
                                    />
                                ) : null
                            }

                        </div>
                    ))
                }
            </div>

            <div className='rt--flex rt--align-center rt--justify-end'>
                <button
                    className={"rt--button-text rt--button rt--flex-equal" + (!isFormChanged(fieldsValue, initial) ? " rt--button-disabled" : "")}
                    onClick={doReset}
                >
                    <span>{t('common.reset')}</span>
                </button>
                <button
                    className={"rt--button rt--button-primary rt--flex-equal rt--ml-16" + (!isFormChanged(fieldsValue, filters) ? " rt--button-disabled" : "")}
                    onClick={doApply}
                >
                    <span>{t('common.apply')}</span>
                </button>
            </div>
        </div>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Function to load date */
    loadFn: PropTypes.func,
    /** Function to set filters */
    setFiltersFn: PropTypes.func,
    /** Filters */
    filters: PropTypes.object,
    /** Is the filters form has date picker with time  */
    useDateWithTime: PropTypes.bool,
    /** Array of filters form controls */
    controls: PropTypes.arrayOf(PropTypes.shape({
        /** Control title */
        title: PropTypes.string,
        /** Control name */
        name: PropTypes.string,
        /** Control type */
        type: PropTypes.oneOf(["SELECT", "INPUT", "RANGEPICKER"]),
        /** Is Control has small size */
        small: PropTypes.bool,
        /** Select Control options */
        items: PropTypes.arrayOf(PropTypes.shape({
            /** Option value */
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            /** Option title */
            title: PropTypes.string
        }))
    })),
    /** Is filters compoennt opened */
    visible: PropTypes.bool,
}

export default Filters;
