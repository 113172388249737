import { combineReducers } from 'redux';

import AuthReducer from './auth/auth.reducer';
import BetshopsReducer from './auth/betshops.reducer';
import ProfileReducer from './dashboard/profile.reducer';
import ProviderReducer from './dashboard/providers.reducer';
import CommonReducer from './dashboard/common.reducer';
import TicketReducer from './dashboard/ticket.reducer';
import BetHistoryReducer from './dashboard/betHistory.reducer';
import ManagerBetshopsReducer from './dashboard/managerBetshops.reducer';
import CashiersReducer from './dashboard/cashiers.reducer';
import SessionsReducer from './dashboard/sessions.reducer';
import TransactionsReducer from './dashboard/transactions.reducer';
import BetshopPerformanceReportReducer from './dashboard/betshopPerformanceReport.reducer';
import CashierPerformanceReportReducer from './dashboard/cashierPerformanceReport.reducer';
import WalletReducer from './dashboard/wallet.reducer';
import HandoverReducer from './dashboard/handover.reducer';
import PlayersReducer from './dashboard/players.reducer';

const reducer = () => combineReducers({
    auth: AuthReducer,
    betshops: BetshopsReducer,
    profile: ProfileReducer,
    providers: ProviderReducer,
    common: CommonReducer,
    ticket: TicketReducer,
    betHistory: BetHistoryReducer,
    managerBetshops: ManagerBetshopsReducer,
    cashiers: CashiersReducer,
    sessions: SessionsReducer,
    transactions: TransactionsReducer,
    betshopPerformanceReport: BetshopPerformanceReportReducer,
    cashierPerformanceReport: CashierPerformanceReportReducer,
    wallet: WalletReducer,
    handover: HandoverReducer,
    players: PlayersReducer
});

export default reducer;