import axios from 'axios';

import { isAuthenticated, getUser, logout, refreshToken } from 'utils/auth';
import LanguageUtils from 'utils/languages';
import { showError } from 'utils/message';

import { RESPONSE_CODES } from 'constants/errorCode.constants';

axios.defaults.baseURL = import.meta.env.SYSTEM_API_URL;

axios.interceptors.request.use( config => {

    // if(config.method === "get"){
    //     config.url = config.url.indexOf("?") > -1 ? config.url + "&timestump=" + Date.now() : config.url + "?timestump=" + Date.now()
    // }

    // set current language
    const currentLang = LanguageUtils.getSelectedLanguage();
    if (currentLang) {
        config.headers['Accept-Language'] = currentLang;
    }


    if(isAuthenticated()){
        if (config?.headers?.grant_type !== 'refresh_token') {
            const token = getUser()?.token;
            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }

            //Set default content type
            if (!config.headers['Content-Type'] || config.headers['Content-Type'] === 'text/plain;charset=utf-8') {
                config.headers['Content-Type'] = 'application/json;charset=utf-8';
            }

        } else {
            delete config.headers['grant_type'];
        }

        // Trim params and data

        if(config.params){
            const newParams = {}
            Object.keys(config.params).map(key => {
                if(typeof config.params[key] === "string"){
                    newParams[key] = config.params[key].trim()
                } else {
                    newParams[key] = config.params[key]
                }
            })
        }
        if(config.data && !(config.data instanceof FormData)){
            try{
                const newData = {};
                let parsed = config.data;
                if(typeof config.data === 'string'){
                    try{
                        parsed = JSON.parse(config.data);
                    }catch(ex){console.log("err")}
                }

                Object.keys(parsed).map(key => {
                    if(typeof parsed[key] === "string"){
                        newData[key] = parsed[key].trim()
                    } else {
                        newData[key] = parsed[key]
                    }
                })
                config.data = newData;
            }catch(ex){console.log(ex)}
        }
    }

    return config;
}, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use( ({ status , data }) => {
    if(data?.resultCode !== RESPONSE_CODES.Success && !(data instanceof Blob) && !(data instanceof Array) ){
        showError(data?.message ?? "", data?.resultCode ?? 0, data?.resource ?? "");
    }
    return { status, data };
}, error => {
    let status = error.response.status;
    let errorData = error.response.data;

    const isBlobResponseType = error.request.responseType === 'blob' && error.response.data instanceof Blob && error.response.data.type && error.response.data.type.toLowerCase().indexOf('json') != -1

    if(status >= 400) {
        if(errorData?.resultCode === RESPONSE_CODES.Unauthorized || isBlobResponseType){
            if(status === 401){
                const refresh_token = getUser()?.refreshToken;
                const userName = getUser()?.userName;
                if(refresh_token) {
                    const config = error.response.config;
                    return refreshToken(refresh_token, userName, config);
                } else {
                    logout();
                }
            } else if ( status === 400 && !isBlobResponseType){
                logout();
            }
        } else {
            showError(errorData?.message ?? "", errorData?.resultCode ?? 0, errorData?.resource ?? "");
        }
    }
    return Promise.reject(error);
});
