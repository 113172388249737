import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Filters from "components/common/filters";
import Export from "components/common/export";

import { setSessionsFilters, getSessions } from "store/actions/dashboard/sessions.action";
import { getManagerAvailableBetshops } from "store/actions/dashboard/managerBetshops.action";
import { getManagerAvailableCashiers } from "store/actions/dashboard/cashiers.action";

import { DATE_PICKER_RANGES } from 'constants/common.constants';
import { USER_TYPE } from "constants/user.constants";
import ApiUrls from "constants/api.constants";

import managerAvailableBetshopType from 'types/managerAvailableBetshop.type';
import autoSuggestionType from 'types/autoSuggestion.type';

/** Betshop Manager Sessions Page Sessions Table Filters Component */
const SessionsFilters = ({
    setSessionsFilters,
    getSessions,
    filters,
    getManagerAvailableBetshops,
    getManagerAvailableCashiers,
    availableBetshops,
    availableCashiers,
    columns
}) => {

    const { t } = useTranslation();

    /** Flag to hide/show filters */
    const [opened, setOpened] = useState(false);

    const mappedFilters = {
        from: filters.from,
        to: filters.to,
        betShopId: filters.betShopId,
        period: filters.period,
        userNameOrId: filters.userNameOrId
    }

    /** Reset filter userNameOrId field on component unmount */
    useEffect(() => () => setSessionsFilters({ userNameOrId: "" }), [])

    /** Get manager available betshops and cashiers */
    useEffect(() => {
        getManagerAvailableBetshops();
        getManagerAvailableCashiers();
    }, [])

    return (
        <div className='rt--filters-header rt--mt-32 rt--pb-16 rt--mb-30'>
            <div className='rt--flex rt--justify-between rt--align-center'>
                <div className='rt--flex rt--align-center rt--filters-header-title'>
                    <span className='rt--title rt--font-big rt--font-regular'>{t("common.cashierSessions")}</span>
                </div>

                <div className='rt--flex'>
                    <Export
                        filters={{
                            ...mappedFilters,
                            userRole: USER_TYPE.CASHIER
                        }}
                        title={t("common.cashierSessions")}
                        columns={columns}
                        url={ApiUrls.EXPORT_CASHIER_SESSIONS}
                        tableName={t("common.cashierSessions")}
                    />

                    <button
                        className='rt--button rt--button-secondary rt--ml-16'
                        onClick={() => setOpened(!opened)}
                    >
                        <span className='rt--flex rt--justify-center rt--align-center'>
                            <i className='icon-filter rt--font-bigest'></i>
                            <span className='rt--title rt--font-medium rt--font-normal'>{t("common.filters")}</span>
                        </span>
                    </button>
                </div>
            </div>
            <Filters
                loadFn={getSessions}
                setFiltersFn={setSessionsFilters}
                filters={{
                    from: filters.from,
                    to: filters.to,
                    betShopId: filters.betShopId,
                    period: filters.period,
                    userNameOrId: filters.userNameOrId
                }}
                useDateWithTime={true}
                controls={[
                    {
                        title: t('common.timePeriod'),
                        name: "period",
                        type: "SELECT",
                        small: true,
                        items: [
                            { value: DATE_PICKER_RANGES.TODAY, title: t('common.today') },
                            { value: DATE_PICKER_RANGES.YESTERDAY, title: t('common.yesterday') },
                            { value: DATE_PICKER_RANGES.THIS_MONTH, title: t('common.thisMonth') },
                            { value: DATE_PICKER_RANGES.LAST_MONTH, title: t('common.lastMonth') },
                            { value: DATE_PICKER_RANGES.LAST_3_MONTH, title: t('common.3Months') },
                        ]
                    },
                    {
                        title: t('common.dateRange'),
                        name: "date",
                        type: "RANGEPICKER",
                    },
                    {
                        title: t('common.betshop'),
                        name: "betShopId",
                        type: "SELECT",
                        items: [
                            { value: "", title: t('common.all') },
                            ...availableBetshops.map(b => ({
                                value: b.id, title: b.name
                            }))
                        ],
                        onChange: (value, updateForm) => {
                            setSessionsFilters({
                                userNameOrId: ""
                            })
                            updateForm("userNameOrId", "")
                            getManagerAvailableCashiers(value)
                        }
                    },
                    {
                        title: t('common.cashier'),
                        name: "userNameOrId",
                        type: "SELECT",
                        items: [
                            { value: "", title: t('common.all') },
                            ...availableCashiers.map(b => ({
                                value: b.id, title: b.name
                            }))
                        ]
                    },
                ]}
                visible={opened}
            />
        </div>

    )
}

/** SessionsFilters propTypes
    * PropTypes
*/
SessionsFilters.propTypes = {
    /** Redux action to get sessions */
    getSessions: PropTypes.func,
    /** Redux action to set sessions filters */
    setSessionsFilters: PropTypes.func,
    /** Redux state property, sessions filters */
    filters: PropTypes.object,
    /** Redux action to get betshop manager available betshops*/
    getManagerAvailableBetshops: PropTypes.func,
    /** Redux state property, available betshops for betshop manager */
    availableBetshops: PropTypes.arrayOf(managerAvailableBetshopType),
    /** Redux action to get available cashiers for manager */
    getManagerAvailableCashiers: PropTypes.func,
    /** Redux state property, available cashiers for manager */
    availableCashiers: PropTypes.arrayOf(autoSuggestionType),
    /** Columns for table */
    columns: PropTypes.arrayOf(PropTypes.object)
}

const mapDispatchToProps = dispatch => (
    {
        getSessions: () => {
            dispatch(getSessions());
        },

        setSessionsFilters: filters => {
            dispatch(setSessionsFilters(filters));
        },

        getManagerAvailableBetshops: () => {
            dispatch(getManagerAvailableBetshops())
        },

        getManagerAvailableCashiers: id => {
            dispatch(getManagerAvailableCashiers(id))
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.sessions.filters,
        availableBetshops: state.managerBetshops.availableBetshops,
        availableCashiers: state.cashiers.availableCashiers,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionsFilters);
