import PropTypes from 'prop-types';

import { BET_STATE, BET_TYPE } from 'constants/bets.constants';
import { PROJECT_PROVIDER_TYPE } from 'constants/common.constants';

import ticketInfoType from './ticketInfo.type'

export default PropTypes.shape({
    amount: PropTypes.number,
    betTime: PropTypes.string,
    betType: PropTypes.oneOf(Object.values(BET_TYPE)),
    calculateTime: PropTypes.string,
    currencyCode: PropTypes.string,
    externalBetSlipId: PropTypes.number,
    factor: PropTypes.number,
    ggr: PropTypes.number,
    id: PropTypes.number,
    paidoutBy: PropTypes.string,
    paidoutTime: PropTypes.string,
    possibleWin: PropTypes.number,
    projectId: PropTypes.number,
    projectName: PropTypes.string,
    provider: PropTypes.oneOf(Object.values(PROJECT_PROVIDER_TYPE)),
    status: PropTypes.oneOf(Object.values(BET_STATE)),
    userId: PropTypes.number,
    userName: PropTypes.string,
    winning: PropTypes.number,
    bets: PropTypes.arrayOf(ticketInfoType)
})