import { BROADCAST_CHANNEL_NAME } from "constants/common.constants";

/** Post message to the provided domain 
     * @function
     * @param {string} domain - The domain where the messaged will be posted
     * @param {string} actionType - Action type
     * @param {string} payload - Payload
 */
export const postMessage = (actionType, payload = {}, win = window) => {

    let data = {
        action: actionType,
        payload: payload
    }
    win.postMessage(JSON.stringify(data), '*');
}
/** Post message to the provided domain 
     * @function
     * @param {string} domain - The domain where the messaged will be posted
     * @param {string} actionType - Action type
     * @param {string} payload - Payload
 */
export const sendBroadcast = (type = '', payload = {}) => {
    const bc = new BroadcastChannel(BROADCAST_CHANNEL_NAME);
    const broadcastedData = JSON.stringify({ type, payload })
    bc.postMessage(broadcastedData);
}

/** Listen to bradcast channel 
     * @function
     * @param {function} callback - function to call on message event
 */
export const listenBroadcast = callback => {
    const bc = new BroadcastChannel(BROADCAST_CHANNEL_NAME);
    bc.onmessage = (ev) => {
        callback(JSON.parse(ev.data))
    }
}




