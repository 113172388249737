import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Modal } from 'antd';

import Loader from "components/common/loader";

import { getManagerBetshopAvailableCashiersForAdd } from 'store/actions/dashboard/managerBetshops.action';
import { addBetshopAccess } from 'store/actions/dashboard/cashiers.action';

import { POPUP_SIZE } from 'constants/popup.constants';

import betshopAvailableCashierType from 'types/betshopAvailableCashier.type';

import noCashierImg from "assets/images/noCashier.png";

/** Add Cashier Popup Component */
const AddCashierPopup = ({
    handleCloseModal,
    getManagerBetshopAvailableCashiersForAdd,
    availableCashiersForAdd,
    isAvailableCashiersLoading,
    addBetshopAccess,
    isSaving
}) => {
    const { t } = useTranslation()

    const searchParams = useParams();

    const [selected, setSelected] = useState(null)

    /** Get betshop manager available cashiers to add to betshop */
    useEffect(() => {
        getManagerBetshopAvailableCashiersForAdd(searchParams.id)
    }, [])

    /** Set default cashier to add */
    useEffect(() => {
        if (availableCashiersForAdd.length > 0) {
            setSelected(availableCashiersForAdd[0].id)
        }
    }, [availableCashiersForAdd])

    return (
        <Modal
            className="rt--modal"
            title={(
                <div className='rt--flex rt--justify-between rt--align-center'>
                    <span className='rt--title rt--font-normal rt--font-regular'>{t("common.addCashier")}</span>
                    <i className='icon-close rt--font-bigest rt--cursor-pointer' onClick={handleCloseModal} />
                </div>
            )}
            visible={true}
            onCancel={() => handleCloseModal()}
            maskClosable={false}
            closable={false}
            onOk={() => { 
                addBetshopAccess(searchParams.id, selected, handleCloseModal)
            }}
            cancelButtonProps={{ className: 'rt--button-secondary' }}
            okButtonProps={{ disabled: isSaving || availableCashiersForAdd.length === 0 }}
            cancelText={t('common.cancel')}
            okText={t('common.add')}
            getContainer={() => document.getElementById('rt--modal-root')}
            width={POPUP_SIZE.NORMAL}
            centered
        >
            <div className='rt--modal-select-cashier'>
                <span className='rt--title rt--font-regular rt--font-big rt--modal-desc'>{t("common.addCashierInfo")}</span>
                {
                    !isAvailableCashiersLoading ? (
                        <div className='rt--flex rt--flex-wrap'>
                            {
                                availableCashiersForAdd.length > 0 ? availableCashiersForAdd.map(cashier => (
                                    <div 
                                        className={'rt--modal-select-cashier-item rt--mt-16 rt--mr-16 rt--pt-12 rt--pb-12 rt--pl-16 rt--pr-16' + (selected === cashier.id ? " rt--modal-select-cashier-item-selected" : "") }
                                        key={cashier.id}
                                        onClick={() => setSelected(cashier.id)}
                                    >
                                        <div className='rt--flex rt--align-center rt--justify-between'>
                                            <div className='rt--flex rt--flex-equal rt--hide-overflow'>
                                                <div className='rt--modal-select-cashier-item-img rt--flex rt--justify-center rt--align-center rt--mr-8'>
                                                    <i className='icon-user rt--font-bigest'></i>
                                                </div>
                                                <div className='rt--flex rt--flex-col rt--hide-overflow'>
                                                    <span className='rt--title rt--font-normal rt--font-regular rt--mb-2'>{`${cashier.firstName} ${cashier.lastName}`}</span>
                                                    <span className='rt--title rt--font-small rt--font-regular'>{cashier.userName}</span>
                                                </div>
                                            </div>
                                            {
                                                selected === cashier.id && (
                                                    <div className='rt--ml-8 rt--modal-select-cashier-item-circle'>
                                                        <i className='icon-checked-circle rt--font-bigest'></i>
                                                    </div>
                                                )
                                            }

                                        </div>
                                    </div>
                                )) : (
                                    <div className='rt--modal-select-cashier-empty rt--flex rt--flex-col rt--align-center rt--justify-center rt--pt-24 rt--pb-24'>
                                        <img alt={t("common.noCashiersFound")} src={noCashierImg} />
                                        <span className='rt--title rt--pt-8 rt--text-center rt--font-medium rt--font-big'>{t("common.noCashiersFound")}</span>
                                    </div>
                                )
                            }

                        </div>
                    ) : <Loader />
                }

            </div>
        </Modal>
    )
}

/** AddCashierPopup propTypes
    * PropTypes
*/
AddCashierPopup.propTypes = {
    /** Function to close popup */
    handleCloseModal: PropTypes.func,
    /** Redux action to get available cashiers to add to betshop for betshop manager */
    getManagerBetshopAvailableCashiersForAdd: PropTypes.func,
    /** Redux state property, available cashiers to add to betshop for betshop manager */
    availableCashiersForAdd: PropTypes.arrayOf(betshopAvailableCashierType),
    /** Redux state property, is true when loading available cashiers to add to betshop for betshop manager */
    isAvailableCashiersLoading: PropTypes.bool,
    /** Redux action to add betshop access to cashier */
    addBetshopAccess: PropTypes.func,
    /**Redux state property, is true when betshop access adding request is in process */
    isSaving: PropTypes.bool
}

const mapStateToProps = state => {
    return {
        availableCashiersForAdd: state.managerBetshops.availableCashiersForAdd,
        isAvailableCashiersLoading: state.managerBetshops.isAvailableCashiersLoading,
        isSaving: state.cashiers.isSaving
    }
}

const mapDispatchToProps = dispatch => (
    {
        getManagerBetshopAvailableCashiersForAdd: id => {
            dispatch(getManagerBetshopAvailableCashiersForAdd(id))
        },

        addBetshopAccess: (betShopId, cashierId, onSuccess) => {
            dispatch(addBetshopAccess(betShopId, cashierId, onSuccess))
        }
    }
)


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    AddCashierPopup
);