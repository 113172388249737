import axios from "axios";

import { initTranslations } from 'translations/config';

import { getSystemLanguages } from 'store/actions/auth/language.action';

import Methods from "constants/httpMethods.constants";
import ApiUrls from "constants/api.constants";

import LanguageUtils from 'utils/languages';
import { isResponseSuccess } from "utils/request";
import { setUrlParam } from 'utils/common';
import sessionStorage from "utils/sessionStorage";

import {
    SET_USER_INFO_ACTION_BEFORE,
    SET_USER_INFO_ACTION_FINISH,
    SET_USER_INFO,
    SET_BALANCE,
    SET_LIMIT,
    SET_USER_AVAILABLE_CURRENCIES_ACTION_BEFORE,
    SET_USER_AVAILABLE_CURRENCIES_ACTION_FINISH,
    SET_USER_AVAILABLE_CURRENCIES,
    SET_CURRENT_PLAYER
} from "store/actionTypes";

const setUserInfoActionBefore = () => ({
    type: SET_USER_INFO_ACTION_BEFORE,
});

const setUserInfoActionFinished = () => ({
    type: SET_USER_INFO_ACTION_FINISH,
});

const setUserInfo = data => ({
    type: SET_USER_INFO,
    payload: data
})
export const setBalance = data => ({
    type: SET_BALANCE,
    payload: data
})

export const setLimit = data => ({
    type: SET_LIMIT,
    payload: data
})

export const getUserInfo = () => {
    const languageCode = LanguageUtils.getSelectedLanguage();

    return (dispatch, getState) => {
        dispatch(setUserInfoActionBefore());

        return axios({
            url: ApiUrls.GET_USER_INFO,
            method: Methods.GET,
            params: { languageCode }
        })
            .then(({ data: { value: info } }) => {
                dispatch(setUserInfo(info));
                dispatch(setUserInfoActionFinished());

                /** Load system languages */
                return dispatch(getSystemLanguages())
            })
            .then(() => {
                const languages = getState().auth.languages;
                const userInfo = getState().profile.userInfo;
                let language = languageCode;

                if (!Object.keys(languages).map(l => l.toLowerCase()).includes(languageCode.toLowerCase())) {
                    language = LanguageUtils.defaultLang;
                }

                initTranslations(
                    userInfo.languageFilePath,
                    language,
                    () => { },
                    () => { }
                );
                setUrlParam('language', language)
            })
            .catch((ex) => {
                dispatch(setUserInfoActionFinished());
            })
    }
}

/** User Avaialable Currencies */

const setUserAvailableCurrenciesActionBefore = () => ({
    type: SET_USER_AVAILABLE_CURRENCIES_ACTION_BEFORE,
});

const setUserAvailableCurrenciesActionFinished = () => ({
    type: SET_USER_AVAILABLE_CURRENCIES_ACTION_FINISH,
});

const setUserAvailableCurrencies = currencies => ({
    type: SET_USER_AVAILABLE_CURRENCIES,
    payload: { currencies },
});

export const getUserAvailableCurrencies = () => {
    return dispatch => {

        dispatch(setUserAvailableCurrenciesActionBefore());

        return axios({
            url: ApiUrls.GET_AVAILABLE_CURRENCIES,
            method: Methods.GET,
            params: {},
        })
            .then(({ data: { value: currencies } }) => {
                dispatch(setUserAvailableCurrencies(currencies));
                dispatch(setUserAvailableCurrenciesActionFinished());
            })
            .catch((ex) => {
                dispatch(setUserAvailableCurrenciesActionFinished());
            });
    }
}

export const setCurrentPlayer = (playerInfo) => ({
    type: SET_CURRENT_PLAYER,
    payload: playerInfo
})

export const setPlayer = (playerInfo) => {
    return dispatch => {

        return axios({
            url: ApiUrls.SET_PLAYER,
            method: Methods.POST,
            data: {
                id: playerInfo.id
            }
        })
            .then(({ data }) => {
                dispatch(setCurrentPlayer(playerInfo))
                sessionStorage.set("playerInfo", playerInfo);
            })
            .catch(() => { })
    }
}

export const resetPlayer = (id) => {
    return dispatch => {

        return axios({
            url: ApiUrls.RESET_PLAYER,
            method: Methods.POST,
            data: {
                id
            }
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    dispatch(setCurrentPlayer(null))
                    sessionStorage.set("playerInfo", null);
                }
            })
            .catch(() => { })
    }
}