import axios from "axios";

import Methods from "constants/httpMethods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { showSuccess } from "utils/message";
import LanguageUtils from 'utils/languages';

import {
    SET_BETSHOPS_ACTION_BEFORE,
    SET_BETSHOPS_ACTION_FINISH,
    SET_BETSHOP_ACTION_FINISH,
    SET_BETSHOP_ACTION_BEFORE,
    SET_BETSHOPS,
    SET_BETSHOP
} from "store/actionTypes";

export const setBetshopsActionBefore = () => ({
    type: SET_BETSHOPS_ACTION_BEFORE,
});

export const setBetshopsActionFinished = () => ({
    type: SET_BETSHOPS_ACTION_FINISH,
});

export const setBetshopActionBefore = () => ({
    type: SET_BETSHOP_ACTION_BEFORE,
});

export const setBetshopActionFinished = () => ({
    type: SET_BETSHOP_ACTION_FINISH,
});

const setBetshops = betshops => ({
    type: SET_BETSHOPS,
    payload: { betshops } ,
});
export const setBetshop = betshopId => ({
    type: SET_BETSHOP,
    payload: { betshopId } ,
});


export const getBetshops = token => {
    const languageCode = LanguageUtils.getSelectedLanguage();

    return (dispatch) => {
        dispatch(setBetshopsActionBefore());

        return axios({
            url: ApiUrls.GET_BETSHOPS,
            method: Methods.GET,
            params: { languageCode },
            headers: token ? {
                'Authorization' : 'Bearer ' + token
            } : {}
        })
            .then(({ data: { value: betshops } }) => {
                dispatch(setBetshops(betshops));
                
                dispatch(setBetshopsActionFinished());
            })
            .catch((ex) => {
                dispatch(setBetshopsActionFinished());
            });
    }
}
export const changeBetshop = (token, id, onSuccess) => {
    return (dispatch) => {
        dispatch(setBetshopActionBefore());

        return axios({
            url: ApiUrls.CHANGE_BETSHOP,
            method: Methods.POST,
            data: { id },
            headers: token ? {
                'Authorization' : 'Bearer ' + token
            } :{}
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                showSuccess(data.message);
                onSuccess && onSuccess({ ... ( data?.value ?? {} ) });
            } 
                
                dispatch(setBetshopActionFinished());
            })
            .catch((ex) => {
                dispatch(setBetshopActionFinished());
            });
    }
}

