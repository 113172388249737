import React, { useRef, useEffect } from "react";

import PropTypes from 'prop-types';

/** Function - hook
	 * @function
	 * @param { object } ref - reference to element
	 * @param { func } callback - function to call when clicked outside
	 * @memberOf useOutsideAlerter
 */
const useOutsideAlerter = (
	ref,
	callback,
	preventClassName
) => {

	/** Function to detect if click is outside component
		 * @function
		 * @param { object } event - click event object
		 * @memberOf useOutsideAlerter
	 */
	const handleClickOutside = event => {
		if (ref.current && !ref.current.contains(event.target) && (!preventClassName || !event.target.closest("." + preventClassName))) {
			if (callback) callback();
		}
	}

	/** Add event listener */
	useEffect(() => {
		document.addEventListener("mousedown" , handleClickOutside);
		return () => {
			document.removeEventListener("mousedown" , handleClickOutside);
		};
	});
}

/** Component to add "click outside" event on wrapped component */
const OutsideAlerter = ({
	children,
	callback,
	preventClassName
}) => {
	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef, callback, preventClassName);

	return <div ref={wrapperRef}>{children}</div>;
}

/** OutsideAlerter propTypes
	* PropTypes
*/
OutsideAlerter.propTypes = {
	/** Wrapped component */
	children: PropTypes.element.isRequired,
	/** Function to call when clicked outside */
	callback: PropTypes.func,
	/** The classname of elements to prevent click */
	preventClassName: PropTypes.string
};

export default OutsideAlerter;