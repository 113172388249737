import React, { useEffect, useState } from 'react';

import BarCodeReader from 'components/common/barCodeReader';
import HandoverTicket from 'components/common/tickets/handoverTicket';

import FrameComponent from './frame'
import Header from './header';

import LocalStorageUtils from "utils/localStorage";

/** Wrapper Component for Cashier, which will redirect to default page */
const CashierComponent = () => {

    /** Should print handover ticket */
    const [printHandover, setPrintHandover] = useState(false)

    /** Print handover data on page open */
    useEffect(() => {
        const handoverData = LocalStorageUtils.get("handover");

        if(handoverData && handoverData.ticketId){
            setPrintHandover(true)
        }
    }, [])

    return (
        <div className='rt--cashier'>
            <Header />
            <div className='rt--cashier-content'>
                <FrameComponent />
                <BarCodeReader /> 
            </div>

            <div className='rt--display-none'>
                {printHandover && <HandoverTicket />}
            </div>
        </div>
        
    )
}

export default CashierComponent;
