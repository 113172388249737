const columns = [
    {
        title: "common.cashierUsername" ,
        key: "cashierUserName"
    },
    {
        title: "common.cashierId",
        key: "cashierId"
    },
    {
        title: "common.depositCount",
        key: "depositCount",
        isNumeric: true
    },
    {
        title: "common.depositAmount",
        key: "depositAmount",
        isNumeric: true,
        isMoney: true
    },
    {
        title: "common.withdrawCount",
        key: "withdrawCount",
        isNumeric: true,
    },
    {
        title: "common.withdrawalAmount",
        key: "withdrawAmount",
        isNumeric: true,
        isMoney: true
    },
    {
        title: "common.netDepositAmount",
        key: "netDepositAmount",
        isNumeric: true,
        isMoney: true
    },
    {
        title: "common.betCount",
        key: "betCount",
        isNumeric: true,
    },
    {
        title: "common.betAmount",
        key: "betAmount",
        isNumeric: true,
        isMoney: true
    },
    {
        title: "common.winBetCount",
        key: "wonCount",
        isNumeric: true,
    },
    {
        title: "common.winAmount",
        key: "wonAmount",
        isNumeric: true,
        isMoney: true
    },
    {
        title: "common.payoutCount",
        key: "paidoutCount",
        isNumeric: true,
    },
    {
        title: "common.payoutAmount",
        key: "paidoutAmount",
        isNumeric: true,
        isMoney: true
    },
    {
        title: "common.bonusBetAmount",
        key: "bonusBetAmount",
        isNumeric: true,
        isMoney: true
    },
    {
        title: "common.bonusWin",
        key: "bonusWonAmount",
        isNumeric: true,
        isMoney: true
    },
    {
        title: "common.bonusRedeem",
        key: "bonusRedeemAmount",
        isNumeric: true,
        isMoney: true
    },
    {
        title: "common.wonNotClaimedBetCount",
        key: "wonNotClaimedBetCount",
        isNumeric: true
    },
    {
        title: "common.wonNotClaimedBetAmount",
        key: "wonNotClaimedBetAmount",
        isNumeric: true,
        isMoney: true
    },
    {
        title: "common.cancelledBetCount",
        key: "cancelledBetsCount",
        isNumeric: true
    },
    {
        title: "common.cancelledBetAmount",
        key: "cancelledBetsAmount",
        isNumeric: true,
        isMoney: true
    },
    {
        title: "common.registeredPlayers",
        key: "playerSignups",
        isNumeric: true
    },
    {
        title: "common.taxAmount",
        key: "taxAmount",
        isNumeric: true,
        isMoney: true
    },
    {
        title: "common.totalIn",
        key: "totalInAmount",
        isNumeric: true,
        isMoney: true
    },
    {
        title: "common.totalOut",
        key: "totalOutAmount",
        isNumeric: true,
        isMoney: true
    },
    {
        title: "common.ggr",
        key: "ggr",
        isNumeric: true,
        isMoney: true
    },
    {
        title: "common.ngr",
        key: "ngr",
        isNumeric: true,
        isMoney: true
    },
]

export default columns;