import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { changePopupVisibility } from "store/actions/dashboard/common.action";
import {
    resetPlayer,
    setCurrentPlayer,
} from "store/actions/dashboard/userInfo.action";

import UnselectedPlayerView from "./unselectedPlayerView";
import SelectedPlayerView from "./selectidPlayerView";

import { getPlayerInfoFromStorage } from "utils/common";

import { POPUP_TYPE } from "constants/popup.constants";

const PlayerSelector = ({
    changePopupVisibility,
    resetPlayer,
    playerInfo,
    setCurrentPlayer,
}) => {
    const handleGetPlayerButtonClick = () => {
        changePopupVisibility({ key: POPUP_TYPE.GET_PLAYER });
    };

    const handleBeckToCashierButtonClick = () => {
        resetPlayer(playerInfo.id);
    };

    useEffect(() => {
        setCurrentPlayer(getPlayerInfoFromStorage());
    }, []);

    return (
        <div className="rt--cashier-header-playerSelector">
            {playerInfo ? (
                <SelectedPlayerView
                    id={playerInfo.longId}
                    userName={playerInfo.userName}
                    onClick={handleBeckToCashierButtonClick}
                />
            ) : (
                <UnselectedPlayerView onClick={handleGetPlayerButtonClick} />
            )}
        </div>
    );
};

PlayerSelector.propTypes = {
    changePopupVisibility: PropTypes.func,
    resetPlayer: PropTypes.func,
    setCurrentPlayer: PropTypes.func,
    playerInfo: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        playerInfo: state.profile.playerInfo,
    };
};

const mapDispatchToProps = (dispatch) => ({
    changePopupVisibility: (data) => {
        dispatch(changePopupVisibility(data));
    },
    resetPlayer: (id) => {
        dispatch(resetPlayer(id));
    },
    setCurrentPlayer: (id) => {
        dispatch(setCurrentPlayer(id));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayerSelector);
