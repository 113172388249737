import React from 'react';

import ManagerCashiersComponent from 'components/manager/cashiers';

import withAuth from 'hocs/withAuth';

const ManagerCashiersRoute = () => {
    return (
        <ManagerCashiersComponent /> 
    )
}

export default withAuth(ManagerCashiersRoute);