import PropTypes from 'prop-types';

import { USER_STATE } from "constants/common.constants" 

export default PropTypes.shape({
    id: PropTypes.string,
    longId: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    userName: PropTypes.string,
    email: PropTypes.string,
    state: PropTypes.oneOf(Object.values(USER_STATE)),
    forcePasswordChange: PropTypes.bool,
    lastLogin: PropTypes.string,
    lastActiveAt: PropTypes.string,
    isOnline: PropTypes.bool,
    betShops: PropTypes.object,
    balance: PropTypes.number
})