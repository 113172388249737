/** Checks if two objects are equal
     * @function
     * @param {object} x
     * @param {object} y
     * @returns {boolean}
 */
const isObjectEquals = (x, y) => {
    if (x === null || x === undefined || y === null || y === undefined) { return x === y; }
    // after this just checking type of one would be enough
    if (x.constructor !== y.constructor) { return false; }
    // if they are functions, they should exactly refer to same one (because of closures)
    if (x instanceof Function) { return x === y; }
    // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
    if (x instanceof RegExp) { return x === y; }
    if (x === y || x.valueOf() === y.valueOf()) { return true; }
    if (Array.isArray(x) && x.length !== y.length) { return false; }

    // if they are dates, they must had equal valueOf
    if (x instanceof Date) { return false; }

    // if they are strictly equal, they both need to be object at least
    if (!(x instanceof Object)) { return false; }
    if (!(y instanceof Object)) { return false; }

    // recursive object equality check
    var p = Object.keys(x);
    return Object.keys(y).every(i => p.indexOf(i) !== -1) && p.every(i => isObjectEquals(x[i], y[i]));
}

const isNumericString = str => {
    if (typeof str != "string") return false
    return !isNaN(str) && !isNaN(parseFloat(str))
}

const deepMap = (f, obj) => {
    return Object.keys(obj).reduce((acc, k) => {
        if ({}.toString.call(obj[k]) == '[object Object]') {
            acc[k] = deepMap(f, obj[k])
        } else {
            acc[k] = f(obj[k], k)
        }
        return acc
    }, {})
}

/** Function which checks if form values are changed
     * @function
     * @param {object} formValues - form current values
     * @param {object} initialValues - form initial values
     * @returns {boolean}
 */
export const isFormChanged = (formValues, initialValues) => {
    if (Object.keys(formValues).length === 0) return false; // form isn't initialized yet

    let values = {};
    let initials = {};

    Object.keys(formValues).forEach(key => {
        values[key] = formValues[key] === null || formValues[key] === undefined ? "" : formValues[key];
        initials[key] = initialValues[key] === null || initialValues[key] === undefined ? "" : initialValues[key];
    });

    values = deepMap(v => typeof v === "string" ? isNumericString(v.trim()) ? Number(v.trim()) : v.trim() : v === null || v === undefined ? "" : v, values);
    initials = deepMap(v => typeof v === "string" ? isNumericString(v.trim()) ? Number(v.trim()) : v.trim() : v === null || v === undefined ? "" : v, initials);

    return !isObjectEquals(values, initials);
}