import axios from "axios";

import Methods from "constants/httpMethods.constants";
import ApiUrls from "constants/api.constants";

import {
    SET_CASHIER_PERFORMANCE_REPORT_ACTION_BEFORE,
    SET_CASHIER_PERFORMANCE_REPORT_ACTION_FINISH,
    SET_CASHIER_PERFORMANCE_REPORT,
    SET_CASHIER_PERFORMANCE_REPORT_FILTERS,
	 SET_PENDING_BETS
} from "store/actionTypes";

const setCashierPerformanceReportActionBefore = () => ({
    type: SET_CASHIER_PERFORMANCE_REPORT_ACTION_BEFORE,
});

const setCashierPerformanceReportActionFinished = () => ({
    type: SET_CASHIER_PERFORMANCE_REPORT_ACTION_FINISH,
});

const setCashierPerformanceReport = report => ({
    type: SET_CASHIER_PERFORMANCE_REPORT,
    payload: { report },
});

const setPendingBets = pendingBets => ({
    type: SET_PENDING_BETS,
    payload: { pendingBets },
});

export const setCashierPerformanceReportFilters = filters => ({
    type: SET_CASHIER_PERFORMANCE_REPORT_FILTERS,
    payload: { filters },
});


export const getCashierPerformanceReport = (betShopNameOrId, from, to) => {
    return dispatch => {
        
        let params = {
            betShopNameOrId,
            from,
            to,
            filterType: 1
        };
        
        dispatch(setCashierPerformanceReportActionBefore());

        return axios({
            url: ApiUrls.GET_CASHIER_PERFORMANCE_REPORT,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: report } }) => {
                dispatch(setCashierPerformanceReport(report));
                dispatch(setCashierPerformanceReportActionFinished());
            })
            .catch((ex) => {
                dispatch(setCashierPerformanceReportActionFinished());
            });
    }
}

export const getCurrentCashierPerformanceReport = () => {
    return ( dispatch, getState) => {
        
        dispatch(setCashierPerformanceReportActionBefore());

        const filters = getState().cashierPerformanceReport.filters

        return axios({
            url: ApiUrls.GET_CASHIER_PERFORMANCE_REPORT,
            method: Methods.GET,
            params: {
                from: filters.from,
                to: filters.to,
                filterType: 1
            },
        })
            .then(({ data: { value: report } }) => {
                dispatch(setCashierPerformanceReport(report));
                dispatch(setCashierPerformanceReportActionFinished());
            })
            .catch((ex) => {
                dispatch(setCashierPerformanceReportActionFinished());
            });
    }
}

export const getPendingBets = () => {
	return (dispatch) => {

		return axios({
			url: ApiUrls.GET_PENDING_BETS,
			method: Methods.GET,
	  })
			.then(({ data: { value } }) => {
				 dispatch(setPendingBets(value));
			})
			.catch((ex) => { });
	}
}