import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { WALLET_OWNER_TYPE } from 'constants/common.constants';

/** Favorite Amounts */
const FavoriteAmounts = ({
    currencyCode,
    currencies,
    walletUserType,
    handleClick
}) => {

    const favoriteAmounts = useMemo(() => {
        if(!currencyCode) return [];
        const currencyData = currencies.find(c => c.code === currencyCode);
        if(!currencyData) return [];
        const favAmounts = currencyData.transactionFavoriteAmounts;
        if(walletUserType === WALLET_OWNER_TYPE.BETSHOP || walletUserType === WALLET_OWNER_TYPE.CASHIER){
            return favAmounts?.betShopFavoriteAmounts ?? []
        } else if(walletUserType === WALLET_OWNER_TYPE.PLAYER){
            return favAmounts?.playerFavoriteAmounts ?? []
        }
    }, [currencyCode, currencies, handleClick])

    return favoriteAmounts.length > 0 ? (
        <div className='rt--modal-money-transfer-favamounts rt--flex rt--align-center rt--mb-16'>
            {
                favoriteAmounts.map(fa => (
                    <div 
                        className='rt--modal-money-transfer-favamounts-item rt--flex rt--align-center rt--justify-center rt--mr-6 rt--ml-6' 
                        key={fa}
                        onClick={() => handleClick(fa)}
                    >
                        <span className='rt--font-normal rt--font-bold'>{fa}</span>
                    </div>
                ))
            }
        </div>
    ) : null 
}

/** FavoriteAmounts propTypes
    * PropTypes
*/
FavoriteAmounts.propTypes = {
    /** Wallet User Type */
    walletUserType: PropTypes.oneOf(Object.values(WALLET_OWNER_TYPE)),
    /** Call on click */
    handleClick: PropTypes.func,
    /** Currency Code */
    currencyCode: PropTypes.string,
    /** Redux state property, current user info */
    currencies: PropTypes.array
}

const mapStateToProps = state => {
    return {
        currencies: state.profile.userInfo.currencies,
    }
}

export default connect(mapStateToProps, null)(FavoriteAmounts);
