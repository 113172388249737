import React from 'react';

import TransactionsComponent from 'components/common/transactions';

import { WALLET_OWNER_TYPE } from 'constants/common.constants';

import withAuth from 'hocs/withAuth';

const ManagerCashierTransactionsRoute = () => {
    return (
        <TransactionsComponent type={WALLET_OWNER_TYPE.CASHIER} /> 
    )
}

export default withAuth(ManagerCashierTransactionsRoute);