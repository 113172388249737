import React, { Fragment } from 'react';

import Cashiers from "./cashiers";
import Filters from "./filters";
import {useTranslation} from "react-i18next";
import useFormat from "hooks/useFormat";
import {DATE_TIME_FORMAT, USER_STATE} from "constants/common.constants";
import moment from "moment";

/** Betshop Manager Cashiers Page */
const BetshopManagerCashiers = () => {
    const { t } = useTranslation();
    const { formatAmount } = useFormat();

    /** Table columns */
    const columns = [
        {
            key: "firstName",
            title: t("common.cashier"),
            render: ( value, record) => (
                <div className='rt--flex rt--align-center'>
                    <div
                        className='rt--table-marker rt--mr-8'
                        data-type={ record.isOnline ? "success" : "inactive" }
                    />
                    <span title={value + " " + record.lastName} className='rt--cashier-name rt--title rt--font-normal rt--font-regular'>{value + " " + record.lastName}</span>
                </div>
            ),
            sorting: true
        },
        {
            key: "userName",
            title: t("common.username"),
            sorting: true
        },
        {
            key: "longId",
            title: t("common.id"),
            sorting: true,
            copy: true
        },
        {
            key: "state",
            title: "Status",
            render: value => (
                <div className='rt--flex rt--align-center'>
                    <div
                        className='rt--table-marker rt--mr-8'
                        data-type={
                            value === USER_STATE.ACTIVE ? "success":
                                value === USER_STATE.IN_PROGRESS ? "pending" : "error"
                        }
                    />
                    <span className='rt--title rt--font-normal rt--font-regular'>{
                        value === USER_STATE.ACTIVE ? t("common.active") :
                            value === USER_STATE.IN_PROGRESS ? t("common.inProgress") : t("common.blocked")
                    }</span>
                </div>
            ),
            sorting: true
        },
        {
            key: "lastLogin",
            title: t("common.lastLogin"),
            render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "-"}</span>,
            sorting: true
        },
        {
            key: "balance",
            title: t("common.betshopSpecificBalance"),
            render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(value, record.currencyCode)}</span>
        }
    ]

    return (
        <Fragment>
            <Filters columns={columns} />
            <Cashiers columns={columns} />
        </Fragment>
    )
}

export default BetshopManagerCashiers;
