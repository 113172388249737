import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { composeInitialProps, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { Form, Input } from 'antd';

import HandoverCountDown from "./countDown";
import InputPasswordWithoutAutocomplete from '../login/inputPassword';

import { authenticate } from 'store/actions/auth/auth.action';
import { rejectHandover } from 'store/actions/dashboard/handover.action';

import { loginUser, isHandoverActive } from 'utils/auth';
import localStorageUtils from 'utils/localStorage';
import { showError } from "utils/message";

import { TOKEN_TYPE } from 'constants/auth.constants';
import Paths from 'constants/path.constants';

import useFormat from 'hooks/useFormat';

/** Handover Page Component */

const HandoverComponent = ({
    authenticate,
    isLoading,
    rejectHandover
}) => {

    const { t } = useTranslation();

    const { formatNumber } = useFormat();

    const { executeRecaptcha } = useGoogleReCaptcha();

    const navigate = useNavigate();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    /** Set default values if handover active, and reject handover if time is expired*/
    useEffect(() => {
        const handoverData = localStorageUtils.get("handover");
        if (isHandoverActive()) {
            setFieldsValue({
                username: handoverData.cashierUserName,
                amount: formatNumber(handoverData.amount) + " " + handoverData.currency
            })
        } else {
            if (handoverData) {
                rejectHandover(handoverData.id, () => {
                    navigate(Paths.LOGIN, { replace: true })
                })
            } else {
                navigate(Paths.LOGIN, { replace: true })
            }
        }
    }, [])

    /** Fires after success login
       * @function
       * @param {Object} data - The data returned from server after login call
       * @param {string} username
       * @memberOf HandoverComponent
   */
    const onSuccessfulAuthentication = (data, username) => {
        switch (data.tokenType) {
            case TOKEN_TYPE.NONE:
                loginUser(data);
                setTimeout(() => {
                    window.location.reload();
                }, 0)
                break;
            case TOKEN_TYPE.PASSWORD_EXPIRED:
                navigate(Paths.SET_PASSWORD + "?t=" + data.token + "&type=forceChange");
                break;
            case TOKEN_TYPE.SELECT_BETSHOP:
                navigate(Paths.SELECT_BETSHOP + "?t=" + data.token + "&user=" + username);
                break;
            default:
                break;
        }
    }

    const getRecaptchaToken = async () => {
        return import.meta.env.MODE === "development" ? "34f530b1728d4b28a5d1e39bb92be946" : await executeRecaptcha();
    }

    const hasNonExistingBetShopError = (response) => (
        response.data &&
        response.data.resultCode === RESPONSE_CODES.NotFound &&
        response.data.resource === 'BetShop'
    );

    const handleLogin = (username, password, token) => {
        return authenticate(username, password, token)
            .then(data => {
                onSuccessfulAuthentication(data, username)
            })
            .catch(response => {
                if (hasNonExistingBetShopError(response)) {
                    throw new Error();
                }
            })
        };

    /** Fires when form submitted
       * @function
       * @memberOf HandoverComponent
   */
    const handleForm = () => {
        validateFields()
            .then(async ({ username, password }) => {
                const token = await getRecaptchaToken();
                handleLogin(username, password, token)
                    .catch(async () => {
                        localStorageUtils.remove('betshopData');

                        const token = await getRecaptchaToken();

                        handleLogin(username, password, token);
                    });
            }).catch(() => { })
    }

    /** Function to reject handover
       * @function
       * @param {boolean} clicked - is function fired on reject button click
       * @memberOf HandoverComponent
   */
    const handleReject = clicked => {
        const handoverData = localStorageUtils.get("handover");
        rejectHandover(handoverData.id, () => {
            navigate(Paths.LOGIN, { replace: true });

            if (clicked) {
                showError(t("common.theShiftHandoverHasBeenRejected"))
            } else {
                showError(t("common.theShiftHandoverSessionHasExpired"))
            }
        })
    }

    return (
        <div className="rt--auth-form">
            <Form
                form={formInstance}
                hideRequiredMark={true}
                layout="vertical"
            >
                <b className="rt--title rt--text-left rt--font-bigest rt--font-medium">{t('common.acceptShiftHandover')}</b>
                <div className='rt--mb-32 rt--mt-8'>
                    <small className="rt--title rt--text-left rt--font-big rt--font-normal">{t('common.acceptShiftHandoverInfo')}</small>
                </div>

                <HandoverCountDown
                    onEnd={() => handleReject(false)}
                />
                <Form.Item
                    label={t('common.balanceTransferedToYou')}
                    name="amount"
                    rules={[
                        { required: true, whitespace: true, message: t('validation.field_required') },
                        { max: 30, message: t('validation.field_invalid') }
                    ]}
                    className="rt--form-item-disabled"
                >
                    <Input
                        className='rt--input'
                        placeholder={t('authentication.username')}
                        disabled={true}
                    />
                </Form.Item>
                <Form.Item
                    label={t('authentication.username')}
                    name="username"
                    rules={[
                        { required: true, whitespace: true, message: t('validation.field_required') },
                        { max: 30, message: t('validation.field_invalid') }
                    ]}
                    className="rt--form-item-disabled"
                >
                    <Input
                        className='rt--input'
                        placeholder={t('authentication.username')}
                        disabled={true}
                    />
                </Form.Item>
                <Form.Item
                    label={t('authentication.password')}
                    name="password"
                    rules={[
                        { required: true, whitespace: true, message: t('validation.field_required') },
                        { max: 24, message: t('validation.field_invalid') },
                        { min: 8, message: t('validation.field_invalid') }
                    ]}
                >
                    <InputPasswordWithoutAutocomplete
                        className='rt--input'
                        placeholder={t('authentication.password')}
                    />
                </Form.Item>
            </Form>
            <div className='rt--mt-32 rt--mb-32'>
                <div className='rt--flex rt--align-center'>
                    <button
                        disabled={isLoading}
                        className="rt--button-secondary rt--button rt--flex-equal"
                        onClick={() => handleReject(true)}
                    >
                        <span>{t('common.reject')}</span>
                    </button>
                    <button
                        disabled={isLoading}
                        className="rt--button-complimentary rt--button rt--flex-equal rt--ml-16"
                        onClick={handleForm}
                    >
                        <span>{t('common.accept')}</span>
                    </button>
                </div>
            </div>
            <div className='rt--copyright rt--pl-20 rt--pr-20 rt--pt-20 rt--pb-20 rt--flex rt--justify-center'>
                <span className='rt--title rt--font-normal rt--font-regular rt--text-center'>{`© ${new Date().getFullYear()} ${t('authentication.copyRightText')}`}</span>
            </div>
        </div>
    )
}

/** HandoverComponent propTypes
    * PropTypes
*/
HandoverComponent.propTypes = {
    /** Redux state property, is true when authenticating */
    isLoading: PropTypes.bool,
    /** Redux action to authenticate */
    authenticate: PropTypes.func,
    /** Redux action to reject handover */
    rejectHandover: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        authenticate: (username, password, captchaToken) => {
            return dispatch(authenticate(username, password, captchaToken))
        },

        rejectHandover: (handoverId, callback) => {
            dispatch(rejectHandover(handoverId, callback))
        }
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.auth.isLoading
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    HandoverComponent
);