import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Modal } from 'antd';

import Table from "components/common/table";

import useFormat from 'hooks/useFormat';

import { POPUP_SIZE } from "constants/popup.constants";
import { WALLET_TYPE } from "constants/common.constants";
import { RESPONSE_CODES } from "constants/errorCode.constants";

/** Player Transfer Popup Failed Transactions Component */
const FailedTransactions = ({
    transactions,
    onClose
}) => {

    const { t } = useTranslation();

    const { formatNumber } = useFormat();

    /** Failed Transactions Error Table Columns */
    const columns = [
        {
            title: "",
            key: 'warning',
            render: (value, record) => record.status !== RESPONSE_CODES.Success ? <i className='icon-warning rt--font-bigest' /> : ""
        },
        {
            title: t('common.name'),
            key: 'userName'
        },
        {
            title: t("common.walletType"),
            key: "type",
            render: value => <span className='rt--title rt--font-normal rt--font-regular'>{value === WALLET_TYPE.BALANCE ? t("common.balance") : value === WALLET_TYPE.LIMIT ? t("common.limit") : ""}</span>
        },
        {
            title: t("common.amount"),
            key: "changeAmount",
            render: value => <span className='rt--title rt--font-normal rt--font-regular'>{ `${formatNumber(value)}` }</span>
        },
        {
            title: t("common.message"),
            key: "message",
            render: (value, record) => record.status === RESPONSE_CODES.Success ? (
                <span className='rt--title rt--font-normal rt--font-regular rt--success-text' title={t("common.successfull")}>{t("common.successfull")}</span>
            ) : (
                <span className='rt--title rt--font-normal rt--font-regular rt--error-text' title={value}>{value}</span>
            )
        },
    ]

    return (
        <Modal
            className='rt--modal rt--modal-failed-transaction'
            title={(
                <div className='rt--flex rt--justify-between rt--align-center'>
                    <div className='rt--flex rt--align-center'>
                        <i className='icon-warning rt--font-bigest' />
                        <span className='rt--title rt--font-normal rt--font-regular rt--pl-8'>{t("common.transactionHasFailed")}</span>
                    </div>
                    <i className='icon-close rt--font-bigest rt--cursor-pointer' onClick={onClose} />
                </div>
            )}
            visible={true}
            onCancel={onClose}
            closable={false}
            maskClosable={false}
            getContainer={() => document.getElementById('rt--modal-root')}
            okButtonProps={{ className: 'rt--display-none' }}
            cancelButtonProps={{ className: 'rt--button-secondary' }}
            cancelText={t('common.close')}
            width={ POPUP_SIZE.BIG}
            centered
        >
            <div className='rt--modal-failed-transactions rt--flex rt--flex-col'>
                <Table
                    name="failedTransactions"
                    columns={columns}
                    data={transactions}
                    isLoading={false}
                    actions={[]}
                    uniqueKey="userName"
                />
            </div>
        </Modal>
    )
}

/** FailedTransactions propTypes
    * PropTypes
*/
FailedTransactions.propTypes = {
    /** Transactions array */
    transactions: PropTypes.arrayOf(PropTypes.shape({
        /** Transaction userName */
        userName: PropTypes.string,
        /** Transaction changed wallet */
        type: PropTypes.oneOf(Object.values(WALLET_TYPE)),
        /** Transaction amount */
        changeAmount: PropTypes.number,
        /** Transaction message */
        message: PropTypes.string
    })),
    /** Function to call close popup */
    onClose: PropTypes.func
}

export default FailedTransactions;