import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import Table from "components/common/table";

import { getCashiers, setCashiersSorting, removeBetshopAccess, changeCashierState } from 'store/actions/dashboard/cashiers.action';
import { changePopupVisibility } from 'store/actions/dashboard/common.action'

import { USER_STATE } from 'constants/common.constants';
import { POPUP_TYPE } from 'constants/popup.constants'

import { isUserBlocked } from 'utils/auth';

import sortingType from 'types/sorting.type';
import cashierType from 'types/cashier.type';

import noCashierImg from "assets/images/noCashier.png";

/** Betshop Manager Csahiers Page Csahiers Table Component */

const Cashiers = ({
    getCashiers,
    setCashiersSorting,
    removeBetshopAccess,
    changeCashierState,
    changePopupVisibility,
    isLoading,
    isSaving,
    cashiers,
    sorting,
    columns
}) => {

    const { t } = useTranslation();
    const searchParams = useParams();

    return (
        <Table
            name="cashiers"
            loadFn={() => getCashiers(searchParams.id)}
            setSortingFn={setCashiersSorting}
            columns={columns}
            data={cashiers}
            isLoading={isLoading}
            sorting={sorting}
            emptyImg={noCashierImg}
            emptyText={t("common.noCashiersFound")}
            actions={
                !isUserBlocked() ?
                [
                    {
                        icon: "icon-remove-user",
                        onClick: record => {
                            changePopupVisibility({
                                key: POPUP_TYPE.CONFIRMATION,
                                data: {
                                    onOk: () => removeBetshopAccess(searchParams.id, record.id),
                                    isSaving: isSaving,
                                    title: t("common.removeCashierConfirmationTitle"),
                                    description: t("common.removeCashierConfirmationDescription").replace("%%userName%%", record.firstName + " " + record.lastName),
                                    okText: t("common.remove")
                                }
                            })
                        },
                        isHidden: record => record.betShopsCount < 2,
                        title: () => t("common.remove")
                    },
                    {
                        icon: "icon-arrows",
                        onClick: record => {
                            changePopupVisibility({
                                key: POPUP_TYPE.MONEY_TRANSFER, 
                                data: { betShopId: searchParams.id, cashierId: record.id, currency: record.currencyCode }})
                        },
                        title: () => t("common.moneyTransfer")
                    },
                    {
                        icon: record => record.state !== USER_STATE.BLOCKED ? "icon-block" : "icon-unblock",
                        onClick: record => {
                            changePopupVisibility({
                                key: POPUP_TYPE.CONFIRMATION,
                                data: {
                                    onOk: () => changeCashierState(record.id, record.state === USER_STATE.BLOCKED, searchParams.id),
                                    isSaving: isSaving,
                                    title: record.state !== USER_STATE.BLOCKED ? t("common.blockTheCashier") : t("common.unblockTheCashier"),
                                    description: record.state !== USER_STATE.BLOCKED ? t("common.blockTheCashierInfo") : t("common.unblockTheCashierInfo"),
                                    okText:  record.state !== USER_STATE.BLOCKED ? t("common.block") : t("common.unblock")
                                }
                            })
                        },
                        isHidden: () => false,
                        title: record => record.state !== USER_STATE.BLOCKED ? t("common.block") : t("common.unblock"),
                    },
                ] : []
            }
        />
    )
}

/** Cashiers propTypes
    * PropTypes
*/
Cashiers.propTypes = {
    /** Redux action to get cashiers */
    getCashiers: PropTypes.func,
    /** Redux action to set cashiers sorting */
    setCashiersSorting: PropTypes.func,
    /** Redux state property, is true when loading cashiers */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when saving cashier */
    isSaving: PropTypes.bool,
    /** Redux state property, cashiers sorting */
    sorting: sortingType,
    /** Redux state property, the array of cashiers */
    cashiers: PropTypes.arrayOf(cashierType),
    /** Redux action to remove betshop access to cashier */
    removeBetshopAccess: PropTypes.func,
    /** Redux action to bock/unblock cashier */
    changeCashierState: PropTypes.func,
    /** Redux action to open popup */
    changePopupVisibility: PropTypes.func,
    /** Table columns */
    columns: PropTypes.arrayOf(PropTypes.object)
}

const mapDispatchToProps = dispatch => (
    {
        getCashiers: id => {
            dispatch(getCashiers(id));
        },

        setCashiersSorting: sorting => {
            dispatch(setCashiersSorting(sorting));
        },

        removeBetshopAccess: (betShopId, cashierId) => {
            dispatch(removeBetshopAccess(betShopId, cashierId))
        },

        changeCashierState: (id, enabled, betShopId) => {
            dispatch(changeCashierState(id, enabled, betShopId))
        },

        changePopupVisibility : data =>{
            dispatch(changePopupVisibility(data))
        }
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.cashiers.isLoading,
        isSaving: state.cashiers.isSaving,
        sorting: state.cashiers.sorting,
        cashiers: state.cashiers.cashiers,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Cashiers
);
