import axios from 'axios';

import Methods from 'constants/httpMethods.constants';
import ApiUrls from 'constants/api.constants';

import {
    SET_LANGUAGES_BEFORE,
    SET_LANGUAGES_AFTER,
    SET_LANGUAGES,
    SET_TRANSLATIONS_LOADED
} from 'store/actionTypes';


const setLanguagesBefore = () => ({
    type: SET_LANGUAGES_BEFORE
})

const setLanguagesFinished = () => ({
    type: SET_LANGUAGES_AFTER
})

const setSystemLanguages = languages => ({
    type: SET_LANGUAGES,
    payload: { languages }
})
export const setTranslationsLoaded = () => ({
    type: SET_TRANSLATIONS_LOADED
})

export const getSystemLanguages = () => {
    return dispatch => {
        dispatch(setLanguagesBefore());

        return axios({
            url: ApiUrls.GET_SYSTEM_LANGUAGES,
            method: Methods.GET
        })
        .then(( {data : {value: languages}}) => {
           
                dispatch(setSystemLanguages(languages));
          
            dispatch(setLanguagesFinished());
        })
        .catch((ex) => {
            dispatch(setLanguagesFinished());
        })
    }
}

