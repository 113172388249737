import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Input, Popover } from 'antd';

import { getManagerBetshopAvailableCashiersForTransfer } from "store/actions/dashboard/managerBetshops.action";

import betshopAvailableCashierType from 'types/betshopAvailableCashier.type';

import noCashierImg from "assets/images/noCashier.png";

/** Money Transfer Available Cashiers Dropdown Component */
const AvailableCashiers = ({
    getManagerBetshopAvailableCashiersForTransfer,
    availableCashiersForTransfer,
    betShopId,
    onSelect
}) => {
    const { t } = useTranslation()

    /** Selected value */
    const [value, setValue] = useState(null)

    /** Flag to show/hide menu */
    const [showList, setShowList] = useState(false)

    /** Load betshop manager available cashiers for transfer */
    useEffect(() => {
        if (betShopId) {
            getManagerBetshopAvailableCashiersForTransfer(betShopId);
            setValue(null)
        }
    }, [betShopId])


    /** Function to call on dropdown list item click
         * @function
         * @param {object} cashier - clicked item cashier data
         * @memberOf AvailableCashiers
    */
    const handleClick = cashier => {
        setValue(cashier.id);
        onSelect && onSelect(cashier.id);
        setShowList(false)
    }

    /** Selected cashier */
    const selectedCashier = availableCashiersForTransfer.find(b => b.id === value);

    /** Dropdown value to show */
    const inputValue = value && selectedCashier ? selectedCashier.firstName + " " + selectedCashier.lastName : "";

    return (
        <div className={'rt--modal-form-item rt--flex rt--flex-col rt--mb-16' + (!betShopId ? " rt--modal-form-item-disabled" : "")}>
            <span className='rt--title rt--font-medium rt--font-normal rt--mb-8'>{t("common.cashier")}</span>
            <div className='rt--modal-money-transfer-dropdown'>
                <Popover
                    placement="bottom"
                    overlayClassName='rt--modal-money-transfer-dropdown-overlay'
                    content={
                        (
                            <div className='rt--modal-money-transfer-dropdown-list rt--pt-8 rt---pb-8'>
                                {
                                    availableCashiersForTransfer.length > 0 ? availableCashiersForTransfer.map(cashier => (
                                        <div
                                            className='rt--modal-money-transfer-dropdown-list-item rt--pl-16 rt--pr-16 rt--flex rt--align-center rt--cursor-pointer'
                                            key={cashier.id}
                                            onMouseDown={() => handleClick(cashier)}
                                        >
                                            <div className='rt--modal-money-transfer-dropdown-list-item-icon rt--flex rt--align-center rt--justify-center' >
                                                <i className='icon-user rt--font-bigest' />
                                            </div>
                                            <div className='rt--modal-money-transfer-dropdown-list-item-content rt--flex rt--flex-equal rt--flex-col rt--pl-8 rt--justify-cneter'>
                                                <b className='rt--title rt--font-normal rt--font-regular'>{cashier.firstName + " " + cashier.lastName}</b>
                                                <div className='rt--flex rt--flex-equal rt--mt-4 rt--align-center'>
                                                    <span className='rt--title rt--font-small rt--font-regular'>{cashier.userName}</span>
                                                    <div className='rt--modal-money-transfer-dropdown-list-item-content-dot rt--ml-8 rt--mr-8'></div>
                                                    <i className='icon-coins rt--font-big' />
                                                    <span className='rt--title rt--font-small rt--font-regular rt--pl-4'>{cashier.balance}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )) : (
                                        <div className='rt--modal-money-transfer-dropdown-list-empty rt--flex rt--flex-col rt--align-center rt--justify-center rt--pt-24 rt--pb-24'>
                                            <img alt={t("common.noCashiersFound")} src={noCashierImg} />
                                            <span className='rt--title rt--pt-8 rt--text-center rt--font-medium rt--font-big'>{t("common.noCashiersFound")}</span>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                    trigger="click"
                    visible={ !betShopId ? false: showList}
                    onVisibleChange={setShowList}
                >
                    <div className='rt--modal-money-transfer-dropdown-input'>
                        <Input
                            readOnly={true}
                            className='rt--input'
                            placeholder={t("common.choose") + " " + t("common.cashier")}
                            value={inputValue}
                            disabled={!betShopId}
                        />
                        <i className='icon-down rt--font-bigest' />
                    </div>
                </Popover>

            </div>
        </div>
    )
}

/** AvailableCashiers propTypes
    * PropTypes
*/
AvailableCashiers.propTypes = {
    /** Redux action to get betshop manager available cashiers for transfer */
    getManagerBetshopAvailableCashiersForTransfer: PropTypes.func,
    /** Redux state property, available cashiers to transfer money for betshop manager */
    availableCashiersForTransfer: PropTypes.arrayOf(betshopAvailableCashierType),
    /** Betshop Id */
    betShopId: PropTypes.string,
    /** Function to call on select */
    onSelect: PropTypes.func
}

const mapStateToProps = state => {
    return {
        availableCashiersForTransfer: state.managerBetshops.availableCashiersForTransfer,
    }
}
const mapDispatchToProps = dispatch => (
    {
        getManagerBetshopAvailableCashiersForTransfer: betShopId => {
            dispatch(getManagerBetshopAvailableCashiersForTransfer(betShopId))
        }
    }
)


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    AvailableCashiers
);