import axios from "axios";

import Methods from "constants/httpMethods.constants";
import ApiUrls from "constants/api.constants";
import { USER_TYPE } from "constants/user.constants";

import {
    SET_SESSIONS_ACTION_BEFORE,
    SET_SESSIONS_ACTION_FINISH,
    SET_SESSIONS,
    SET_SESSIONS_SORTING,
    SET_SESSIONS_FILTERS
} from "store/actionTypes";

const setSessionsActionBefore = () => ({
    type: SET_SESSIONS_ACTION_BEFORE,
});

const setSessionsActionFinished = () => ({
    type: SET_SESSIONS_ACTION_FINISH,
});

const setSessions = sessions => ({
    type: SET_SESSIONS,
    payload: { sessions },
});

export const setSessionsSorting = sorting => ({
    type: SET_SESSIONS_SORTING,
    payload: { sorting },
});

export const setSessionsFilters = filters => ({
    type: SET_SESSIONS_FILTERS,
    payload: { filters },
});


export const getSessions = id => {
    return (dispatch, getState) => {
        
        let params = {
            userRole: USER_TYPE.CASHIER,
            ...getState().sessions.sorting,
            from: getState().sessions.filters.from,
            to: getState().sessions.filters.to,
            userNameOrId: getState().sessions.filters.userNameOrId,
            betShopIds: getState().sessions.filters.betShopId ? getState().sessions.filters.betShopId : []
        };
        
        dispatch(setSessionsActionBefore());

        return axios({
            url: ApiUrls.GET_CASHIER_SESSIONS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: sessions } }) => {
                dispatch(setSessions(sessions));
                dispatch(setSessionsActionFinished());
            })
            .catch((ex) => {
                dispatch(setSessionsActionFinished());
            });
    }
}
