import React, { useEffect, useRef } from "react"

import JsBarcode from "jsbarcode";

/** Barcode Image Component */
const BarCode = ({ 
    value 
}) => {
    const ref = useRef()
    
    useEffect(() => {
        if (value) {
            if (ref.current) {
                let valueForBarCode = ""
                if (value.length < 5) {
                    valueForBarCode = "0" + value;
                } else {
                    valueForBarCode = value;
                }

                JsBarcode(ref.current, valueForBarCode, {
                    format: "CODE39",
                    fontSize: 16,
                    textMargin: 2,
                    font: "Roboto",
                    lineColor: "#000000",
                    margin: 0,
                    height: 50,
                    displayValue: false
                });
            }
        }
    }, [value])

    return (
        <img ref={ref} alt="barcode" />
    )
}

export default BarCode;