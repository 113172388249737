import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { Form, Result, Tooltip } from 'antd';

const { Item: FormItem } = Form;

import PasswordValidationTooltip from "../passwordValidationTooltip";
import InputPasswordWithoutAutocomplete from '../login/inputPassword';

import { getPasswordSettings } from 'store/actions/auth/passwordSettings.action';
import { resetPassword, setPassword } from 'store/actions/auth/resetPassword.action';

import { loginUser } from 'utils/auth';
import { validatePasword } from "utils/password";

import { USER_ROLE } from 'constants/user.constants';
import Paths from 'constants/path.constants';

import passwordSettingsType from 'types/passwordSettings.type';

/** Set Password Page Component */

const SetPasswordComponent = ({
    resetPassword,
    setPassword,
    getPasswordSettings,
    passwordSettings,
    isReseting,
}) => {

    const { t } = useTranslation();

    const { search } = useLocation();

    const [formInstance] = Form.useForm();
    const { validateFields, getFieldValue } = formInstance;

    const ref = useRef()
    const navigate = useNavigate();

    /** New Password field value */
    const [newPassword, setNewPassword] = useState('');

    /** State for New Password field focues */
    const [newPasswordFocused, setNewPasswordFocused] = useState(false);

    const token = (new URLSearchParams(search)).get("t");

    const type = (new URLSearchParams(search)).get("type") || "forgotPassword";

    /** Load password settings */
    useEffect(() => {
        if (token) {
            getPasswordSettings(token, type);
        }
    }, [])


    /** Fires when form submitted
       * @function
       * @memberOf SetPasswordComponent
   */
    const handleForm = () => {

        validateFields()
            .then(({ newPassword, confirmPassword }) => {
                if (type === "forgotPassword") {
                    resetPassword(token, newPassword, confirmPassword, () => {
                        navigate(Paths.LOGIN);
                    });
                } else {
                    setPassword(token, newPassword, confirmPassword, (data) => {
                        loginUser(data);
                        setTimeout(() => {
                            const pathToRedirect = data?.role === USER_ROLE.CASHIER ? Paths.CASHIER : Paths.MANAGER
                            navigate(pathToRedirect);
                            window.location.reload();
                        }, 0)
                    });
                }
            }).catch(() => { })
    }

    return type ? (
        <div className="rt--auth-form" ref={ref}>
            <Form
                form={formInstance}
                hideRequiredMark={true}
                layout="vertical"
                initialValues={{
                    newPassword: "",
                    confirmPassword: ""
                }}
            >
                <b className="rt--title rt--text-left rt--font-bigest rt--font-medium rt--mb-32 rt--mt-8">{t('authentication.setNewPassword')}</b>
                <Tooltip
                    title={<PasswordValidationTooltip password={newPassword} passwordSettings={passwordSettings} />}
                    placement="right"
                    overlayClassName="rt--auth-password-rules-tooltip"
                    getPopupContainer={() => ref.current}
                    visible={newPasswordFocused || !!newPassword}
                >
                    <Form.Item
                        label={`${t('authentication.password')}`}
                        name="newPassword"
                        validateFirst
                        rules={[
                            { required: true, whitespace: true, message: t('validation.field_required') },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    return validatePasword(value, passwordSettings);
                                }
                            })
                        ]}

                    >

                        <InputPasswordWithoutAutocomplete
                            className='rt--input'
                            placeholder={`${t('common.enter')} ${t('authentication.password')}`}
                            onChange={e => {
                                setNewPassword(e.target.value)
                                setTimeout(() => {
                                    if (getFieldValue('confirmPassword') !== "")
                                        validateFields(['confirmPassword'])
                                }, 0)
                            }}
                            onFocus={() => setNewPasswordFocused(true)}
                            onBlur={() => setNewPasswordFocused(false)}
                        />
                    </Form.Item>
                </Tooltip>

                <Form.Item
                    label={`${t('authentication.confirmPassword')}`}
                    name="confirmPassword"
                    validateFirst
                    rules={[
                        { required: true, whitespace: true, message: t('validation.field_required') },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (value !== getFieldValue("newPassword")) {
                                    return Promise.reject(t('validation.passwords_do_not_match'))
                                }
                                return Promise.resolve();
                            }
                        })
                    ]}
                >
                    <InputPasswordWithoutAutocomplete
                        className='rt--input'
                        placeholder={`${t('authentication.confirmPassword')}`}
                        onPaste={e => e.preventDefault()}
                        onContextMenu={e => e.preventDefault()}
                        onCopy={e => e.preventDefault()}
                    />
                </Form.Item>

                <div className='rt--mt-32'>
                    <FormItem>
                        <button
                            disabled={isReseting}
                            className={"rt--button-complimentary rt--button" + (isReseting ? " rt--button-disabled" : "")}
                            onClick={handleForm}
                        >
                            <span className='rt--font-big rt--font-medium'>{t('authentication.confirmPassword')}</span>
                        </button>
                    </FormItem>
                </div>
            </Form>
            <div className='rt--copyright rt--pl-20 rt--pr-20 rt--pt-20 rt--pb-20 rt--flex rt--justify-center'>
                <span className='rt--title rt--font-normal rt--font-regular rt--text-center'>{`© ${new Date().getFullYear()} ${t('authentication.copyRightText')}`}</span>
            </div>
        </div>
    ) : <Result status="warning" title={t('authentication.noTokenFound')} />
}

/** SetPasswordComponent propTypes
    * PropTypes
*/
SetPasswordComponent.propTypes = {
    /** Redux state property, is true when set password request is in process */
    isReseting: PropTypes.bool,
    /** Redux action to get Password Settings */
    getPasswordSettings: PropTypes.func,
    /** Redux action to reset password */
    resetPassword: PropTypes.func,
    /** Redux action to set password */
    setPassword: PropTypes.func,
    /** Redux state proprty, password settings */
    passwordSettings: passwordSettingsType
}

const mapDispatchToProps = dispatch => (
    {
        getPasswordSettings: (token, type) => {
            dispatch(getPasswordSettings(token, type));
        },
        resetPassword: (token, newPassword, confirmPassword, onSuccess) => {
            dispatch(resetPassword(token, newPassword, confirmPassword, onSuccess))
        },
        setPassword: (token, newPassword, confirmPassword, onSuccess) => {
            dispatch(setPassword(token, newPassword, confirmPassword, onSuccess))
        }
    }
)

const mapStateToProps = state => {
    return {
        isReseting: state.auth.resetPassword.isReseting,
        passwordSettings: state.auth.passwordSettings.passwordSettings
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    SetPasswordComponent
);