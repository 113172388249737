import React from 'react';

import ManagerBetshopsComponent from 'components/manager/betshops';

import withAuth from 'hocs/withAuth';

const ManagerBetshopsRoute = () => {
    return (
        <ManagerBetshopsComponent /> 
    )
}

export default withAuth(ManagerBetshopsRoute);