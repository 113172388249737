import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Loader from 'components/common/loader';

import { getCurrentCashierPerformanceReport } from "store/actions/dashboard/cashierPerformanceReport.action";

import useFormat from 'hooks/useFormat';

import { getUser } from 'utils/auth';

import performanceReportType from 'types/performanceReport.type';
import userInfoType from 'types/userInfo.type';

import columns from "./columns";

import noDataImg from "assets/images/noContent.png"

/** Performance Report Table Component */
const PerformanceReport = ({
    getCurrentCashierPerformanceReport,
    isLoading,
    report,
    userInfo
}) => {

    const { t } = useTranslation();

    const { formatNumber, formatAmount } = useFormat();

    const reportData = report[0];

    const currency = userInfo?.currencies?.[0] ?? null;

    const makeValue = column => {
        let result = "";
        const value = reportData[column.key];

        const currencyCode = currency?.code ?? "";

        if(column.key === "cashierUserName"){
            return getUser()?.userName
        }

        if(column.isMoney){
            
            if(value === 0){
                result = formatAmount(value, currencyCode);
            } else {
                result = `${formatAmount(value, currencyCode)} ${currencyCode}`;
            }
                
            return result;
        }

        if(column.isNumeric){
            return formatNumber(value);
        }
        
        return value;
    }

    /** Load Current Cashier Performance Report */
    useEffect(() => {
        getCurrentCashierPerformanceReport()
    }, [])

    const renderEmptyContent = () => (
        <div className='rt--report-empty rt--flex rt--flex-col rt--align-center rt--justify-center'>
            <img alt={t("common.noDataFound")} src={noDataImg} />
            <span className='rt--title rt--pt-8 rt--text-center rt--font-medium rt--font-extra-biger'>{t("common.noDataFound")}</span>
        </div>
    )

    const renderTable = () => (
        <div className='rt--report-table'>
            {
                columns.map(column => (
                    <div className='rt--report-table-row rt--flex rt--align-center rt--mb-4' key={column.key}>
                        <div className='rt--report-table-col rt--flex rt--align-center rt--mr-1 rt--pl-24 rt--pr-24'>
                            <span className='rt--title rt--font-regular rt--font-big'>{t(column.title)}</span>
                        </div>
                        <div className='rt--report-table-col rt--flex rt--align-center rt--pl-24 rt--pr-24 rt--flex-equal'>
                            <span className='rt--title rt--font-regular rt--font-big'>{makeValue(column)}</span>
                        </div>
                    </div>
                ))
            }
        </div>
    )

    return (
        <Fragment>
            {
                isLoading ? <Loader /> : (
                    <div className='rt--report rt--flex-equal'>
                        {
                            !reportData ? renderEmptyContent() : renderTable()
                        }
                    </div>
                )
            }
        </Fragment>
    )
}

/** PerformanceReport propTypes
    * PropTypes
*/
PerformanceReport.propTypes = {
    /** Redux action to get cashier performance report */
    getCurrentCashierPerformanceReport: PropTypes.func,
    /** Redux state property, is true when loading cashier performance report */
    isLoading: PropTypes.bool,
    /** Redux state property, the array of cashier performance report */
    report: PropTypes.arrayOf(performanceReportType),
    /** Redux state property, current user info */
    userInfo: userInfoType
}

const mapDispatchToProps = dispatch => (
    {
        getCurrentCashierPerformanceReport: () => {
            dispatch(getCurrentCashierPerformanceReport());
        }
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.cashierPerformanceReport.isLoading,
        report: state.cashierPerformanceReport.report,
        userInfo: state.profile.userInfo
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    PerformanceReport
);
