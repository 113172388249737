import PropTypes from 'prop-types';

import { BET_STATE, BET_TYPE } from 'constants/bets.constants';

export default PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.oneOf(Object.values(BET_STATE)),
    betTime: PropTypes.string,
    calculateTime: PropTypes.string,
    externalBetSlipId: PropTypes.number,
    payoutBy: PropTypes.string,
    currencyCode: PropTypes.string,
    factor: PropTypes.number,
    amount: PropTypes.number,
    winning: PropTypes.number,
    possibleWin: PropTypes.number,
    paidoutTime: PropTypes.string,
    betType: PropTypes.oneOf(Object.values(BET_TYPE))
})