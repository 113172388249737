import React from 'react';

import { Outlet } from 'react-router';

/** Empty Layout Component */
const Layout = () => {
  return  <Outlet />
}

export default Layout;

