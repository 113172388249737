import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import AutoComplete from 'components/common/autoComplete';

import { setManagerBetshopsFilters , getManagerBetshops } from "store/actions/dashboard/managerBetshops.action";
import { getManagerAvailableBetshops } from "store/actions/dashboard/managerBetshops.action";

import ApiUrls from "constants/api.constants";
import Export from "components/common/export";

import managerAvailableBetshopType from "types/managerAvailableBetshop.type";

/** Betshop Manager Betshops Page Betshops Table Filters Component */

const BetshopsFilters = ({
    setManagerBetshopsFilters,
    getManagerBetshops,
    getManagerAvailableBetshops,
    availableBetshops,
    columns,
    filters
}) => {

    const { t } = useTranslation();

     /** Load available betshops betshop manager */
    useEffect(() => {
        getManagerAvailableBetshops();
    }, [])

    /** Function, handler for betshop name or id search input
		 * @function
		 * @param {string} value - the field value
		 * @memberOf BetshopsFilters
    */
    const handleSearchChange = value => {
        console.log({value});
        let nameOrId = value && value.length > 2 ? value : "";
        if(nameOrId){
            nameOrId = availableBetshops.find(b => b.name === nameOrId)?.id ?? ""
        }
        setManagerBetshopsFilters({
            nameOrId: nameOrId
        })
        setTimeout(() => {
            getManagerBetshops()
        }, 10)
    }

    useEffect(() => {
        return () => {
            setManagerBetshopsFilters({
                nameOrId: ""
            })
        }
    }, [])

    return (
        <div className='rt--filters-header rt--flex rt--flex-equal rt--justify-between rt--align-center rt--mt-32 rt--pb-24 rt--mb-30'>
            <div>
                <span className='rt--title rt--font-big rt--font-regular'>{t("common.betshops")}</span>
            </div>

            <div className='rt--flex'>
                <div className='rt--filters-header-search'>
                    <AutoComplete
                        placeholder={t("common.searchByBetshopNameOrId")}
                        items={availableBetshops.map(b => ({
                            id: b.id,
                            longId: b.longId,
                            name: b.name
                        }))}
                        onSelect={handleSearchChange}
                    />
                    <i className='icon-search rt--font-bigest' />
                </div>

                <Export
                    filters={filters}
                    title={t("common.betshops")}
                    columns={columns}
                    url={ApiUrls.EXPORT_MANAGER_BETSHOPS}
                    tableName={t("common.betshops")}
                />
            </div>
        </div>
    )
}

/** BetshopsFilters propTypes
    * PropTypes
*/
BetshopsFilters.propTypes = {
    /** Redux action to get betshop manager betshops */
    getManagerBetshops: PropTypes.func,
    /** Redux action to set betshop manager betshops filters */
    setManagerBetshopsFilters: PropTypes.func,
    /** Redux action to get available betshops for manager */
    getManagerAvailableBetshops: PropTypes.func,
    /** Redux state property, available betshops for manager */
    availableBetshops: PropTypes.arrayOf(managerAvailableBetshopType),
    /** Redux state property, betshop manager filters */
    filters: PropTypes.object,
    /** Columns data */
    columns: PropTypes.arrayOf(PropTypes.object)
}

const mapDispatchToProps = dispatch => (
    {
        getManagerBetshops: () => {
            dispatch(getManagerBetshops());
        },

        setManagerBetshopsFilters: sorting => {
            dispatch(setManagerBetshopsFilters(sorting));
        },

        getManagerAvailableBetshops: () => {
            dispatch(getManagerAvailableBetshops())
        },
    }
)

const mapStateToProps = state => {
    return {
        availableBetshops: state.managerBetshops.availableBetshops,
        filters: state.managerBetshops.filters,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BetshopsFilters);
