import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Checkbox, Tooltip } from 'antd';

import Dropdown from "components/common/dropdown";

/** Checkboxes Dropdown Component */
const CheckboxesDropdown = ({
    columns,
    buttons,
    buttonText,
    buttonClassName,
    allowEmpty,
    infoText,
    headerDescription,
    selectAllByDefault,
    defaultSelectedColumns
}) => {
    const { t } = useTranslation();

    const _dropdownRef = useRef(null)

    const [selectedColumns, setSelectedColumns] = useState(selectAllByDefault ? columns.map(c => c.key) : defaultSelectedColumns ? defaultSelectedColumns : []);

    const allIsSelected = selectedColumns.length === columns.length;

    /** Function , returns modal content JSX
       * @function
       * @param {boolean} value
       * @memberOf CheckboxesDropdown
   */
    const handleSelectAll = value => {
        setSelectedColumns(allIsSelected ? [] : columns.map(c => c.key) )
    }

    /** Set default values */
    useEffect(() => {
        if (selectAllByDefault) {
            setSelectedColumns(columns.map(c => c.key))
        } else {
            setSelectedColumns(defaultSelectedColumns ? defaultSelectedColumns : []);
        }

    }, [selectAllByDefault])


    /** Function , returns modal content JSX
       * @function
       * @returns {JSX}
       * @memberOf CheckboxesDropdown
   */
    const renderDropdownContent = () => {
        return (

            <div className="rt--checkboxes-modal rt--dropdown-menu">
                <div className="rt--checkboxes-modal-content rt--flex rt--flex-col">
                    <div className='rt--checkboxes-modal-content-head rt--pl-16 rt--pr-16'>
                        <div className='rt--flex rt--flex-col'>
                            <div className="rt--flex rt--align-center rt--justify-between rt--flex-equal">
                                <div className="rt--checkboxes-modal-content-head-row rt--flex rt--align-center">
                                    <Checkbox
                                        checked={allIsSelected}
                                        onChange={e => handleSelectAll(e.target.checked)}
                                        indeterminate={allIsSelected}
                                    />
                                    <span className="rt--title rt--font-normal rt--font-regular rt--pl-8">
                                        {allIsSelected ? t('common.deselectAll') : t('common.selectAll')}
                                    </span>
                                </div>
                                {
                                    infoText ?
                                        <Tooltip title={infoText}>
                                            <i className='icon-info'/>
                                        </Tooltip> :
                                        null
                                }
                            </div>

                            {headerDescription ? <span className="rt--checkboxes-modal-content-head-description">{headerDescription}</span> : null}
                        </div>
                    </div>
                    <div className='rt--checkboxes-modal-content-list rt--pb-4 rt--pt-4 rt--flex-equal'>
                        <Checkbox.Group
                            value={selectedColumns}
                            onChange={value => setSelectedColumns(value)}
                        >
                            {
                                columns.map(column => (
                                    <div className="rt--checkboxes-modal-content-row rt--flex rt--align-center rt--justify-start rt--pl-16 rt--pr-16" key={column.key}>
                                        <Checkbox value={column.key} />
                                        <span className="rt--title rt--font-normal rt--font-regular rt--pl-8">{t(column.title)}</span>
                                    </div>
                                ))
                            }
                        </Checkbox.Group>
                    </div>
                    <div className="rt--checkboxes-modal-footer rt--pl-24 rt--pr-24 rt--pt-16 rt--pb-16 rt--flex rt--align-center rt--justify-center">
                        {
                            buttons.map((b, index) => (
                                <button
                                    key={index}
                                    type="primary"
                                    className="rt--button rt--ml-4 rt--mr-4"
                                    disabled={!allowEmpty ? selectedColumns.length === 0 : false}
                                    onClick={() => {
                                        b.onClick(selectedColumns);
                                        _dropdownRef.current.close()
                                    }}
                                >
                                    {b.text}
                                </button>
                            ))
                        }
                    </div>

                </div>
            </div>
        )
    }

    return (
        <div className={"rt--checkboxes-dropdown " + (buttonClassName ? buttonClassName : "")}>
            <Dropdown
                ref={_dropdownRef}
                element={
                    <div className="rt--checkboxes-dropdown-content rt--flex rt--align-center">
                        <span className='rt--title rt--font-bold rt--font-normal'>{buttonText}</span>
                        <i className="icon-down rt--font-bigest" />
                    </div>
                }
                dropdownContent={renderDropdownContent()}
            />
        </div>
    )
}

/** CheckboxesDropdown propTypes
    * PropTypes
*/
CheckboxesDropdown.propTypes = {
    /** Array od columns of table which can be exported */
    columns: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        key: PropTypes.string
    })),
    /** Buttons array */
    buttons: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
        onClick: PropTypes.func
    })),
    /** Dropdown toggle button text */
    buttonText: PropTypes.string,
    /** Dropdown toggle button className */
    buttonClassName: PropTypes.node,
    /** Allow to uncheck all checkboxes */
    allowEmpty: PropTypes.bool,
    /** Select all checkboxes by default */
    selectAllByDefault: PropTypes.bool,
    /** Default Selected columns */
    defaultSelectedColumns: PropTypes.arrayOf(PropTypes.string),
    /** Text for tooltip */
    infoText: PropTypes.string,
    /** Text for description */
    headerDescription: PropTypes.string
}

export default CheckboxesDropdown;
