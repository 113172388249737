import {
    SET_BETSHOP_PERFORMANCE_REPORT_ACTION_BEFORE,
    SET_BETSHOP_PERFORMANCE_REPORT_ACTION_FINISH,
    SET_BETSHOP_PERFORMANCE_REPORT,
    SET_BETSHOP_PERFORMANCE_REPORT_SORTING,
    SET_BETSHOP_PERFORMANCE_REPORT_FILTERS,
    SET_BETSHOP_PERFORMANCE_TOTALS_ACTION_BEFORE,
    SET_BETSHOP_PERFORMANCE_TOTALS_ACTION_FINISH,
    SET_BETSHOP_PERFORMANCE_TOTALS
} from "store/actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_BETSHOP_PERFORMANCE_REPORT_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_BETSHOP_PERFORMANCE_REPORT_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_BETSHOP_PERFORMANCE_REPORT:
            return {
                ...state,
                report: payload.report.item2,
                total: payload.report.item1,
            };

        case SET_BETSHOP_PERFORMANCE_TOTALS_ACTION_BEFORE:
            return {
                ...state,
                totals: {
                    ...state.totals,
                    isLoading: true
                }
            };
        case SET_BETSHOP_PERFORMANCE_TOTALS_ACTION_FINISH:
            return {
                ...state,
                totals: {
                    ...state.totals,
                    isLoading: false
                }
            };
        case SET_BETSHOP_PERFORMANCE_TOTALS:
            return {
                ...state,
                totals: {
                    ...state.totals,
                    totals: payload.totals
                }
            };

        case SET_BETSHOP_PERFORMANCE_REPORT_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...payload.filters
                },
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            };
        case SET_BETSHOP_PERFORMANCE_REPORT_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        default:
            return state;
    }
};
