import React, { useEffect } from 'react';
import ReactDOM from "react-dom";

import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import ChangeBetShopPopup from './changeBetshop';
import CheckTicketPopup from './checkTicket';
import TransferHistoryPopup from './transferHistory';
import BetHistoryPopup from './betHistory';
import RegisterPlayerPopup from './registerPlayer';
import TransferPlayerPopup from './transferPlayer';
import AddCashierPopup from './addCashier';
import MoneyTransferPopup from './moneyTransfer';
import ConfirmationPopup from './confirmation';
import ShiftHandoverPopup from './shiftHandover';
import PerformanceReportPopup from './performanceReport';
import GetPlayer from './getPlayer';

import { changePopupVisibility } from 'store/actions/dashboard/common.action';

import { POPUP_TYPE } from 'constants/popup.constants'

/** Popup Component */
const Popup = ({
    popup,
    changePopupVisibility
}) => {

    /** Hide page overflow on popup open */
    useEffect(() => {
        popup.key ? document.getElementsByTagName('html')[0].classList.add('rt--ovfh') : document.getElementsByTagName('html')[0].classList.remove('rt--ovfh');
    }, [popup.key]);

    /** Function, to call to close popup
		 * @function
		 * @param {object} e - event object
		 * @memberOf Popup
    */
    const handleClosePopup = e => {
        e && e.stopPropagation();
        e && e.preventDefault();
        changePopupVisibility({ key: POPUP_TYPE.CLOSE })
    }

    /** Function, to render popup content
		 * @function
		 * @returns {JSX} 
		 * @memberOf Popup
    */
    const getContent = () => {
        switch (popup.key) {
            case POPUP_TYPE.CONFIRMATION:
                return <ConfirmationPopup data={popup.data} handleCloseModal={handleClosePopup} />
            case POPUP_TYPE.CHANGE_BETSHOP:
                return <ChangeBetShopPopup data={popup} handleCloseModal={handleClosePopup} />
            case POPUP_TYPE.CHECK_TICKET:
                return <CheckTicketPopup ticketId={popup.data} handleCloseModal={handleClosePopup} />
            case POPUP_TYPE.TRANSFER_HISTORY:
                return <TransferHistoryPopup handleCloseModal={handleClosePopup} />
            case POPUP_TYPE.BET_HISTORY:
                return <BetHistoryPopup handleCloseModal={handleClosePopup} />
            case POPUP_TYPE.REGISTER_PLAYER:
                return <RegisterPlayerPopup handleCloseModal={handleClosePopup} />
            case POPUP_TYPE.TRANSFER_PLAYER:
                return <TransferPlayerPopup transferType={popup.data} handleCloseModal={handleClosePopup} />
            case POPUP_TYPE.ADD_CASHIER:
                return <AddCashierPopup handleCloseModal={handleClosePopup} />
            case POPUP_TYPE.MONEY_TRANSFER:
                return <MoneyTransferPopup data={popup.data} handleCloseModal={handleClosePopup} />
            case POPUP_TYPE.SHIFT_HANDOVER:
                return <ShiftHandoverPopup handleCloseModal={handleClosePopup} />
            case POPUP_TYPE.PERFORMANCE_REPORT:
                return <PerformanceReportPopup handleCloseModal={handleClosePopup} />
            case POPUP_TYPE.GET_PLAYER:
                return <GetPlayer handleCloseModal={handleClosePopup} />
            default:
                return <></>
        }
    }

    return popup.key ? ReactDOM.createPortal(
        getContent(popup),
        document.getElementById('rt--modal-root')
    ) : null;

}

/** Popup propTypes
    * PropTypes
*/
Popup.propTypes = {
    /** Redux action to open popup */
    changePopupVisibility: PropTypes.func,
    /** Popup data */
    popup: PropTypes.shape({
        /** Popup additional data */
        data: PropTypes.any,
        /** Popup key */
        key: PropTypes.number
    })
}

const mapStateToProps = state => {
    return {
        popup: state.common.popup
    }
}
const mapDispatchToProps = dispatch => (
    {
        changePopupVisibility: (data) => {
            dispatch(changePopupVisibility(data))
        }
    }
)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Popup
);