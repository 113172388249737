import React, { Fragment, useEffect } from 'react';

import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import LocalStorageUtils from "utils/localStorage";
import { printElement } from 'utils/common';
import { getUser } from 'utils/auth';

import { DATE_TIME_FORMAT } from 'constants/common.constants';

import userInfoType from 'types/userInfo.type';

import Logo from "assets/images/logo-blue.svg"

/** Handover Ticket Component */
const HandoverTicket = ({
    userInfo
}) => {
    const { t } = useTranslation();

    const companyLongId = getUser()?.companyLongId;
    const projectLongId = getUser()?.projectLongId;

    /** Ticket details */
    const ticketDetails = userInfo.payoutTicket;

    /** Handover data from local storage */
    const handover = LocalStorageUtils.get("handover")

    /** Print Ticket */
    useEffect(() => {
        if (userInfo.payoutTicket && handover) {
            printElement('rt--print-wrapper', () => {
                LocalStorageUtils.remove("handover")
            })
        }
    }, [userInfo.payoutTicket])

    return handover && userInfo.payoutTicket? (
        <div id="rt--print-wrapper" className="rt--print-wrapper">
            <div className="rt--print">
                <div className="rt--print-header rt--mb-16">
                    {
                        ticketDetails.ticketLogoId ?
                            <img src={`${import.meta.env.SYSTEM_CDN_URL}/companies/${companyLongId}/projects/${projectLongId}/images/${ticketDetails.ticketLogoId}_ticket_logo.png`} alt="logo" /> :
                            <img src={Logo} alt="logo" />
                    }
                    <div className='rt--mt-16'>
                        <b className='rt--print-header-title rt--font-big rt--font-bold'>{t("common.shiftHandoverTicket")}</b>
                    </div>
                </div>

                <div className="rt--print-info rt--print-info-dashed rt--pt-16 rt--pb-16">
                    <div className="rt--print-info-row rt--mb-12">
                        <span>{t('common.ticketId')}</span>
                        <span>{handover.ticketId}</span>
                    </div>
                    <div className="rt--print-info-row rt--mb-12">
                        <span>{t('common.date')}</span>
                        <span>{moment(new Date()).format(DATE_TIME_FORMAT)}</span>
                    </div>
                    <div className="rt--print-info-row rt--mb-12">
                        <span>{t('common.betshopName')}</span>
                        <span>{handover.betShopName}</span>
                    </div>
                    <div className="rt--print-info-row rt--mb-12">
                        <span>{t('common.cashierHandingOver')}</span>
                        <span>{handover.userName}</span>
                    </div>
                    <div className="rt--print-info-row">
                        <span>{t('common.cashierReceiving')}</span>
                        <span>{handover.cashierUserName}</span>
                    </div>
                </div>

                <div className="rt--print-info rt--pt-16 rt--pb-16">
                    <div className="rt--print-info-row">
                        <span>{t('common.amount')}</span>
                        <span>{`${handover.amount} ${handover.currency}`}</span>
                    </div>
                </div>
            </div>
        </div>
    ) : <Fragment />
}

/** HandoverTicket propTypes
    * PropTypes
*/
HandoverTicket.propTypes = {
    /** Redux state property, current user info */
    userInfo: userInfoType
}

const mapStateToProps = state => {
    return {
        userInfo: state.profile.userInfo,
    }
}

export default connect(mapStateToProps, null)(HandoverTicket)
