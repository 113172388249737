import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Input, Popover } from 'antd';

import { getManagerAvailableBetshops } from "store/actions/dashboard/managerBetshops.action";

import managerAvailableBetshopType from 'types/managerAvailableBetshop.type';

import noBetshopImg from "assets/images/noBetshop.png"

/** Money Transfer Available Betshops Dropdown Component */
const AvailableBetshops = ({
    getManagerAvailableBetshops,
    availableBetshops,
    onSelect
}) => {
    const { t } = useTranslation()

    /** Selected value */
    const [value, setValue] = useState(null)

    /** Flag to show/hide menu */
    const [showList, setShowList] = useState(false)

    /** Get Manager available betshops */
    useEffect(() => {
        getManagerAvailableBetshops()
    }, [])

    /** Dropdown value to show */
    const inputValue = value ? availableBetshops.find(b => b.id === value).name : ""

    /** Function to call on dropdown list item click
         * @function
         * @param {object} betshop - clicked item betshop data
         * @memberOf AvailableBetshops
    */
    const handleClick = betshop => {
        setValue(betshop.id);
        onSelect && onSelect(betshop.id, betshop);
        setShowList(false)
    }

    return (
        <div className='rt--modal-form-item rt--flex rt--flex-col rt--mb-16'>
            <span className='rt--title rt--font-medium rt--font-normal rt--mb-8'>{t("common.betshop")}</span>
            <div className='rt--modal-money-transfer-dropdown'>
                <Popover
                    placement="bottom"
                    overlayClassName='rt--modal-money-transfer-dropdown-overlay'
                    content={
                        (
                            <div className='rt--modal-money-transfer-dropdown-list rt--pt-8 rt---pb-8'>
                                {
                                    availableBetshops.length > 0 ? availableBetshops.map(betshop => (
                                        <div
                                            className='rt--modal-money-transfer-dropdown-list-item rt--pl-16 rt--pr-16 rt--flex rt--align-center rt--cursor-pointer'
                                            key={betshop.id}
                                            onMouseDown={() => handleClick(betshop)}
                                        >
                                            <div className='rt--modal-money-transfer-dropdown-list-item-icon rt--flex rt--align-center rt--justify-center' >
                                                <i className='icon-betshops rt--font-bigest' />
                                            </div>
                                            <div className='rt--modal-money-transfer-dropdown-list-item-content rt--flex rt--flex-equal rt--flex-col rt--pl-8 rt--justify-cneter'>
                                                <b className='rt--title rt--font-normal rt--font-regular'>{betshop.name}</b>
                                                <div className='rt--flex rt--flex-equal rt--mt-4 rt--align-center'>
                                                    <span className='rt--title rt--font-small rt--font-regular'>{betshop.address}</span>
                                                    <div className='rt--modal-money-transfer-dropdown-list-item-content-dot rt--ml-8 rt--mr-8'></div>
                                                    <i className='icon-coins rt--font-big' />
                                                    <span className='rt--title rt--font-small rt--font-regular rt--pl-4'>{betshop.balance + " " + betshop.currency}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )) : (
                                        <div className='rt--modal-money-transfer-dropdown-list-empty rt--flex rt--flex-col rt--align-center rt--justify-center rt--pt-24 rt--pb-24'>
                                            <img alt={t("common.noBetshopsFound")} src={noBetshopImg} />
                                            <span className='rt--title rt--pt-8 rt--text-center rt--font-medium rt--font-big'>{t("common.noBetshopsFound")}</span>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                    trigger="click"
                    visible={showList}
                    onVisibleChange={setShowList}
                >
                    <div className='rt--modal-money-transfer-dropdown-input'>
                        <Input
                            readOnly={true}
                            className='rt--input'
                            placeholder={t("common.choose") + " " + t("common.betshop")}
                            value={inputValue}
                        />
                        <i className='icon-down rt--font-bigest' />
                    </div>
                </Popover>

            </div>
        </div>
    )
}

/** AvailableBetshops propTypes
    * PropTypes
*/
AvailableBetshops.propTypes = {
    /** Redux action to get betshop manager available betshops*/
    getManagerAvailableBetshops: PropTypes.func,
    /** Redux state property, available betshops for betshop manager */
    availableBetshops: PropTypes.arrayOf(managerAvailableBetshopType),
    /** Function to call on select */
    onSelect: PropTypes.func
}

const mapStateToProps = state => {
    return {
        availableBetshops: state.managerBetshops.availableBetshops,
    }
}
const mapDispatchToProps = dispatch => (
    {
        getManagerAvailableBetshops: () => {
            dispatch(getManagerAvailableBetshops())
        }
    }
)


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    AvailableBetshops
);