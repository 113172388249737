import moment from "moment";

import { constructArrayForGivenRanges } from "utils/common";

import { TIME_FORMAT } from "constants/common.constants";

export const TIME_PICKER_MODES = {
    DEFAULT: "default",
    ONLY_HOURS: "onlyHours"
}

export const TIME_PICKER_OPTIONS_BY_MODES = {
    [TIME_PICKER_MODES.DEFAULT]: {
        format: TIME_FORMAT,
    },

    [TIME_PICKER_MODES.ONLY_HOURS]: {
        hideDisabledOptions: true,
        disabledMinutes: () => constructArrayForGivenRanges({ start: 1, end: 60 }),
        defaultValue: [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')],
    }
}