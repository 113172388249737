import React, { Component } from 'react';

import { Navigate } from 'react-router-dom';

import { isAuthenticated, getUser } from 'utils/auth';

import PATHS from 'constants/path.constants';
import { USER_ROLE } from 'constants/user.constants';

/** Hocs which allows access to page only unAuthorized users 
       * @function
       * @param {Component} WrappedComponent - Component to add functionality 
   */
const WithAnanymous = WrappedComponent => {

    class WithAnanymous extends Component {

        render() {

            if (isAuthenticated()) {
                const pathToRedirect = getUser()?.role === USER_ROLE.CASHIER ? PATHS.CASHIER : PATHS.MANAGER

                return <Navigate to={pathToRedirect} />
            }

            return <WrappedComponent {...this.props} />;
        }
    }

    WithAnanymous.displayName = `WithAnanymous(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
    return WithAnanymous;
}

export default WithAnanymous;