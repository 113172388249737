import axios from "axios";

import Methods from "constants/httpMethods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_CODES } from "constants/errorCode.constants";

import { isResponseSuccess } from "utils/request";
import { showSuccess,showError } from "utils/message";

import { 
    SET_WALLET_ACTION_BEFORE, 
    SET_WALLET_ACTION_FINISH
} from "store/actionTypes";

const setWalletActionBefore = () => ({
    type: SET_WALLET_ACTION_BEFORE,
});

const setWalletActionFinished = () => ({
    type: SET_WALLET_ACTION_FINISH,
});


const handleTransferResponse = data => {
    if (isResponseSuccess(data)) {
        const resp = data.value;
        if(resp){
            if(resp.status === RESPONSE_CODES.Success){
                showSuccess(resp.message)
            } else {
                showError(resp.message)
            }
        }
    } 
}

export const transferToCashier = (data, onSuccess) => {
    
    return dispatch => {
        dispatch(setWalletActionBefore());

        return axios({
            url: ApiUrls.WALLET_CASHIER_TRANSACTION,
            method: Methods.POST,
            data: { ...data }
        })
        .then(({data}) => {
            handleTransferResponse(data);
            onSuccess && onSuccess();
            dispatch(setWalletActionFinished());
        })

        .catch((ex) => {
            dispatch(setWalletActionFinished());
        })
    }
}

export const resetBetshopLimit = betShopId => {
    return dispatch => {
        dispatch(setWalletActionBefore());

        return axios({
            url: ApiUrls.WALLET_RESET_BETSHOP_LIMIT,
            method: Methods.POST,
            data: { betShopId }
        })
        .then(({data}) => {
            handleTransferResponse(data);
            //onSuccess && onSuccess();
            dispatch(setWalletActionFinished());
        })

        .catch((ex) => {
            dispatch(setWalletActionFinished());
        })
    }
}