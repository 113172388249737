import React from 'react';

import ManagerSessionsComponent from 'components/manager/sessions';

import withAuth from 'hocs/withAuth';

const ManagerSessionsRoute = () => {
    return (
        <ManagerSessionsComponent /> 
    )
}

export default withAuth(ManagerSessionsRoute);