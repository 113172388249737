import React from 'react';

import CashierComponent from 'components/cashier';

import withAuth from 'hocs/withAuth';

const CashierRoute = () => {
    return (
        <CashierComponent /> 
    )
}

export default withAuth(CashierRoute);