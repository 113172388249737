import axios from "axios";

import Methods from "constants/httpMethods.constants";
import ApiUrls from "constants/api.constants";

import {
    SET_BETSHOP_PERFORMANCE_REPORT_ACTION_BEFORE,
    SET_BETSHOP_PERFORMANCE_REPORT_ACTION_FINISH,
    SET_BETSHOP_PERFORMANCE_REPORT,
    SET_BETSHOP_PERFORMANCE_REPORT_SORTING,
    SET_BETSHOP_PERFORMANCE_REPORT_FILTERS,
    SET_BETSHOP_PERFORMANCE_TOTALS_ACTION_BEFORE,
    SET_BETSHOP_PERFORMANCE_TOTALS_ACTION_FINISH,
    SET_BETSHOP_PERFORMANCE_TOTALS
} from "store/actionTypes";

const setBetshopPerformanceReportActionBefore = () => ({
    type: SET_BETSHOP_PERFORMANCE_REPORT_ACTION_BEFORE,
});

const setBetshopPerformanceReportActionFinished = () => ({
    type: SET_BETSHOP_PERFORMANCE_REPORT_ACTION_FINISH,
});


const setBetshopPerformanceReport = report => ({
    type: SET_BETSHOP_PERFORMANCE_REPORT,
    payload: { report },
});

const setBetshopPerformanceTotalsActionBefore = () => ({
    type: SET_BETSHOP_PERFORMANCE_TOTALS_ACTION_BEFORE,
});

const setBetshopPerformanceTotalsActionFinished = () => ({
    type: SET_BETSHOP_PERFORMANCE_TOTALS_ACTION_FINISH,
});

const setBetshopPerformanceTotals = totals => ({
    type: SET_BETSHOP_PERFORMANCE_TOTALS,
    payload: { totals },
})

export const setBetshopPerformanceReportSorting = sorting => ({
    type: SET_BETSHOP_PERFORMANCE_REPORT_SORTING,
    payload: { sorting },
});

export const setBetshopPerformanceReportFilters = filters => ({
    type: SET_BETSHOP_PERFORMANCE_REPORT_FILTERS,
    payload: { filters },
});


export const getBetshopPerformanceReport = () => {
    return (dispatch, getState) => {

        let params = {
            ...getState().betshopPerformanceReport.sorting,
            ...getState().betshopPerformanceReport.filters,
            filterType: 1
        };

        dispatch(setBetshopPerformanceReportActionBefore());

        return axios({
            url: ApiUrls.GET_BETSHOP_PERFORMANCE_REPORT,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: report } }) => {
                dispatch(setBetshopPerformanceReport(report));
                dispatch(setBetshopPerformanceReportActionFinished());
            })
            .catch((ex) => {
                dispatch(setBetshopPerformanceReportActionFinished());
            });
    }
}

export const getBetshopPerformanceTotals = () => {
    return (dispatch, getState) => {

        let params = {
           ...getState().betshopPerformanceReport.sorting,
           ...getState().betshopPerformanceReport.filters,
            filterType: 1
        };

        dispatch(setBetshopPerformanceTotalsActionBefore());

        return axios({
            url: ApiUrls.GET_BETSHOP_PERFORMANCE_TOTALS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: report } }) => {
                dispatch(setBetshopPerformanceTotals(report));
                dispatch(setBetshopPerformanceTotalsActionFinished());
            })
            .catch((ex) => {
                dispatch(setBetshopPerformanceTotalsActionFinished());
            });
    }
}
