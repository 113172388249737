import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { Modal, Input } from 'antd';

import AvailableBetshops from './availableBetshops';
import AvailableCashiers from './availableCashiers';
import FavoriteAmounts from 'components/common/favoriteAmounts';

import { transferToCashier } from "store/actions/dashboard/wallet.action";
import { getCashiers } from "store/actions/dashboard/cashiers.action";

import Paths from 'constants/path.constants';
import { POPUP_SIZE } from "constants/popup.constants";
import { INTEGER_AND_DECIMAL_NUMBERS_REGEX } from 'constants/regex.constants';
import { WALLET_OWNER_TYPE } from 'constants/common.constants';

import { hasDotAndDotIsAtTheEnd } from 'utils/common';


const MONEY_TRANSFER_ACTION = {
    DEPOSIT: 1,
    WITHDRAW: 2
}

/** Money Transfer Popup Component */
const MoneyTransferPopup = ({
    handleCloseModal,
    data,
    transferToCashier,
    isSaving,
    getCashiers
}) => {
    const { t } = useTranslation()

    const { pathname } = useLocation();
    const searchParams = useParams();

    /** Selected transfer action */
    const [action, setAction] = useState(MONEY_TRANSFER_ACTION.DEPOSIT);
    /** Selected betShop ID */
    const [betShopId, setBetShopId] = useState(data.betShopId || null);
    /** Selected cashier ID */
    const [cashierId, setCashierId] = useState(data.cashierId || null);
    /** Selected betShop Currency */
    const [selectedCurrency, setSelectedCurrency] = useState(data.currency || null);
    /** Selected amount*/
    const [totalAmount, setTotalAmount] = useState("");

    /** Function, handler for amount input change
		 * @function
         * @param {string} value
		 * @memberOf MoneyTransferPopup
    */
    const handleTotalAmountChange = value => {
        if (value === '' || (hasDotAndDotIsAtTheEnd(value)) || INTEGER_AND_DECIMAL_NUMBERS_REGEX.test(value)) {
            setTotalAmount(value)
        }
    }


    /** Function to call on transfer button click
		 * @function
		 * @memberOf MoneyTransferPopup
    */
    const handleTransfer = () => {
        if(cashierId && betShopId && Number(totalAmount) > 0){
            transferToCashier({
                cashierId,
                betShopId,
                amount: totalAmount,
                transactionType: action
            }, () => {
                if(pathname.startsWith(Paths.MANAGER_CASHIERS)){
                    getCashiers(searchParams.id)
                }
                handleCloseModal();
            })
        }
    }

    const handleFavAmountClick = value => {
        const currentValue = totalAmount ?? 0;
        setTotalAmount(Number(currentValue) + value);
    }

    return (
        <Modal
            className="rt--modal rt--modal-money-transfer"
            title={(
                <div className='rt--flex rt--justify-between rt--align-center'>
                    <span className='rt--title rt--font-normal rt--font-regular'>{t("common.moneyTransfer")}</span>
                    <i className='icon-close rt--font-bigest rt--cursor-pointer' onClick={handleCloseModal} />
                </div>
            )}
            visible={true}
            onCancel={() => handleCloseModal()}
            maskClosable={false}
            closable={false}
            onOk={handleTransfer}
            cancelButtonProps={{ className: 'rt--button-secondary' }}
            okButtonProps={{ disabled: isSaving || !cashierId || !betShopId || Number(totalAmount) <= 0 }}
            cancelText={t('common.cancel')}
            okText={t('common.transfer')}
            getContainer={() => document.getElementById('rt--modal-root')}
            width={POPUP_SIZE.SMALL}
            centered
        >
            <div className='rt--modal-money-transfer'>
                <div className='rt--flex rt--modal-tabs rt--mb-24'>
                    <div
                        className={'rt--modal-tabs-item rt--flex rt--align-center rt--justify-center rt--flex-equal rt--cursor-pointer' + (action === MONEY_TRANSFER_ACTION.DEPOSIT ? " rt--modal-tabs-item-selected" : "")}
                        onClick={() => setAction(MONEY_TRANSFER_ACTION.DEPOSIT)}
                    >
                        <span className='rt--title rt--font-medium rt--font-normal rt--font-capitalize'>{t("common.deposit")}</span>
                    </div>
                    <div
                        className={'rt--modal-tabs-item rt--flex rt--align-center rt--justify-center rt--flex-equal rt--cursor-pointer' + (action === MONEY_TRANSFER_ACTION.WITHDRAW ? " rt--modal-tabs-item-selected" : "")}
                        onClick={() => setAction(MONEY_TRANSFER_ACTION.WITHDRAW)}
                    >
                        <span className='rt--title rt--font-medium rt--font-normal rt--font-capitalize'>{t("common.withdraw")}</span>
                    </div>
                </div>

                {
                    !data.betShopId && (
                        <AvailableBetshops
                            onSelect={( id, betshop) => {
                                setSelectedCurrency(betshop.currency);
                                setBetShopId(id);
                                setCashierId(null)
                            }}
                        />
                    )
                }
                {
                    !data.cashierId && (
                        <AvailableCashiers
                            betShopId={betShopId}
                            onSelect={id => setCashierId(id)}
                        />
                    )
                }

                <div className={'rt--modal-form-item rt--flex rt--flex-col' + (!cashierId ? " rt--modal-form-item-disabled" : "")}>
                    <span className='rt--title rt--font-medium rt--font-normal rt--mb-8'>{t("common.transferAmount")}</span>
                    <Input
                        className='rt--input'
                        placeholder={t("common.enter") + " " + t("common.transferAmount")}
                        value={totalAmount}
                        onChange={e => handleTotalAmountChange(e.target.value)}
                        disabled={!cashierId}
                    />
                </div>

                <div className={'rt--modal-form-item rt--flex rt--flex-col rt--mt-12' + (!cashierId ? " rt--modal-form-item-disabled" : "")}>
                    <FavoriteAmounts 
                        currencyCode={selectedCurrency}
                        handleClick={handleFavAmountClick}
                        walletUserType={WALLET_OWNER_TYPE.CASHIER}
                    />
                </div>
            </div>
        </Modal>
    )
}

/** MoneyTransferPopup propTypes
    * PropTypes
*/
MoneyTransferPopup.propTypes = {
    /** Function to close popup */
    handleCloseModal: PropTypes.func,
    /** Redux state property is true when money transfer request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to transfer money to cashier */
    transferToCashier: PropTypes.func,
    /** Redux action to get cashiers */
    getCashiers: PropTypes.func,
    /** Popup data */
    data: PropTypes.shape({
        /** Passed betshop Id */
        betshopId: PropTypes.string,
        /** Passed cashier Id */
        cashierId: PropTypes.string
    })
}

const mapStateToProps = state => {
    return {
        isSaving: state.wallet.isSaving
    }
}

const mapDispatchToProps = dispatch => (
    {
        transferToCashier: (data, onSuccess) => {
            dispatch(transferToCashier(data, onSuccess))
        },

        getCashiers: id => {
            dispatch(getCashiers(id));
        }
    }
)


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    MoneyTransferPopup
);