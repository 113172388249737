import moment from 'moment/moment';

import { DATE_TIME_FORMAT, TIME_FORMAT } from 'constants/common.constants';

/** Format date
     * @function
     * @param {Date} date - date
     * @returns {string}
 */
export const formatDate = date => moment(date).format("DD/MM/YYYY")

/** Format date and time
     * @function
     * @param {Date} date - date
     * @param {boolean} tz - add Timezone
     * @returns {string}
 */
export const formatDateTime = (date, tz = true, sec = false) => {
    const dateTimeFormat = sec ? 'DD/MM/YYYY HH:mm:ss.SSS' : 'DD/MM/YYYY HH:mm'
    const format = tz ? dateTimeFormat + " (UTCZ)" : dateTimeFormat;
    const timeZone = getTimeZone();
    const hours = parseInt(timeZone);
    const minutes = (timeZone % 1) * 60;
    if (tz && date.toISOString().indexOf("Z") === -1) {
        date = new Date(date);
        date.setHours(date.getHours() + hours);
        date.setMinutes(date.getMinutes() + minutes);
    }
    return moment(date).format(format)
}

/** get a day ago date from now
     * @function
     * @returns {Date}
 */
export const aDayAgo = () => {
    const now = new Date();
    return new Date(now.setDate(now.getDate() - 1));
};

/** get a week ago date from now
     * @function
     * @returns {Date}
 */
export const aWeekAgo = () => {
    const now = new Date();
    return new Date(now.setDate(now.getDate() - 7));
};

/** get users timezone
     * @function
     * @returns {string}
 */
export const getTimeZone = () => {
    const date = new Date();
    const timeZone = date.getTimezoneOffset() / 60;
    return timeZone > 0 ? "-" + timeZone : timeZone * -1
}

/** get the end of date
     * @function
     * @param {Date} d - date
     * @returns {Date}
 */
export const endOfDay = d => {
    const now = d || new Date();
    now.setHours(23, 59, 59, 999);
    return now;
};

/** get the start of date
     * @function
     * @param {Date} d - date
     * @returns {Date}
 */
export const startOfDay = d => {
    const now = d || new Date();
    now.setHours(0, 0, 0, 0);
    return now;
};

/** make date to UTC
     * @function
     * @param {Date} date - date
     * @returns {Date}
 */
export const getUTCDate = date => {
    return date.toISOString();
};

/** Get current date
     * @function
     * @param {boolean} round - round the time up to the nearest hour
     * @returns {object} - Moment date
 */
export const getNow = round => {
    const date = moment();
    if (!round) return date;
    const roundUp = date.minute() || date.second() || date.millisecond() ? date.add(1, 'hour').startOf('hour') : date.startOf('hour');
    return roundUp;
}

/** Get yesterday day
     * @function
     * @param {boolean} round - round the time up to the nearest hour
     * @returns {object} - Moment date
 */
export const yesterday = round => {
    const date = moment().subtract(1, 'days');
    if (!round) return date;
    const roundUp = date.minute() || date.second() || date.millisecond() ? date.add(1, 'hour').startOf('hour') : date.startOf('hour');
    return roundUp;
}

/** Get tomorrow day
     * @function
     * @param {boolean} round - round the time up to the nearest hour
     * @returns {object} - Moment date
 */
export const tomorrow = round => {
    const date = moment().add(1, 'days');
    if (!round) return date;
    const roundUp = date.minute() || date.second() || date.millisecond() ? date.add(1, 'hour').startOf('hour') : date.startOf('hour');
    return roundUp;
}

/** Get a week ago day
     * @function
     * @param {number} count - number of weeks
     * @param {boolean} round - round the time up to the nearest hour
     * @returns {object} - Moment date
 */
export const weekago = (count, round) => {
    const date = moment().subtract(count, 'weeks');
    if (!round) return date;
    const roundUp = date.minute() || date.second() || date.millisecond() ? date.add(1, 'hour').startOf('hour') : date.startOf('hour');
    return roundUp;
}

/** Get months ago day
     * @function
     * @param {number} count - mounths count
     * @param {boolean} round - round the time up to the nearest hour
     * @returns {object} - Moment date
 */
export const monthAgo = (count, round) => {
    const date = moment().subtract(count * 30, 'days');
    if (!round) return date;
    const roundUp = date.minute() || date.second() || date.millisecond() ? date.add(1, 'hour').startOf('hour') : date.startOf('hour');
    return roundUp;
}

/** Get first day of month
     * @function
     * @returns {object} - Moment date
 */
export const firstDayOfCurrentMonth = () => {
    const date = moment().startOf('month')
    return date;
}

/** Get first day of year
     * @function
     * @returns {object} - Moment date
 */
export const firstDayOfCurrentYear = () => {
    const date = moment().startOf('year')
    return date;
}

/** Get defualt dates for all the filters
     * @function
     * @description - "from" date is 24 hour ago, and "to" date is now
     * @returns {object}
 */
export const getDefaultDate = () => {
    const TIME_FROM = moment(getNow(true), TIME_FORMAT);
    const TIME_TO = moment(getNow(true), TIME_FORMAT);
    let from_d, to_d;

    to_d = moment(getNow(true), DATE_TIME_FORMAT);
    from_d = yesterday(true);

    from_d.set({ hour: TIME_FROM.get('hour'), minute: TIME_FROM.get('minute') });
    to_d.set({ hour: TIME_TO.get('hour'), minute: TIME_TO.get('minute') })

    return {
        from: from_d.toDate(),
        to: to_d.toDate()
    }
}
