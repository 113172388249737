import i18n from 'i18next';
import { message } from 'antd';

import { RESPONSE_CODES } from "constants/errorCode.constants";
import {ERROR_MESSAGE_RESTRICTIONS} from "constants/message.constants";

const restrictShowError = (status, resource) => {
    return ERROR_MESSAGE_RESTRICTIONS
            .some(restriction => (
                restriction.status === status &&
                restriction.resource === resource
            ));
}

/** Show error notification
     * @function
     * @param {string} msg - error message
     * @param {string} status - error status
     * @param {string} resource - error resource
*/

export const showError = (msg, status, resource) => {
    if (restrictShowError(status, resource)) {
        return;
    }

    console.log(msg, status, resource)
    if(!status) return message.error({className:'rt--error-message',content:msg});

    if(!Object.values(RESPONSE_CODES).includes(status)) return message.error({className:'rt--error-message',content:msg});

    const st = Object.keys(RESPONSE_CODES).find(key => RESPONSE_CODES[key] === status);
    let text = i18n.t(`errors.message.${st}`).replace("%resource%", resource && resource !== "None" ? i18n.t(`errors.resources.${resource}`) : "");

    if(st === "InvalidParameters" && msg){
        text = msg;
    }

    message.error({className:'rt--error-message',content: text === "" ? " ": text});
}


/** Show success notification
     * @function
     * @param {string} msg - error message
*/

export const showSuccess = msg => {
    return message.success({className:'rt--success-message', content: msg})
}
