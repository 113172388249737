import { useSelector } from 'react-redux';

const selectCurrencies = state => state.profile.userInfo.currencies;

const getCurrencyDecimalCount = (currencyCode, currencies = []) => {
    let currency = currencies.find(c => c.code.toLowerCase() === currencyCode.toLowerCase());
    
    if(currency){
        return currency.decimalCount;
    }

    return 0;
}

const useFormat = () => {

    //#region ------------------------------------------- REDUX --------------------------------------------//

    const currencies = useSelector(selectCurrencies);

    //#endregion

    // Format Number
    const formatNumber = num => {
        if (num === null || num === undefined) return "";

        let separator = " ", decimal = ".";

        const str = num.toString();
        const parts = str.split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator);
        return parts.join(decimal);
    }

    // Format amount
    const formatAmount = (amount, currencyCode) => {
        if(amount === null || amount === undefined) return "-";

        const decimalCount = getCurrencyDecimalCount(currencyCode, currencies);
        
        let num = Number(amount);
        const degree = Math.pow(10, decimalCount);

        num = Math.trunc(num * degree) / degree

        return formatNumber( Number(num).toFixed( decimalCount ) );
    }

    return {
        formatNumber,
        formatAmount
    }
}

export default useFormat;