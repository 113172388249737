import {BETSLIP_FILTER_TYPE, ORDER_DIRECTION, PROJECT_PROVIDER_TYPE} from "constants/common.constants";
import { WALLET_OWNER_TYPE } from 'constants/common.constants';

import { getDefaultDate } from "utils/dateTime";

const initialState = {
	auth: {
		isLoading: false,
		expires: -1,
		passwordSettings: {
			isLoading: false,
			passwordSettings: {},
		},
		resetPassword: {
			isReseting: false,
		},
		languages: {},
		translationsLoaded: false,
		isLanguagesLoading: false,
		languagesLoaded: false
	},
	profile: {
		isUserInfoLoading: true,
		userInfo: {},
		playerInfo: null,
		isUserCurrenciesLoading: false,
		currencies: []
	},
	betshops: {
		betshops: [],
		betshopId: null,
		isLoading: true,
		isSaving: false
	},
	providers: {
		providers: {
			[PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS]: {},
			[PROJECT_PROVIDER_TYPE.SPORTBOOK]: {},
            [PROJECT_PROVIDER_TYPE.GOLDEN_RACE]: {}
		},
		provider: null,
		isLoading: true,

	},
	common: {
		popup: {
			key: 0
		}
	},
	ticket: {
		ticketInfo: [],
		payoutTicket: [],
		isTicketInfoLoading: false,
		isPayoutLoading: false
	},
	managerBetshops: {
		betshops: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 20,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC
		},
		filters: {
			nameOrId: ""
		},
		isAvailableCashiersLoading: false,
		availableCashiersForAdd: [],
		availableCashiersForTransfer: [],
		availableBetshops: [],
		isAvailableBetshopsLoading: false,
	},
	cashiers: {
		cashiers: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 1000,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC
		},
		filters: {
			userNameOrId: ""
		},
		isAvailableLoading: false,
		availableCashiers: []
	},
	sessions: {
		sessions: [],
		isLoading: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 20,
			orderBy: "StartTime",
			orderDirection: ORDER_DIRECTION.DESC
		},
		filters: {
			userNameOrId: "",
			betShopId: "",
			period: null,
			...getDefaultDate()
		},
	},
	transactions: {
		transactions: [],
		isLoading: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 20,
			orderBy: "TransactionTime",
			orderDirection: ORDER_DIRECTION.DESC
		},
		filters: {
			[WALLET_OWNER_TYPE.CASHIER]: {
				transactionId: "",
				betShopId: "",
				transactionTypes: "",
				cashier: "",
				period: null,
				player: "",
				...getDefaultDate(),
			},
			[WALLET_OWNER_TYPE.BETSHOP]: {
				transactionId: "",
				betShopId: "",
				transactionTypes: "",
				cashier: "",
				period: null,
				...getDefaultDate(),
			}
		},
	},
	betHistory: {
		bets: [],
		sorting: {
			page: 1,
			limit: 20,
			orderBy: "BetTime",
			orderDirection: ORDER_DIRECTION.DESC
		},
		filters: {
			betSlipId: "",
			betSlipFilterType: BETSLIP_FILTER_TYPE.BET_TIME,
			userNameOrId: "",
			betShopNameOrId: null,
			providertype: "",
			status: "",
			type: "",
			period: null,
			playerUserNameOrId: "",
			...getDefaultDate()
		},
		total: 0,
		totals: {
			isLoading: false,
			totals: {}
		},
		isLoading: false
	},
	betshopPerformanceReport: {
		report: [],
		isLoading: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 20,
			orderBy: "Ggr",
			orderDirection: ORDER_DIRECTION.DESC
		},
		filters: {
			betShopNameOrId: "",
			currencies: null,
			period: null,
			...getDefaultDate()
		},
		totals: {
			totals: {},
			isLoading: false
		}
	},
	cashierPerformanceReport: {
		report: [],
		pendingBets: {
			totalBetsAmount: null,
			totalBetsCount: null,
		},
		isLoading: false,
		total: 0,
		filters: {
			period: null,
			...getDefaultDate()
		},
	},
	handover: {
		isLoading: false,
		isSaving: false,
		availableCashiersForHandover: []
	},
	wallet: {
		isSaving: false
	},
	players: {
		isLoading: false,
		isSaving: false,
		availableCurrencies: [],
		registrationForm: [],
		playerInfo: {},
		isWithdrawalsLoading: false,
		withdrawals: []
	}
}

export default initialState;
