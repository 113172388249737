import React from 'react';

import SelectBetShopComponent from 'components/auth/selectBetShop';

import withAnanymous from 'hocs/withAnanymous';

const ResetPasswordRoute = () => {
    return (
        <SelectBetShopComponent /> 
    )
}

export default withAnanymous(ResetPasswordRoute);