import React from 'react';

import { useTranslation } from 'react-i18next';

/** Betshop Manager Reports Page */
const BetshopManagerReports = () => {

    const { t } = useTranslation();

    return (
        <div className='rt--mt-32'>
            <span className='rt--title rt--font-big rt--font-regular'>{t("common.reports")}</span>
        </div>
    )
}

export default BetshopManagerReports;
