import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';

import { Input, Popover } from 'antd';

import { getPlayerWithdrawals } from 'store/actions/dashboard/players.action';

import { getUser } from 'utils/auth';

import { ENVIRONMENT_TYPE, DATE_TIME_FORMAT } from 'constants/common.constants';

import playerInfoType from 'types/playerInfo.type';
import playerWithdrawType from 'types/playerWithdraw.type';

import NoTicketImg from "assets/images/noTicket.png";

/** Player Transfer Withdraw Form Withdrawals Dropdown Component */
const Withdrawals = ({
    playerInfo,
    getPlayerWithdrawals,
    withdrawals,
    onChange,
    verificationCode
}) => {
    const { t } = useTranslation();

    /** Selected value */
    const [value, setValue] = useState(null);

    /** Flag to show/hide menu */
    const [showList, setShowList] = useState(false);

    const inputValue = value ? `${value.amount} ${playerInfo.currencyCode}` : ""

    /** Get withdrawals */
    useEffect(() => {
        if (playerInfo.id && getUser()?.environmentType === ENVIRONMENT_TYPE.RETAIL) {
            getPlayerWithdrawals(playerInfo.id, verificationCode)
        }
    }, [])

    /** Function to call on dropdown list item click
		 * @function
         * @param {object} withdrawal - clicked item data
		 * @memberOf Withdrawals
    */
    const handleClick = withdrawal => {
        setValue(withdrawal);
        onChange && onChange(withdrawal);
        setShowList(false)
    }

    return (
        <div className='rt--modal-form-item rt--flex rt--flex-col rt--mb-16'>
            <span className='rt--title rt--font-regular rt--font-normal rt--mb-8'>{t("common.withdrawal")}</span>
            <div className='rt--modal-withdrawals-dropdown'>
                <Popover
                    placement="bottom"
                    overlayClassName='rt--modal-withdrawals-dropdown-overlay'
                    content={
                        (
                            <div className='rt--modal-withdrawals-dropdown-list rt--pt-8 rt---pb-8'>
                                {
                                    withdrawals.length > 0 ? withdrawals.map(withdrawal => (
                                        <div
                                            className='rt--modal-withdrawals-dropdown-list-item rt--pl-16 rt--pr-16 rt--flex rt--align-center rt--cursor-pointer'
                                            key={withdrawal.id}
                                            onClick={() => handleClick(withdrawal)}
                                        >
                                            <div className='rt--modal-withdrawals-dropdown-list-item-content rt--flex rt--flex-equal rt--flex-col rt--pl-8 rt--justify-cneter'>
                                                <b className='rt--title rt--font-normal rt--font-regular'>{`${withdrawal.amount} ${playerInfo.currencyCode}`}</b>
                                                <div className='rt--flex rt--flex-equal rt--mt-4 rt--align-center'>
                                                    <span className='rt--title rt--font-small rt--font-regular'>{moment.utc(withdrawal.date).local().format(DATE_TIME_FORMAT)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )) : (
                                        <div className='rt--modal-withdrawals-dropdown-list-empty rt--flex rt--flex-col rt--align-center rt--justify-center rt--pt-24 rt--pb-24'>
                                            <img alt={t("common.noWithdrawal")} src={NoTicketImg} />
                                            <span className='rt--title rt--pt-8 rt--text-center rt--font-medium rt--font-big'>{t("common.noWithdrawal")}</span>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                    trigger="click"
                    visible={showList}
                    onVisibleChange={setShowList}
                >
                    <div className='rt--modal-withdrawals-dropdown-input'>
                        <Input
                            readOnly={true}
                            className='rt--input'
                            placeholder={`${t('common.select')} ${t("common.withdrawal")}`}
                            value={inputValue}
                        />
                        <i className='icon-down rt--font-bigest' />
                    </div>
                </Popover>
            </div>
        </div>
    )
}

/** Withdrawals propTypes
    * PropTypes
*/
Withdrawals.propTypes = {
    /** Redux state property player withdraws */
    withdrawals: PropTypes.arrayOf(playerWithdrawType),
    /**Redux action to get player withdraws */
    getPlayerWithdrawals: PropTypes.func,
    /** Redux state property, player info */
    playerInfo: playerInfoType,
    /** Function which will fire on change */
    onChange: PropTypes.func,
    /** The verification code */
    verificationCode: PropTypes.string,
}

const mapStateToProps = state => {
    return {
        withdrawals: state.players.withdrawals,
        playerInfo: state.players.playerInfo
    }
}

const mapDispatchToProps = dispatch => (
    {
        getPlayerWithdrawals: (playerId, verificationCode) => {
            dispatch(getPlayerWithdrawals(playerId, verificationCode))
        }
    }
)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Withdrawals
);
