import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import CheckboxesDropdown from "components/common/checkboxesDropdown";

/** Table Columns chooser component */
const ColumnsButton = ({ 
    columns, 
    onApply,
    defaultSelectedColumns 
}) => {
    const { t } = useTranslation();

    /** Function , fires on apply button click
       * @function
       * @param {array} value - selected columns
       * @memberOf ColumnsButton
   */
    const applyColumns = value => {
        onApply(value);
    }

    return (
        <CheckboxesDropdown
            columns={columns}
            buttons={[
                { text: t('common.apply'), onClick: value => applyColumns(value) }
            ]}
            buttonText={t('common.columns')}
            buttonClassName="rt--table-columns-button rt--flex rt--align-center rt--justify-center rt--pl-16 rt--pr-8 rt--ml-16"
            allowEmpty={true}
            selectAllByDefault={false}
            defaultSelectedColumns={defaultSelectedColumns}
        />
    )
}

/** ColumnsButton propTypes
    * PropTypes
*/
ColumnsButton.propTypes = {
    /** Array of columns of table which can be excluded */
    columns: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        key: PropTypes.string
    })),
    /** Function which will fire on apply button click */
    onApply: PropTypes.func,
    /** Default Selected columns */
    defaultSelectedColumns: PropTypes.arrayOf(PropTypes.string)
}

export default ColumnsButton;