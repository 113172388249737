import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Table from "components/common/table";
import Pagination from "components/common/pagination";

import { getSessions, setSessionsFilters, setSessionsSorting } from 'store/actions/dashboard/sessions.action';

import sortingType from 'types/sorting.type';
import sessionType from 'types/session.type';

import noDataImg from "assets/images/noContent.png"

/** Betshop Manager Sessions Page Sessions Table Component */

const Sessions = ({
    getSessions,
    setSessionsSorting,
    setSessionsFilters,
    filters,
    isLoading,
    sessions,
    sorting,
    total,
    columns
}) => {

    const { t } = useTranslation();

    return (
        <Fragment>
            <Table
                name="sessions"
                loadFn={getSessions}
                setSortingFn={setSessionsSorting}
                columns={columns}
                data={sessions}
                isLoading={isLoading}
                sorting={sorting}
                emptyImg={noDataImg}
                emptyText={t("common.noSessionsFound")}
                actions={[]}
                enableReload={true}
                setFiltersFn={setSessionsFilters}
                filters={filters}
            />
            <Pagination
                total={total}
                onChange={value => setSessionsSorting({
                    ...sorting,
                    ...value
                })}
                page={sorting.page}
                limit={sorting.limit}
                loadFn={getSessions}
            />
        </Fragment>

    )
}

/** Sessions propTypes
    * PropTypes
*/
Sessions.propTypes = {
    /** Redux action to get sessions */
    getSessions: PropTypes.func,
    /** Redux action to set sessions sorting */
    setSessionsSorting: PropTypes.func,
    /** Redux action to set sessions filters */
    setSessionsFilters: PropTypes.func,
    /** Redux state property, is true when loading sessions */
    isLoading: PropTypes.bool,
    /** Redux state property, sessions sorting */
    sorting: sortingType,
    /** Redux state property, the array of sessions */
    sessions: PropTypes.arrayOf(sessionType),
    /** Redux state property, sessions totla count */
    total: PropTypes.number,
    /** Redux state property, sessions filters */
    filters: PropTypes.object,
    /** Columns data */
    columns: PropTypes.arrayOf(PropTypes.object)
}

const mapDispatchToProps = dispatch => (
    {
        getSessions: id => {
            dispatch(getSessions(id));
        },

        setSessionsSorting: sorting => {
            dispatch(setSessionsSorting(sorting));
        },

        setSessionsFilters: filters => {
            dispatch(setSessionsFilters(filters));
        },
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.sessions.isLoading,
        sorting: state.sessions.sorting,
        sessions: state.sessions.sessions,
        filters: state.sessions.filters,
        total: state.sessions.total
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Sessions
);
