import axios from "axios";

import Methods from "constants/httpMethods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { showSuccess } from "utils/message";
import LocalStorageUtils from "utils/localStorage";
import { logout } from "utils/auth";

import {
    SET_HANDOVER_ACTION_BEFORE,
    SET_HANDOVER_ACTION_FINISH,
    SET_HANDOVER_SAVE_ACTION_BEFORE,
    SET_HANDOVER_SAVE_ACTION_FINISH,
    SET_AVAILABLE_CASHIERS_FOR_CASHIERS
} from "store/actionTypes";

const setHandoverActionBefore = () => ({
    type: SET_HANDOVER_ACTION_BEFORE,
});

const setHandoverActionFinished = () => ({
    type: SET_HANDOVER_ACTION_FINISH,
});

const setHandoverSaveActionBefore = () => ({
    type: SET_HANDOVER_SAVE_ACTION_BEFORE,
});

const setHandoverSaveActionFinished = () => ({
    type: SET_HANDOVER_SAVE_ACTION_FINISH,
});

const setAvailableCashiersForHandover = cashiers => ({
    type: SET_AVAILABLE_CASHIERS_FOR_CASHIERS,
    payload: { cashiers },
});

export const getAvailableCashiersForHandover = id => {
    return (dispatch, getState) => {
        
        dispatch(setHandoverActionBefore());

        return axios({
            url: ApiUrls.GET_AVAILABLE_CASHIERS_FOR_HANDOVER,
            method: Methods.GET,
            params: { id },
        })
            .then(({ data: { value: cashiers } }) => {
                dispatch(setAvailableCashiersForHandover(cashiers));
                dispatch(setHandoverActionFinished());
            })
            .catch((ex) => {
                dispatch(setHandoverActionFinished());
            });
    }
}

export const handover = (cashier, onSuccess) => {
    return dispatch => {
        
        dispatch(setHandoverSaveActionBefore());

        return axios({
            url: ApiUrls.CASHIER_HANDOVER,
            method: Methods.POST,
            data: {
                cashierId: cashier.id
            },
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    showSuccess(data.message);
                    onSuccess && onSuccess();

                    const userName = cashier.userName;
                    const betShopData = LocalStorageUtils.get('betshopData') || {};
                    delete betShopData[userName];
                    LocalStorageUtils.set('betshopData', betShopData);

                    LocalStorageUtils.set("handover", data.value);
                    logout();
                }
                dispatch(setHandoverSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setHandoverSaveActionFinished());
            });
    }
}

export const rejectHandover = (handoverId, onSuccess) => {
    return dispatch => {
        
        dispatch(setHandoverSaveActionBefore());

        return axios({
            url: ApiUrls.CASHIER_REJECT_HANDOVER,
            method: Methods.POST,
            data: {
                handoverId
            },
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    LocalStorageUtils.remove("handover");
                    onSuccess && onSuccess();
                }
                dispatch(setHandoverSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setHandoverSaveActionFinished());
            });
    }
}
