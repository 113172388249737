import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { connect } from 'react-redux';

import AutoComplete from 'components/common/autoComplete';
import Export from "components/common/export";

import { setCashiersFilters, getCashiers } from "store/actions/dashboard/cashiers.action";
import { resetBetshopLimit } from "store/actions/dashboard/wallet.action";
import { changePopupVisibility } from 'store/actions/dashboard/common.action';
import { getManagerAvailableCashiers } from "store/actions/dashboard/cashiers.action";

import PATHS from 'constants/path.constants';
import ApiUrls from "constants/api.constants";
import { POPUP_TYPE } from 'constants/popup.constants';
import { ENVIRONMENT_TYPE } from 'constants/common.constants';

import { isUserBlocked, getUser } from 'utils/auth';

import autoSuggestionType from "types/autoSuggestion.type";

/** Betshop Manager Cashiers Page Cashiers Table Filters Component */

const CashiersFilters = ({
    setCashiersFilters,
    getCashiers,
    changePopupVisibility,
    isSaving,
    resetBetshopLimit,
    getManagerAvailableCashiers,
    availableCashiers,
    columns,
    filters
}) => {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const searchParams = useParams();

    /** Load available cashiers for betshop manager */
    useEffect(() => {
        getManagerAvailableCashiers(searchParams.id);
    }, [])

    /** Function, handler for name or id search input
		 * @function
		 * @param {string} value - the field value
		 * @memberOf CashiersFilters
    */
    const handleSearchChange = value => {
        setCashiersFilters({
            userNameOrId: value && value.length > 2 ? value : ""
        })
        setTimeout(() => {
            getCashiers(searchParams.id)
        }, 10)
    }

    useEffect(() => () => setCashiersFilters({ userNameOrId: "" }), [])

    const betShopName = (new URLSearchParams(location.search)).get("name")

    return (
        <div className='rt--filters-header rt--flex rt--flex-equal rt--justify-between rt--align-center rt--mt-32 rt--pb-24 rt--mb-30'>
            <div className='rt--flex rt--align-center rt--filters-header-title'>
                <i
                    className='icon-left rt--font-bigest rt--cursor-pointer'
                    onClick={() => navigate(PATHS.MANAGER)}
                />
                <span className='rt--title rt--font-big rt--font-regular rt--pl-4'>{betShopName}</span>
            </div>

            <div className='rt--flex'>
                <div className='rt--filters-header-search'>

                    <AutoComplete
                        placeholder={t("common.searchByNameOrId")}
                        items={availableCashiers}
                        onSelect={handleSearchChange}
                    />
                    <i className='icon-search rt--font-bigest'></i>

                </div>

                <Export
                    filters={{
                        ...filters,
                        betShopId: searchParams.id,
                    }}
                    title={t("common.cashiers")}
                    columns={columns}
                    url={ApiUrls.EXPORT_CASHIERS}
                    tableName={t("common.cashiers")}
                />

                {
                    getUser()?.environmentType === ENVIRONMENT_TYPE.AGENT_SYSTEM && (
                        <button
                            className={'rt--button rt--button-secondary rt--ml-24 rt--betshop-manager-reset ' + (isUserBlocked() ? " rt--button-disabled" : "")}
                            disabled={isUserBlocked()}
                            onClick={e => {
                                changePopupVisibility({
                                    key: POPUP_TYPE.CONFIRMATION,
                                    data: {
                                        onOk: () => resetBetshopLimit(searchParams.id),
                                        isSaving: isSaving,
                                        title: t("common.resetBetshopLimit"),
                                        description: t("common.resetBetshopLimitConfirmationDescription"),
                                        okText: t("common.reset")
                                    }
                                })
                            }}
                        >
                            <span className='rt--flex rt--justify-center rt--align-center'>
                                <i className='icon-reset rt--font-bigest'></i>
                                <span className='rt--title rt--font-medium rt--font-normal'>{t("common.resetLimit")}</span>
                            </span>
                        </button>
                    )
                }

                <button
                    className={'rt--button rt--button-complimentary rt--ml-24' + (isUserBlocked() ? " rt--button-disabled" : "")}
                    onClick={e => {
                        changePopupVisibility({ key: POPUP_TYPE.ADD_CASHIER })
                    }}
                    disabled={isUserBlocked()}
                >
                    <span className='rt--flex rt--justify-center rt--align-center'>
                        <i className='icon-plus rt--font-bigest'></i>
                        <span className='rt--title rt--font-medium rt--font-normal'>{t("common.addCashier")}</span>
                    </span>
                </button>
            </div>
        </div>
    )
}

/** CashiersFilters propTypes
    * PropTypes
*/
CashiersFilters.propTypes = {
    /** Redux action to get cashiers */
    getCashiers: PropTypes.func,
    /** Redux action to set cashiers filters */
    setCashiersFilters: PropTypes.func,
    /** Redux action to reset betshop limit */
    resetBetshopLimit: PropTypes.func,
    /** Redux action to open popup */
    changePopupVisibility: PropTypes.func,
    /** Redux state property, is true when saving cashier */
    isSaving: PropTypes.bool,
    /** Redux action to get available cashiers for manager */
    getManagerAvailableCashiers: PropTypes.func,
    /** Redux state property, available cashiers for manager */
    availableCashiers: PropTypes.arrayOf(autoSuggestionType),
    /** Table columns */
    columns: PropTypes.arrayOf(PropTypes.object),
    /** Redux state property, cashiers filters */
    filters: PropTypes.object
}

const mapDispatchToProps = dispatch => (
    {
        getCashiers: id => {
            dispatch(getCashiers(id));
        },

        setCashiersFilters: filters => {
            dispatch(setCashiersFilters(filters));
        },

        resetBetshopLimit: betShopId => {
            dispatch(resetBetshopLimit(betShopId));
        },

        changePopupVisibility: data => {
            dispatch(changePopupVisibility(data))
        },

        getManagerAvailableCashiers: id => {
            dispatch(getManagerAvailableCashiers(id))
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.wallet.isSaving,
        availableCashiers: state.cashiers.availableCashiers,
        filters: state.cashiers.filters,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CashiersFilters);
