import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { PROJECT_PROVIDER_TYPE } from 'constants/common.constants';

/** Virtual Sport Iframe Component */
const GoldenRaceComponent = ({
    providers,
}) => {

    /** Golden Race  provider data */
    const data = providers[PROJECT_PROVIDER_TYPE.GOLDEN_RACE];

    const launchUrl = data?.launchUrl ?? null;

    return (
        <div className='rt--provider-virtual-sport'>
            {
                launchUrl && (
                    <iframe
                        src={launchUrl}
                        allowFullScreen
                        frameBorder="0"
                        title="Golden Race"
                    ></iframe>
                )
            }

        </div>

    )
}

/** GoldenRaceComponent propTypes
    * PropTypes
*/
GoldenRaceComponent.propTypes = {
    /** Redux state property, providers data */
    providers: PropTypes.object
}

const mapStateToProps = state => {
    return {
        providers: state.providers.providers,
    }
}


export default connect(mapStateToProps, null)(GoldenRaceComponent)