import axios from "axios";

import Methods from "constants/httpMethods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_CODES } from "constants/errorCode.constants";

import { isResponseSuccess } from "utils/request";
import { showSuccess } from "utils/message";

import { 
    SET_PLAYER_ACTION_BEFORE, 
    SET_PLAYER_ACTION_FINISH,
    SET_PLAYER_SAVE_ACTION_BEFORE,
    SET_PLAYER_SAVE_ACTION_FINISH,
    SET_PLAYER_REGISTRATION_FORM,
    SET_PLAYER_AVAILABLE_CURRENCIES,
    SET_PLAYER_INFO,
    SET_PLAYER_WITHDRAWALS_ACTION_BEFORE,
    SET_PLAYER_WITHDRAWALS_ACTION_FINISH,
    SET_PLAYER_WITHDRAWALS
} from "store/actionTypes";

const setPlayerActionBefore = () => ({
    type: SET_PLAYER_ACTION_BEFORE,
});

const setPlayerActionFinished = () => ({
    type: SET_PLAYER_ACTION_FINISH,
});

const setPlayerSaveActionBefore = () => ({
    type: SET_PLAYER_SAVE_ACTION_BEFORE,
});

const setPlayerSaveActionFinished = () => ({
    type: SET_PLAYER_SAVE_ACTION_FINISH,
});

const setPlayerRegistrationForm = registrationForm => ({
    type: SET_PLAYER_REGISTRATION_FORM,
    payload: { registrationForm }
});

export const getPlayerRegistrationForm = () => {
    return dispatch => {
        dispatch(setPlayerActionBefore());

        return axios({
            url: ApiUrls.GET_PLAYER_REGISTRATION_FORM,
            method: Methods.GET
        })
        .then(({data : {value: registrationForm}}) => {
            dispatch(setPlayerRegistrationForm(registrationForm))
            dispatch(setPlayerActionFinished());
        })
        .catch((ex) => {
            dispatch(setPlayerActionFinished());
        })
    }
}

const setPlayerAvailableCurrencies = currencies => ({
    type: SET_PLAYER_AVAILABLE_CURRENCIES,
    payload: { currencies }
});

export const getPlayerAvailableCurrencies = () => {
    return dispatch => {

        return axios({
            url: ApiUrls.GET_PLAYER_AVAILABLE_CURRENCIES,
            method: Methods.GET
        })
        .then(({data : {value: currencies}}) => {
            dispatch(setPlayerAvailableCurrencies(currencies))
        })
        .catch((ex) => {
            console.log(ex)
        })
    }
}

export const createPlayer = (data, onSuccess) => {
    return dispatch => {
        
        dispatch(setPlayerSaveActionBefore());

        const formData = new FormData();

        Object.keys(data).forEach((key) => {
            if(data[key] !== undefined){
                formData.append(key, data[key]);
            }
        });

        return axios({
            url: ApiUrls.CREATE_PLAYER,
            method: Methods.POST,
            headers: {
                "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
            },
            data: formData
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    showSuccess(data.message);
                    onSuccess && onSuccess();
                }
                dispatch(setPlayerSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setPlayerSaveActionFinished());
            });
    }
}

const setPlayerInfo = data => ({
    type: SET_PLAYER_INFO,
    payload: { data }
});

export const getPlayerInfo = (data, onSuccess) => {
    return dispatch => {
        dispatch(setPlayerActionBefore());
        return axios({
            url: ApiUrls.GET_PLAYER_INFO,
            method: Methods.GET,
            params: {
                ...data
            }
        })
            .then(({ data: { value: info } }) => {
                dispatch(setPlayerInfo(info))

                onSuccess && onSuccess(info);

                dispatch(setPlayerActionFinished());
            })
            .catch((ex) => {
                dispatch(setPlayerActionFinished());
            })
    }
}

const handleTransferResponse = (wallets, onSuccess) => {
    let hasError = false;
    if(wallets){
        hasError = wallets.some(w => w.status !== RESPONSE_CODES.Success)
    } else {
        hasError = true;
    }

    onSuccess && onSuccess(!hasError, wallets);
}


export const depositToPlayer = (data, onSuccess) => {
    return dispatch => {
        dispatch(setPlayerActionBefore());
        return axios({
            url: ApiUrls.PLAYER_DEPOSIT,
            method: Methods.POST,
            data: {
                ...data
            }
        })
            .then(({ data: { value: info } }) => {

                handleTransferResponse(info.wallets, onSuccess);

                dispatch(setPlayerActionFinished());
            })
            .catch((ex) => {
                dispatch(setPlayerActionFinished());
            })
    }
}

export const withdrawPlayer = (data, onSuccess) => {
    return dispatch => {
        dispatch(setPlayerActionBefore());
        return axios({
            url: ApiUrls.PLAYER_WITHDRAW,
            method: Methods.POST,
            data: {
                ...data
            }
        })
            .then(({ data: { value: info } }) => {

                handleTransferResponse(info.wallets, onSuccess);

                dispatch(setPlayerActionFinished());
            })
            .catch((ex) => {
                dispatch(setPlayerActionFinished());
            })
    }
}


const setPlayerWithdrawalsActionBefore = () => ({
    type: SET_PLAYER_WITHDRAWALS_ACTION_BEFORE,
});

const setPlayerWithdrawalsActionFinished = () => ({
    type: SET_PLAYER_WITHDRAWALS_ACTION_FINISH,
});

const setPlayerWithdrawals = data => ({
    type: SET_PLAYER_WITHDRAWALS,
    payload: { data }
});

export const getPlayerWithdrawals = (playerId, verificationCode) => {
    return dispatch => {
        dispatch(setPlayerWithdrawalsActionBefore());
        return axios({
            url: ApiUrls.GET_PLAYER_WITHDRAWALS,
            method: Methods.GET,
            params: {
                playerId, verificationCode 
            }
        })
            .then(({ data: { value: withdrawals } }) => {

                dispatch(setPlayerWithdrawals(withdrawals))

                dispatch(setPlayerWithdrawalsActionFinished());
            })
            .catch((ex) => {
                dispatch(setPlayerWithdrawalsActionFinished());
            })
    }
}