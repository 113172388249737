import {
    SET_PLAYER_ACTION_BEFORE,
    SET_PLAYER_ACTION_FINISH,
    SET_PLAYER_SAVE_ACTION_BEFORE,
    SET_PLAYER_SAVE_ACTION_FINISH,
    SET_PLAYER_REGISTRATION_FORM,
    SET_PLAYER_AVAILABLE_CURRENCIES,
    SET_PLAYER_INFO,
    SET_PLAYER_WITHDRAWALS_ACTION_BEFORE,
    SET_PLAYER_WITHDRAWALS_ACTION_FINISH,
    SET_PLAYER_WITHDRAWALS
} from "store/actionTypes";


export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_PLAYER_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_PLAYER_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_PLAYER_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_PLAYER_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_PLAYER_REGISTRATION_FORM:
            return {
                ...state,
                registrationForm: payload.registrationForm
            };
        case SET_PLAYER_AVAILABLE_CURRENCIES:
            return {
                ...state,
                availableCurrencies: payload.currencies,
            };
        case SET_PLAYER_INFO:
            return {
                ...state,
                playerInfo: payload.data,
            };
        case SET_PLAYER_WITHDRAWALS_ACTION_BEFORE:
            return {
                ...state,
                isWithdrawalsLoading: true,
            };
        case SET_PLAYER_WITHDRAWALS_ACTION_FINISH:
            return {
                ...state,
                isWithdrawalsLoading: false,
            };
        case SET_PLAYER_WITHDRAWALS:
            return {
                ...state,
                withdrawals: payload.data
            };
        default:
            return state;
    }
};
