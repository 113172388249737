import {
    SET_HANDOVER_ACTION_BEFORE,
    SET_HANDOVER_ACTION_FINISH,
    SET_HANDOVER_SAVE_ACTION_BEFORE,
    SET_HANDOVER_SAVE_ACTION_FINISH,
    SET_AVAILABLE_CASHIERS_FOR_CASHIERS
} from "store/actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_HANDOVER_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_HANDOVER_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_HANDOVER_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_HANDOVER_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_AVAILABLE_CASHIERS_FOR_CASHIERS:
            return {
                ...state,
                availableCashiersForHandover: payload.cashiers,
            };
        default:
            return state;
    }
};
