import React from 'react';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import HandoverComponent from 'components/auth/handover';

import withAnanymous from 'hocs/withAnanymous';

import { CAPTCHA_KEY } from 'constants/common.constants';

const HandoverRoute = () => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA_KEY}>
            <HandoverComponent /> 
        </GoogleReCaptchaProvider>
    )
}

export default withAnanymous(HandoverRoute);