import {
    SET_SESSIONS_ACTION_BEFORE,
    SET_SESSIONS_ACTION_FINISH,
    SET_SESSIONS,
    SET_SESSIONS_SORTING,
    SET_SESSIONS_FILTERS
} from "store/actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_SESSIONS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_SESSIONS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_SESSIONS:
            return {
                ...state,
                sessions: payload.sessions.item2,
                total: payload.sessions.item1,
            };

        case SET_SESSIONS_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...payload.filters
                },
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            };
        case SET_SESSIONS_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        default:
            return state;
    }
};