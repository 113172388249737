import i18n from 'translations/config';

import { showSuccess } from './message';
import { isNumber, isString } from './javaScriptTypes';
import sessionStorageUtils from './sessionStorage';

import { BET_STATE } from 'constants/bets.constants';

/** Get query params
     * @function
     * @param {string} path - location path
     * @returns {object} - query params
 */
export const getUrlVars = path => {
    const href = path || window.location.href;
    const vars = {};
    const parts = href.replace(/[?&]+([^=&]+)=([^&]*)/gi,
        (m, key, value) => {
            vars[key] = value;
        }
    );
    return vars;
}

/** Set query params
     * @function
     * @param {string} param - param name
     * @param {string} value - param name
     * @returns {object} - query params
 */
export const setUrlParam = (param, value) => {
    let queryParams = new URLSearchParams(window.location.search);
    queryParams.set(param, value);
    window.history.pushState(null, null, "?" + queryParams.toString());
}

/** Get query params from obj
     * @function
     * @param {object} obj -query param object
     * @returns {string} - query string
 */
export const objectToQueryParams = (obj) =>{
    let string = '?';
    for (var key in obj) {
        if (string !== '?' && !Array.isArray(obj[key])) {
            string += '&';
        }
        string += Array.isArray(obj[key]) ? obj[key].reduce((acc, item) => acc + key + "=" + item + "&", '&').slice(0, -1) : key + "=" + obj[key];
    }
    return string;
}


/** Make first letter of string to upper case
     * @function
     * @param {string} str - string to convert
     * @returns {string}
 */
export const toUpperCaseFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1)

/** Make first letter of string to lower case
     * @function
     * @param {string} str - string to convert
     * @returns {string}
 */
 export const toLowerCaseFirstLetter = str => str.charAt(0).toLowerCase() + str.slice(1)

/** function which make a binary integer, from array of enum values, so server can detect selected enum values
    * @function
    * @param {array} arr - array of enum values(1,2,4,8,16,...)
    * @returns {number}
*/
export const flagsToBinary = arr => arr.reduce((a, b) => a ^ b, 0)

/** function which filter enum values, to values which are true with given binary number
     * @function
     * @param {array} flags - array of all enum values(1,2,4,8,16,...)
     * @param {binaryNum} number - binary integer, which represents the array of selected flags
     * @returns {array}
 */
export const binaryToFlags = (flags, binaryNum) => flags.filter(f => f & binaryNum);

/** Detects domain of the site without subdomains
 * @returns {string} Returns user current domain
 */
export const getDomain = (subDomainIncluded = true, excludeWWW = true) => {
    let currentDomain = ""
    const { host } = window.location;

    if (subDomainIncluded) {
        currentDomain = host;
    } else {

        let separate = host.split(".");

        if (separate.length > 2) {
            separate = separate.slice(separate.length - 2, separate.length);
            currentDomain = separate.join('.');
        }
    }

    currentDomain = currentDomain.toLowerCase();

    if (excludeWWW && currentDomain.startsWith('www.')) {
        currentDomain = currentDomain.substring(4)
    }

    // if(excludeWWW || !excludeWWW){
    //     return 'retail-cashier.tst-digi.com'
    // }

    return currentDomain;
}
/** Make number to float(string)
     * @function
     * @param {number} num
     * @param {number} decimal - number counts after floating number
     * @returns {string}
 */
export const toFixed = (num, decimal) => Number(num).toFixed(decimal);

/** Function which prints DOM elemnt content
     * @function
     * @param {string} id - The DOM elemnt id to print
*/
export const printElement = (id, onSuccess) => {
    const element = document.getElementById(id);
    const styleTags = Array.prototype.slice.call(document.getElementsByTagName("style"));
    const linkTags = Array.prototype.slice.call(document.getElementsByTagName("link")).filter(l => l.rel === "stylesheet");
    const elementsToCopy = [...styleTags, ...linkTags];
    let pri;
    const iframe = document.getElementById(id + "-iframe") ? document.getElementById(id + "-iframe") : document.createElement('iframe');
    iframe.setAttribute("id", id + "-iframe");
    iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;');
    iframe.setAttribute("height", "560px")
    iframe.setAttribute("width", "420px")
    document.body.appendChild(iframe);
    pri = iframe.contentWindow;
    pri.document.open();
    pri.document.write("<div class='rt--print-view rt--pt-20 rt--pb-20 rt--pl-16 rt--pr-16'>" + element.innerHTML + "</div>");
    elementsToCopy.forEach(el => {
        const node = el.cloneNode(true);
        node.setAttribute("media", "print")
        pri.document.body.appendChild(el.cloneNode(true))
    })
    const styleTag = document.createElement("style");
    styleTag.innerHTML = "html, body{min-width: 0!important; width: 100%!important; background-color: #fff!important; will-change: auto!important;transform: none!important; margin: auto}";
    pri.document.body.appendChild(styleTag);
    pri.document.close()
    pri.focus();
    pri.addEventListener('load', function () {
        pri.print();
        onSuccess && onSuccess()
    }, false);
}

/** Function which prints multiple DOM elemnts content
     * @function
     * @param {string} ids - The DOM elemnts ids to print
     * @param {function} callback - Function to call after print
*/
export const multiPrintElement = (ids, callback) => {
	if (!Array.isArray(ids) || !ids.length) { return }

	const styleTags = Array.prototype.slice.call(document.getElementsByTagName("style"));
	const linkTags = Array.prototype.slice.call(document.getElementsByTagName("link")).filter(l => l.rel === "stylesheet");
	const elementsToCopy = [...styleTags, ...linkTags];

    for (let i = 0; i < ids.length; i++) {
		runPrint(ids[i], i)
	}

	function runPrint(id, num) {
		const element = document.getElementById(id);
		if (!element) { return }

        let pri;
		const iframe = document.getElementById(id + "-iframe") || document.getElementById(id + "-iframe-" + num) || document.createElement('iframe')

        iframe.setAttribute("id", id + "-iframe-" + num);
		iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;');
		iframe.setAttribute("height", "560px")
		iframe.setAttribute("width", "420px")
		document.body.appendChild(iframe);
		pri = iframe.contentWindow;
		pri.document.open();
		pri.document.write("<div class='rt--print-view'>" + element.innerHTML + "</div>");

        elementsToCopy.forEach(el => {
			const node = el.cloneNode(true);
			node.setAttribute("media", "print")
			pri.document.body.appendChild(el.cloneNode(true))
		})

		const styleTag = document.createElement("style");
        styleTag.innerHTML = "html, body{min-width: 0!important; width: 100%!important; background-color: #fff!important; will-change: auto!important;transform: none!important; margin: auto}";
		pri.document.body.appendChild(styleTag)
		pri.document.close()
		pri.focus();
		pri.addEventListener('load', function () { pri.print(); typeof callback === "function" && callback(id, num) }, false);
	}
}

/** Get bet state text
     * @function
     * @param {number} state - state
     * @returns {string}
*/
export const getBetStateText = state => {

    switch (state) {
        case BET_STATE.PENDING:
            return i18n.t('common.pending');
        case BET_STATE.RETURN:
            return i18n.t('common.return');
        case BET_STATE.WON:
            return i18n.t('common.won');
        case BET_STATE.SEMIWON:
            return i18n.t('common.semiWon');
        case BET_STATE.LOST:
            return i18n.t('common.lost');
        case BET_STATE.SEMILOST:
            return i18n.t('common.semiLost');
        case BET_STATE.CANCELLED:
            return i18n.t('common.cancelled');
        case BET_STATE.REJECTED_BY_PROVIDER:
            return i18n.t('common.rejected');
        case BET_STATE.CASHED_OUT:
            return i18n.t('common.cashedOut');
        case BET_STATE.PAID_OUT:
            return i18n.t('common.paidOut');
        default:
            return "";
    }
}

/** Copy text to clipboard
     * @function
     * @param {string} text - text to copy
     * @returns {string}
*/

export const copyToClipboard = text => {
    const input = document.createElement('textarea');
    input.innerHTML = text;
    document.body.appendChild(input);
    input.select();
    const result = document.execCommand('copy');
    document.body.removeChild(input);
    showSuccess(i18n.t('common.copied'));
    return result;
}


const isDotAtTheEnd = (str) => {
    const firstFoundDotIndex = str.indexOf(".");

    if(firstFoundDotIndex === 0) {
        return false;
    }

    return firstFoundDotIndex === str.length - 1;
}

export const hasDotAndDotIsAtTheEnd = (str) => {
    const hasDot = str.includes(".");

    if(!hasDot) {
        return false;
    }

    return isDotAtTheEnd(str)
}

export const getPropFromMatchObj = ({ value, options, prop }) => {
    const foundOptionObj = options.find(optionObj => optionObj.value === value);

    return foundOptionObj ? foundOptionObj[prop] : value
}

export const constructArrayForGivenRanges = ({ start, end }) => {
    if (!isNumber(start) || !isNumber(end)) {
        console.error('Invalid range format. Each range object should have start and end properties of type number.');
        return [];
    }

    if (start > end) {
        console.error('Invalid range. The start value should be less than or equal to the end value.');
        return [];
    }

    const constructedArray = [];

    for (let i = start; i <= end; i++) {
        constructedArray.push(i);
    }

    return constructedArray;
}


export const classNames = (...classNames) => {
    let classes = "";

    for (let className of classNames) {
        if (!className) {
            continue;
        }

        if (isString(className) || isNumber(className)) {
            classes = (
                classes !== ""
                    ? `${classes} ${className}`
                    : `${className}`
            )
        }
    }

    return classes;
}

/** Format bytes as human-readable text.
     * @function
     * @param {number} bytes - Number of bytes
     * @returns {string} - Formatted string.
 */
 export const humanFileSize = bytes => {
    const thresh = 1024;
    const dp = 2

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
}

export const getPlayerInfoFromStorage = () => {
    const playerInfo = sessionStorageUtils.get("playerInfo");

    if (!playerInfo) {
        sessionStorageUtils.set("playerInfo", null);

        return null;
    }

    return playerInfo;
}
