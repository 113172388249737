import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { getBetshops, changeBetshop } from 'store/actions/auth/betshops.action';

import { loginUser } from 'utils/auth';
import LocalStorageUtils from "utils/localStorage"

import Paths from 'constants/path.constants';
import { TOKEN_TYPE } from 'constants/auth.constants';

import Loader from 'components/common/loader';

import accountBetshopType from 'types/accountBetshop.type';

import noBetshopImg from "assets/images/noBetshop.png";

/** Select Betshop Page Component */

const SelectBetShopComponent = ({
    getBetshops,
    changeBetshop,
    betshops,
    isLoading,
    isSaving
}) => {

    const { t } = useTranslation();
    const [selectedBetShop, setSelectedBetShop] = useState(false);

    const navigate = useNavigate();
    const { search } = useLocation();

    /** Load available betshops */
    useEffect(() => {
        const token = (new URLSearchParams(search)).get("t");
        
        if (token) {
            getBetshops(token);
        }
    }, [])

    /** Function to call on select betshop success
       * @function
       * @param {Object} data - The data returned from server after select betshop call
       * @memberOf SelectBetShopComponent
   */
    const authenticateCB = data => {
        switch (data.tokenType) {
            case TOKEN_TYPE.NONE:
                loginUser({ ...data, userName: (new URLSearchParams(window.location.search)).get("user") });
                setTimeout(() => {
                    window.location.reload();
                }, 0)
                break;
            case TOKEN_TYPE.PASSWORD_EXPIRED:
                navigate(Paths.SET_PASSWORD + "?t=" + data.token + "&type=forceChange");
                break;
            default:
                break;
        }
    }

    /** Function, fires on continue button click
       * @function
       * @memberOf SelectBetShopComponent
   */
    const handleContinue = () => {
        const token = (new URLSearchParams(search)).get("t");
        const userName = (new URLSearchParams(search)).get("user");

        const storageData = LocalStorageUtils.get('betshopData') || {};
        storageData[userName] = selectedBetShop
        LocalStorageUtils.set('betshopData', storageData);
        changeBetshop(token, selectedBetShop, authenticateCB);
    }


    return (
        <div className="rt--auth-select-betshop rt--flex rt--flex-col">
            <div className="rt--auth-select-betshop-content rt--flex-equal rt--flex rt--flex-col rt--justify-center">
                <div className='rt--mb-32'>
                    <b className="rt--title rt--text-left rt--font-bigest rt--font-medium rt--mb-8 rt--mt-8">{t('authentication.selectBetShop')}</b>
                    <span className="rt--auth-select-betshop-content-info rt--text-left">{t('authentication.selectBetShopInfo')}</span>
                </div>
                <div className="rt--select-betshop rt--flex rt--flex-wrap">
                    {
                        (isLoading || isSaving ) ? <Loader /> : betshops.length > 0 ? betshops.map(shop => (
                            <div
                                className='rt--select-betshop-item rt--cursor-pointer rt--pl-16 rt--pr-16 rt--flex rt--align-center rt--justify-between rt--mr-16 rt--mb-16'
                                onClick={() => setSelectedBetShop(shop.id)}
                                key={shop.id}
                            >
                                <div className='rt--flex rt--flex-col'>
                                    <span className='rt--title rt--font-normal rt--font-regular rt--mb-4'>{shop.name}</span>
                                    <span className='rt--title rt--font-small rt--font-regular'>{shop.address}</span>
                                </div>
                                {
                                    selectedBetShop === shop.id && (
                                        <div className='rt--ml-8 rt--select-betshop-item-circle'>
                                            <i className='icon-checked-circle rt--font-bigest'></i>
                                        </div>
                                    )
                                }

                            </div>
                        )) : (
                            <div className='rt--select-betshop-empty rt--flex rt--flex-col rt--align-center rt--justify-center rt--pt-24 rt--pb-24'>
                                <img alt={t("common.noBetshopsFound")} src={noBetshopImg} />
                                <span className='rt--title rt--pt-8 rt--text-center rt--font-medium rt--font-big'>{t("common.noBetshopsFound")}</span>
                            </div>
                        )
                    }
                </div>
            </div>
            <div className="rt--text-right rt--mb-16 rt--mt-16">
                <button
                    disabled={isSaving}
                    className={`${!selectedBetShop ? 'rt--button-disabled' : ''} rt--button rt--button-complimentary rt--button-with-icon`}
                    onClick={handleContinue}
                >
                    <span className='rt--title rt--font-medium rt--font-normal'>{t('authentication.continue')}</span>
                    <i className='icon-right rt--font-bigest' />
                </button>
            </div>
        </div>

    )
}

/** SelectBetShopComponent propTypes
    * PropTypes
*/
SelectBetShopComponent.propTypes = {
    /** Redux state property, is true when available betshops are loading */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when changing betshop request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to change betshop */
    changeBetshop: PropTypes.func,
    /** Redux action to get available betshops */
    getBetshops: PropTypes.func,
    /** Redux state property, available betshops for account */
    betshops: PropTypes.arrayOf(accountBetshopType)
}

const mapDispatchToProps = dispatch => (
    {
        changeBetshop: (token, id, onSuccess) => {
            dispatch(changeBetshop(token, id, onSuccess))
        },
        getBetshops: (token) => {
            dispatch(getBetshops(token))
        }
    }
)

const mapStateToProps = state => {
    return {
        betshops: state.betshops.betshops,
        isLoading: state.betshops.isLoading,
        isSaving: state.betshops.isSaving
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    SelectBetShopComponent
);