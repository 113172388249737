import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { Button, Form, Input } from 'antd';

import { authenticate } from 'store/actions/auth/auth.action';

import { loginUser } from 'utils/auth';
import LocalStorageUtils from "utils/localStorage";

import { TOKEN_TYPE } from 'constants/auth.constants';
import { RESPONSE_CODES } from "constants/errorCode.constants";
import Paths from 'constants/path.constants';

import InputPasswordWithoutAutocomplete from './inputPassword';


/** Login Page Component */

const LoginComponent = ({
    authenticate,
    isLoading
}) => {

    const { t } = useTranslation();

    const { executeRecaptcha } = useGoogleReCaptcha();
    const navigate = useNavigate();

    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    /** Function to call on login success
       * @function
       * @param {Object} data - The data returned from server after login call
       * @param {string} username
       * @memberOf LoginComponent
   */
    const onSuccessfulAuthentication = (data, username) => {

        switch (data.tokenType) {
            case TOKEN_TYPE.NONE:
                loginUser(data);
                setTimeout(() => {
                    window.location.reload();
                }, 0)
                break;
            case TOKEN_TYPE.PASSWORD_EXPIRED:
                navigate(Paths.SET_PASSWORD + "?t=" + data.token + "&type=forceChange");
                break;
            case TOKEN_TYPE.SELECT_BETSHOP:
                navigate(Paths.SELECT_BETSHOP + "?t=" + data.token + "&user=" + username);
                break;
            default:
                break;
        }
    }

    const hasNonExistingBetShopError = (response) => (
        response.data &&
        response.data.resultCode === RESPONSE_CODES.NotFound &&
        response.data.resource === 'BetShop'
    );

    const handleLogin = (username, password, token) => (
        authenticate(username, password, token)
            .then(data => onSuccessfulAuthentication(data, username))
            .catch(response => {
                if (hasNonExistingBetShopError(response)) {
                    throw new Error();
                }
            })
    );

    const getRecaptchaToken = async () => {
        return import.meta.env.MODE === "development" ? "34f530b1728d4b28a5d1e39bb92be946" : await executeRecaptcha();
    }

    /** Fires when form submitted
       * @function
       * @memberOf LoginComponent
   */
    const handleFormSubmit = () => {
        validateFields()
            .then(async ({ username, password }) => {
                const token = await getRecaptchaToken();

                handleLogin(username, password, token)
                    .catch(async () => {
                        LocalStorageUtils.remove('betshopData');

                        const token = await getRecaptchaToken();

                        handleLogin(username, password, token);
                    });
            }).catch(() => { })
    }

    return (
        <div className="rt--auth-form">
            <Form
                form={formInstance}
                hideRequiredMark={true}
                layout="vertical"
            >
                <b className="rt--title rt--text-left rt--font-bigest rt--font-medium rt--mb-32 rt--mt-8">{t('authentication.signIn')}</b>
                <Form.Item
                    label={t('authentication.username')}
                    name="username"
                    rules={[
                        { required: true, whitespace: true, message: t('validation.field_required') },
                        { max: 30, message: t('validation.field_invalid') }
                    ]}
                >
                    <Input className='rt--input'
                        placeholder={t('authentication.username')}
                        autoComplete='off'
                    />
                </Form.Item>
                <Form.Item
                    label={t('authentication.password')}
                    name="password"
                    rules={[
                        { required: true, whitespace: true, message: t('validation.field_required') },
                        { max: 24, message: t('validation.field_invalid') },
                        { min: 8, message: t('validation.field_invalid') }
                    ]}
                >
                    <InputPasswordWithoutAutocomplete
                        className='rt--input'
                        placeholder={t('authentication.password')}
                    />
                </Form.Item>
                <div className='rt--mt-32'>
                    <Form.Item>
                        <Button
                            disabled={isLoading}
                            className={"rt--button-complimentary rt--button" + (isLoading ? " rt--button-disabled" : "")}
                            onClick={handleFormSubmit}
                            loading={isLoading}
                            htmlType="submit"
                        >
                            <span className='rt--font-big rt--font-medium'>{t('authentication.signIn')}</span>
                        </Button>
                    </Form.Item>
                </div>
            </Form>
            <div className='rt--copyright rt--pl-20 rt--pr-20 rt--pt-20 rt--pb-20 rt--flex rt--justify-center'>
                <span className='rt--title rt--font-normal rt--font-regular rt--text-center'>{`© ${new Date().getFullYear()} ${t('authentication.copyRightText')}`}</span>
            </div>
        </div>
    )
}

/** LoginComponent propTypes
    * PropTypes
*/
LoginComponent.propTypes = {
    /** Redux state property, is true when authenticating */
    isLoading: PropTypes.bool,
    /** Redux action to authenticate */
    authenticate: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        authenticate: (username, password, captchaToken) => {
            return dispatch(authenticate(username, password, captchaToken))
        }
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.auth.isLoading
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    LoginComponent
);
