import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Outlet } from "react-router-dom";
import { connect } from 'react-redux';

import { initTranslations } from 'translations/config';

import LanguagesDropdown from 'components/auth/languagesDropdown';
import Loader from 'components/common/loader';
import Sidebar from 'components/auth/sideBar';

import { getSystemLanguages } from 'store/actions/auth/language.action';

import LanguageUtils from 'utils/languages';
import { setUrlParam } from 'utils/common';

/** Auth Layout Component */
const Layout = ({
    languagesLoaded,
    getSystemLanguages,
    translationsLoaded,
    languages
}) => {

    /** Get system languages */
    useEffect(() => {
        getSystemLanguages();
    }, [])

    /** Set default language */
    useEffect(() => {
        if (languagesLoaded) {
            let language = LanguageUtils.getSelectedLanguage()

            if (!Object.keys(languages).map(l => l.toLowerCase()).includes(language.toLowerCase())) {
                language = LanguageUtils.defaultLang;
            }

            initTranslations( null, language );
            setUrlParam('language', language)
        }
    }, [languagesLoaded])


    return translationsLoaded ? (
        <div className="rt--auth rt--flex">
            <Sidebar />
            <div className="rt--auth-content rt--flex-equal rt--flex rt--align-center rt--justify-center">
                <LanguagesDropdown onChange={language => initTranslations( null, language )} />
                <Outlet />
            </div>
        </div>
    ) : <Loader full={true} />
}

/** Layout propTypes
    * PropTypes
*/
Layout.propTypes = {
    /** Redux state property , is true when languages are loaded*/
    languagesLoaded: PropTypes.bool,
    /** Redux action to get system languages */
    getSystemLanguages: PropTypes.func,
    /** Redux state property , is true when translations are loaded*/
    translationsLoaded: PropTypes.bool,
    /** Redux state property, system languages */
    languages: PropTypes.object
}

const mapStateToProps = state => {
    return {
        translationsLoaded: state.auth.translationsLoaded,
        languages: state.auth.languages,
        languagesLoaded: state.auth.languagesLoaded,
    }
}

const mapDispatchToProps = dispatch => (
    {
        getSystemLanguages: () => {
            dispatch(getSystemLanguages());
        }
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(Layout);