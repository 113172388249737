export const BET_STATE = {
    PENDING: 1,
    RETURN: 2,
    WON: 4,
    SEMIWON: 8,
    SEMILOST: 16,
    LOST: 32,
    CANCELLED: 64,
    REJECTED_BY_PROVIDER: 128,
    CASHED_OUT: "cashedout",
    PAID_OUT: "*",
}

export const BET_TYPE = {
    SINGLE: 1,
    MULTI: 2,
    SYSTEM: 3
}
