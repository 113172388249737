import {
    SET_BETSHOPS_ACTION_BEFORE,
    SET_BETSHOPS_ACTION_FINISH,
    SET_BETSHOPS,
    SET_BETSHOP,
    SET_BETSHOP_ACTION_BEFORE,
    SET_BETSHOP_ACTION_FINISH
} from "store/actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_BETSHOPS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_BETSHOPS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_BETSHOPS:
            return {
                ...state,
                betshops: payload.betshops
            };
        case SET_BETSHOP_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_BETSHOP_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_BETSHOP:
            return {
                ...state,
                betshopId: payload.betshopId
            }

        default:
            return state;
    }
};
