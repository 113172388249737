import React from 'react';

import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import Paths from 'constants/path.constants';

// Layouts

import RootLayout from 'layouts/root.layout';
import AuthLayout from 'layouts/auth.layout';
import CashierLayout from 'layouts/cashier.layout';
import ManagerLayout from 'layouts/manager.layout';
import EmptyLayout from 'layouts/empty.layout';

// Routes
import LoginRoute from 'routes/auth/login.route';
import SetPasswordRoute from 'routes/auth/setPassword.route';
import SelectBetShopRoute from 'routes/auth/selectBetShop.route';
import HandoverRoute from 'routes/auth/handover.route';
import CashierRoute from 'routes/cashier/cashier.route';
import ManagerBetshopsRoute from 'routes/manager/managerBetshops.route';
import ManagerReportsRoute from 'routes/manager/managerReports.route';
import ManagerCashiersRoute from 'routes/manager/managerCashiers.route';
import ManagerSessionsRoute from 'routes/manager/managerSessions.route';
import ManagerBetshopTransactionsRoute from 'routes/manager/managerBetshopTransactions.route';
import ManagerCashierTransactionsRoute from 'routes/manager/managerCashierTransactions.route';
import ManagerBetshopPerformanceReportRoute from 'routes/manager/managerBetshopPerformanceReport.route';
import ManagerCashierPerformanceReportRoute from 'routes/manager/managerCashierPerformanceReport.route';
import ManagerBetHistoryRoute from 'routes/manager/managerBetHistory.route';
import MirrorRoute from 'routes/mirror/mirror.route';

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path={Paths.DEFAULT} element={<RootLayout />} >
            <Route path={Paths.DEFAULT} element={<AuthLayout />} >
                <Route path="" element={<LoginRoute />} />
            </Route>

            <Route path={Paths.LOGIN} element={<AuthLayout />} >
                <Route path="" element={<LoginRoute />} />
            </Route>
            <Route path={Paths.SET_PASSWORD} element={<AuthLayout />} >
                <Route path="" element={<SetPasswordRoute />} />
            </Route>

            <Route path={Paths.SELECT_BETSHOP} element={<AuthLayout />} >
                <Route path="" element={<SelectBetShopRoute />} />
            </Route>

            <Route path={Paths.HANDOVER} element={<AuthLayout />} >
                <Route path="" element={<HandoverRoute />} />
            </Route>

            <Route path={Paths.CASHIER} element={<CashierLayout />} >
                <Route path="" element={<CashierRoute />} />
            </Route>

            <Route path={Paths.MANAGER} element={<ManagerLayout />} >
                <Route path="" element={<ManagerBetshopsRoute />} />
                <Route path={Paths.MANAGER_CASHIERS + "/:id"} element={<ManagerCashiersRoute />} />
                <Route path={Paths.MANAGER_SESSIONS} element={<ManagerSessionsRoute />} />
                <Route path={Paths.MANAGER_CASHIER_TRANSACTIONS} element={<ManagerCashierTransactionsRoute />} />
                <Route path={Paths.MANAGER_BETSHOP_TRANSACTIONS} element={<ManagerBetshopTransactionsRoute />} />
                <Route path={Paths.MANAGER_BETSHOP_PERFORMANCE_REPORT} element={<ManagerBetshopPerformanceReportRoute />} />
                <Route path={Paths.MANAGER_CASHIER_PERFORMANCE_REPORT + "/:id"} element={<ManagerCashierPerformanceReportRoute />} />
                <Route path={Paths.MANAGER_REPORTS} element={<ManagerReportsRoute />} />
                <Route path={Paths.MANAGER_BET_HISTORY} element={<ManagerBetHistoryRoute />} />
            </Route>

            <Route path={Paths.MIRROR} element={<EmptyLayout />} >
                <Route path="" element={<MirrorRoute />} />
            </Route>
        </Route>
    )
)

export default router;