import { useEffect, useState } from "react";

import localStorageUtils from 'utils/localStorage';

const LOCAL_STORAGE_KEY = "selectedColumns";

const useIncludedColumns = ({ tableName, defaultIncludedColumns = [] }) => {

    const selectedColumnsFromStorage = localStorageUtils.get(LOCAL_STORAGE_KEY) ?? {}; // If the columns are not already set

    const [includedColumns, setIncludedColumns] = useState(selectedColumnsFromStorage[tableName] ?? defaultIncludedColumns);

    // Keep included columns in local storage
    useEffect(() => {
        selectedColumnsFromStorage[tableName] = includedColumns;

        localStorageUtils.set(LOCAL_STORAGE_KEY, selectedColumnsFromStorage);
    }, [includedColumns]);

    const keepAppliedColumns = (includedColumns) => {
        setIncludedColumns(includedColumns);
    }

    return [
        includedColumns,
        keepAppliedColumns,
    ]
}
 
export default useIncludedColumns;