import {
    SET_CASHIERS_ACTION_BEFORE,
    SET_CASHIERS_ACTION_FINISH,
    SET_CASHIERS_SAVE_ACTION_BEFORE,
    SET_CASHIERS_SAVE_ACTION_FINISH,
    SET_CASHIERS,
    SET_CASHIERS_SORTING,
    SET_CASHIERS_FILTERS,
    SET_AVAILABLE_CASHIERS_ACTION_BEFORE,
    SET_AVAILABLE_CASHIERS_ACTION_FINISH,
    SET_AVAILABLE_CASHIERS
} from "store/actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_CASHIERS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_CASHIERS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_CASHIERS:
            return {
                ...state,
                cashiers: payload.cashiers.item2,
                total: payload.cashiers.item1,
            };

        case SET_CASHIERS_FILTERS:
            return {
                ...state,
                filters: payload.filters,
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            };
        case SET_CASHIERS_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        case SET_CASHIERS_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_CASHIERS_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_AVAILABLE_CASHIERS_ACTION_BEFORE:
            return {
                ...state,
                isAvailableLoading: false,
            };
        case SET_AVAILABLE_CASHIERS_ACTION_FINISH:
            return {
                ...state,
                isAvailableLoading: false,
            };
        case SET_AVAILABLE_CASHIERS:
            return {
                ...state,
                availableCashiers: payload.cashiers,
            };
        default:
            return state;
    }
};