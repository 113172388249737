import React, { useState, Fragment, useRef, useEffect } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Upload, Row, Col, message } from 'antd';

const { Dragger } = Upload;

import { humanFileSize } from 'utils/common';

/** Image uploader Component */
const ImageUploaderComponent = ({
    remove = false,
    size,
    disabled,
    onChange
}) => {
    const { t } = useTranslation();

    const [file, setFile] = useState(null);

    const infoText = "JPG, PNG " + t("common.maxSizeOf") + " " + humanFileSize(size);
    const extensions = ["image/png", "image/jpeg", "image/jpg"];

    /** Fires on remove button click
         * @function
         * @param {object} e - event object
         * @memberOf ImageUploaderComponent
     */
    const handleRemove = e => {
        e.stopPropagation();
        setFile(null);
        onChange && onChange(undefined)
    }

    /** Function which fires on upload start
         * @function
         * @param {object} file
         * @memberOf ImageUploaderComponent
     */
    const onBeforeUpload = file => {
        if (extensions && !extensions.includes(file.type)) {
            message.info(t('errors.message.InvalidFile'));
            setFile(null);
            return false;
        } else if (size && size < file.size) {
            message.info(t('errors.message.InvalidFileSize'));
            setFile(null);
            return false;
        }
    }

    /** Function which fires during upload
         * @function
         * @param {object} info
         * @memberOf BannerUploadComponent
     */
    const onFileChange = info => {
        const f = {};
        f.name = info?.file?.name ?? "";
        f.fileObject = info?.file?.originFileObj ?? {};
        f.url = window.URL.createObjectURL(f.fileObject);
        setFile(f);
        onChange && onChange(f.fileObject);
    }

    return (
        <div
            className={"rt--image-uploader" + (file ? " rt--image-uploader-done" : "")}>
            <Dragger
                multiple={false}
                onChange={onFileChange}
                beforeUpload={onBeforeUpload}
                disabled={disabled}
                showUploadList={{
                    showPreviewIcon: false,
                    showDownloadIcon: false,
                    showRemoveIcon: false
                }}
                accept={extensions?.toString()}
                customRequest={({ onSuccess, onError, onProgress, file }) => {
                    return false;
                }}
            >
                {
                    file ?
                        (
                            <div className="rt--image-uploader-preview rt--flex rt--align-center rt--justify-center rt--pl-16 rt--pr-16 rt--pb-16 rt--pt-16">
                                <img src={file.url} alt="banner" />
                                <div className="rt--image-uploader-preview-actions rt--flex rt--align-center rt--justify-center">
                                    {
                                        remove ? (
                                            <div className="rt--image-uploader-preview-actions-button rt--flex rt--align-center rt--justify-center rt--ml-16" onClick={handleRemove}>
                                                <i className="icon-trash rt--font-bigest" />
                                            </div>
                                        ) : (
                                            <div className="rt--image-uploader-preview-actions-button rt--flex rt--align-center rt--justify-center rt--ml-16">
                                                <i className="icon-edit rt--font-bigest" />
                                            </div>
                                        )
                                    }
                                </div>

                            </div>
                        )
                        : (
                            <div className='rt--flex rt--align-center rt--justify-center rt--image-uploader-empty'>
                                <div className='rt--flex rt--flex-col rt--align-center rt--justify-center rt--mr-8'>
                                    <span className="rt--title rt--font-regular rt--font-normal rt--image-uploader-empty-text rt--mb-2">{t('common.upload')}</span>
                                    <span className="rt--title rt--font-regular rt--font-normal rt--image-uploader-empty-sub-text">{infoText}</span>
                                    <span className="rt--title rt--font-bold rt--font-normal rt--image-uploader-empty-hovered">
                                        {t('common.selectOrDragToUpload')}
                                    </span>
                                    <span className="rt--title rt--font-bold rt--font-normal rt--image-uploader-empty-dragging">{t('common.dragHere')}</span>
                                </div>
                                <i className='icon-upload rt--font-bigest' />
                            </div>
                        )
                }
            </Dragger>
        </div>
    )
}

/** ImageUploaderComponent propTypes
    * PropTypes
*/
ImageUploaderComponent.propTypes = {
    /** Function which will fire on remove button click */
    remove: PropTypes.bool,
    /** Max file size allowed for upload */
    size: PropTypes.number,
    /** Should upload be disabled */
    disabled: PropTypes.bool,
    /** OnChange handler */
    onChange: PropTypes.func
}


export default ImageUploaderComponent;
