const PATHS = {
    DEFAULT: "/",
    LOGIN: "/login",
    SET_PASSWORD: "/setPassword",
    SELECT_BETSHOP: "/selectBetShop",
    HANDOVER: "/handover",
    CASHIER: "/cashier",
    MANAGER: "/manager",
    MANAGER_CASHIERS: "/manager/cashiers",
    // MANAGER_BETSHOPS: "/manager/betshops",
    MANAGER_REPORTS: "/manager/reports",
    MANAGER_SESSIONS: "/manager/sessions",
    MANAGER_BET_HISTORY: "/manager/betHistory",
    MANAGER_BETSHOP_TRANSACTIONS: "/manager/betshopTransactions",
    MANAGER_CASHIER_TRANSACTIONS: "/manager/cashierTransactions",
    MANAGER_BETSHOP_PERFORMANCE_REPORT: "/manager/betshopPerformanceReport",
    MANAGER_CASHIER_PERFORMANCE_REPORT: "/manager/cashierPerformanceReport",
    MIRROR:"/mirror"
}

export default PATHS;