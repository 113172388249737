import {
    SET_BET_HISTORY_ACTION_BEFORE,
    SET_BET_HISTORY_ACTION_FINISH,
    SET_BET_HISTORY,
    SET_BET_HISTORY_SORTING,
    SET_BET_HISTORY_FILTERS,
    SET_BET_HISTORY_TOTALS_ACTION_BEFORE,
    SET_BET_HISTORY_TOTALS_ACTION_FINISH,
    SET_BET_HISTORY_TOTALS
} from "store/actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_BET_HISTORY_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_BET_HISTORY_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_BET_HISTORY:
            return {
                ...state,
                bets: payload.bets.item2,
                total: payload.bets.item1,
            };

        case SET_BET_HISTORY_TOTALS_ACTION_BEFORE:
            return {
                ...state,
                totals: {
                    ...state.totals,
                    isLoading: true,
                }
            };
        case SET_BET_HISTORY_TOTALS_ACTION_FINISH:
            return {
                ...state,
                totals: {
                    ...state.totals,
                    isLoading: false,
                }
            };
        case SET_BET_HISTORY_TOTALS:
            return {
                ...state,
                totals: {
                    ...state.totals,
                    totals: payload.totals,
                }
            };

        case SET_BET_HISTORY_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...payload.filters
                },
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            };
        case SET_BET_HISTORY_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        default:
            return state;
    }
};
