import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Modal } from 'antd';

import { POPUP_SIZE } from "constants/popup.constants";

/** Confirmation Popup Component */
const ConfirmationPopup = ({
    handleCloseModal,
    data
}) => {
    const { t } = useTranslation()

    return (
        <Modal
            className="rt--modal rt--modal-confirmation"
            title={(
                <div className='rt--flex rt--justify-end rt--align-center'>
                    <i className='icon-close rt--font-bigest rt--cursor-pointer' onClick={handleCloseModal} />
                </div>
            )}
            visible={true}
            onCancel={() => handleCloseModal()}
            maskClosable={false}
            closable={false}
            onOk={() => {
                data.onOk();
                handleCloseModal()
            }}
            cancelButtonProps={{ className: 'rt--button-secondary' }}
            okButtonProps={{ disabled: data.isSaving }}
            cancelText={t('common.cancel')}
            okText={data.okText}
            getContainer={() => document.getElementById('rt--modal-root')}
            width={POPUP_SIZE.SMALLEST}
            centered
        >
            <div className='rt--modal-confirmation-content rt--flex rt--justify-center rt--flex-col rt--align-center'>
                <b className='rt--title rt--font-biger rt--font-medium rt--pb-16 rt--text-center'>{data.title}</b>
                <span className='rt--title rt--font-normal rt--font-regular rt--text-center'>{data.description}</span>
            </div>
        </Modal>
    )
}

/** ConfirmationPopup propTypes
    * PropTypes
*/
ConfirmationPopup.propTypes = {
    /** Function to close popup */
    handleCloseModal: PropTypes.func,
    /** Confirmation popup data */
    data: PropTypes.shape({
        /** Title */
        title: PropTypes.string,
        /** Description */
        description: PropTypes.string,
        /** Ok button text */
        okText: PropTypes.string,
        /** Ok button handler */
        onOk: PropTypes.func,
        /** Is treu when request is in process */
        isSaving: PropTypes.bool
    })
}

export default ConfirmationPopup;