import LocalStorageUtils from "./localStorage"
import { postMessage, sendBroadcast } from './postMessage'

import { BROADCAST_CHANNEL_NAME } from 'constants/common.constants';

/** Event listener for mirroring
     * @function
     * @param {object} event - The event
 */
const listener = event => {
    if (event && event.data) {
        try {
            const parsed = JSON.parse(event.data);
            if (parsed) {
                if (parsed.action === "screenshot") {
                    sendBroadcast("update", parsed.payload.data)
                }
            }
        } catch (ex) { 
            return;
        }
    }
}

/** Init Mirroring
     * @function
     * @param {DOMElement} source - The source iframe
 */
export const initMirroring = source => {
    let iframeElem = source || document.querySelector('.rt--frame-container iframe');
    if (iframeElem) {
        let channelCount = +(LocalStorageUtils.get(BROADCAST_CHANNEL_NAME) || 0);
        LocalStorageUtils.subscribe(BROADCAST_CHANNEL_NAME, (val) => {
            if (+(val || 0) > 0) {
                postMessage('startWatching', {}, iframeElem.contentWindow)
            } else {
                postMessage('stopWatching', {}, iframeElem.contentWindow)
            }
        })
        if (channelCount > 0) {
            postMessage('startWatching', {}, iframeElem.contentWindow)
        } else {
            postMessage('stopWatching', {}, iframeElem.contentWindow)
        }
    }

    window.removeEventListener("message", listener)
    window.addEventListener("message", listener, false)
}