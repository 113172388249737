import React from 'react';

import MirrorComponent from 'components/mirror';

const MirrorRoute = () => {
    return (
        <MirrorComponent /> 
    )
}

export default MirrorRoute;