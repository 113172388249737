import React from 'react';

import ManagerReportsComponent from 'components/manager/reports';

import withAuth from 'hocs/withAuth';

const ManagerReportsRoute = () => {
    return (
        <ManagerReportsComponent /> 
    )
}

export default withAuth(ManagerReportsRoute);