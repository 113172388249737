import React from 'react';

import PropTypes from 'prop-types';

/** Loader Component */
const Loader = ( { 
    full, 
    style, 
    fixed 
}) => {
    return (
        <div className={"rt--loader rt--flex rt--flex-row rt--align-center rt--justify-center " + (fixed ? " rt--loader-fixed" : "")} style={style ? style : full ? {height: "100vh"} : null} >
            <div className="icon-loader rt--loader-inner "></div>
        </div>
    )
}

/** Loader propTypes
    * PropTypes
*/
Loader.propTypes = {
    /** Should the loader be full height */
    full: PropTypes.bool,
    /** Should the loader be centered in full screen */
    fixed: PropTypes.bool,
    /** Additional styles for loader */
    style: PropTypes.object
}

export default Loader;