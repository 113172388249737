import React from 'react';
import {Select} from "antd";
import {useTranslation} from "react-i18next";

const Games = ({ providers, provider }) => {
    const { t } = useTranslation();

    const games = [
        {
            label: <div className="rt--flex rt--align-center rt--gap-8"><i className="icon-live-stream" />{t("common.liveStream")}</div>,
            value: "",
            hidden: true
        },
        ...(providers?.[provider]?.games || []).map(game => ({ label: game.title, value: game.streamUrl }))
    ];

    const handleGameOpen = (url) => {
        window.open(url,"Stream_"+ Math.random(),`toolbar=no,location=no,status=no,menubar=no,titlebar=no,scrollbars=no,resizable=no,width=${window.screen.availWidth},height=${window.screen.availHeight}`);
    }

    return (
        <div
            className='rt--flex rt--align-center rt--justify-center rt--ml-24 rt--cursor-pointer'
            title={t("common.game")}
        >
            <Select
                value=""
                className='rt--select'
                placeholder={t('common.selectRange')}
                suffixIcon={<i className="icon-down rt--font-bigest"></i>}
                showSearch={false}
                options={games}
                onChange={handleGameOpen}
            />
        </div>
    );
};

export default Games;
