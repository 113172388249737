import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Profile from 'components/common/profile';
import Clock from "./clock";

import { changePopupVisibility } from 'store/actions/dashboard/common.action';

import { POPUP_TYPE } from 'constants/popup.constants';

import { isUserBlocked } from 'utils/auth';

/** Betshop Manager Header Component */
const Header = ({
    changePopupVisibility
}) => {
    const { t } = useTranslation();

    return (
        <div className='rt--betshop-manager-header'>
            <div className='rt--betshop-manager-header-inner rt--flex rt--justify-between rt--align-center rt--pr-16 rt--pl-24'>
                <Clock />
                <div className='rt--flex rt--flex-equal rt--align-center rt--justify-end'>
                    <div 
                        className={
                            'rt--betshop-manager-header-transfer rt--flex rt--align-center rt--justify-center rt--cursor-pointer' +
                            (isUserBlocked() ? " rt--betshop-manager-header-transfer-disabled" : "")
                        }
                        onClick={() => {
                            if(!isUserBlocked()){
                                changePopupVisibility({key: POPUP_TYPE.MONEY_TRANSFER, data: { }})
                            }
                        }}
                        title={t("common.moneyTransfer")}
                    >
                        <i className='icon-arrows rt--font-bigest'></i>
                    </div>
                    
                    <Profile />
                </div>
            </div> 
        </div>
    )
}

/** Header propTypes
    * PropTypes
*/
Header.propTypes = {
    /** Redux action to open popup */
    changePopupVisibility: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        changePopupVisibility : data =>{
            dispatch(changePopupVisibility(data))
        }
    }
)

export default connect(null, mapDispatchToProps)(Header);
