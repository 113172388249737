/** Email Regex */
export const EMAIL_REGEX = /^([a-zA-Z0-9.\-_]+)@[a-zA-Z0-9.\-_]+\.[a-zA-Z]{2,5}$/;

/** Phone Number Regex */
export const TEL_REGEX = /^(?:00|\+)[0-9]{7,16}$/;

/** Username Regex */
export const USERNAME_REGEX = /^[A-Za-z0-9-_|.]{5,30}$/;

/** Number Regex */
export const NUMBER_REGEX = /^[0-9]*$/

export const INTEGER_AND_DECIMAL_NUMBERS_REGEX = /^[0-9]{1,12}(?:\.[0-9]{1,8})?$/