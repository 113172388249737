import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "react-i18next";
import { Collapse } from "antd";

import Table from "components/common/table";
import Tag from "components/common/tag";

import useFormat from "hooks/useFormat";

import noDataImg from "assets/images/noContent.png";

const Totals = ({name, totals, columns, loadFn, isLoading}) => {
    const { t } = useTranslation();
    const { formatAmount, formatNumber } = useFormat();

    const totalsArray = totals ? Object.keys(totals).map(key => ({...totals[key], currencyCode: key})) : [];

    const currenciesCount = totalsArray.length;
    const columnsWithRender =
        columns
            .map(column => {
                if (column.key === 'currencyCode' ) {
                    return ({
                        ...column,
                        render: (value) => value.toUpperCase(),
                    })
                }

                return ({
                    ...column,
                    render: (value, record) => column.isNumeric ? formatNumber(value) : formatAmount(value, record.currencyCode)
                })
            });

    return (
        <Collapse className="rt--totals" expandIcon={({isActive}) => <i className={"icon-down" + (isActive ? " rt--totals-header-icon__active" : "")}/>}>
            <Collapse.Panel
                className="rt--totals-panel"
                key="*"
                header={t('common.totals')}
                extra={currenciesCount ? <Tag>{currenciesCount} {t('common.currencies')}</Tag> : null}
            >
                <div className="rt--totals-table-wrapper">
                    <Table
                        name={name || "totals"}
                        isLoading={isLoading}
                        loadFn={loadFn}
                        data={totalsArray}
                        columns={columnsWithRender}
                        emptyImg={noDataImg}
                        emptyText={t("common.noData")}
                    />
                </div>
            </Collapse.Panel>
        </Collapse>
    );
};

Totals.propTypes = {
    name: PropTypes.string,
    isTotalsLoading: PropTypes.bool,
    loadFn: PropTypes.func.isRequired,
    totals: PropTypes.object,
    columns: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        title: PropTypes.string,
    })).isRequired,
}

export default Totals;
