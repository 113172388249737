import {
    SET_WALLET_ACTION_BEFORE, 
    SET_WALLET_ACTION_FINISH
} from "store/actionTypes";

export default (state = {}, { type }) => {
    switch (type) {
        case SET_WALLET_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_WALLET_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        default:
            return state;
    }
};
