import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Dropdown from 'components/common/dropdown';
import Flag from 'components/common/flag';

import { changePopupVisibility } from 'store/actions/dashboard/common.action';

import LanguageUtils from 'utils/languages';
import { logout, getUser } from 'utils/auth';
import { setUrlParam } from 'utils/common';

import { POPUP_TYPE } from 'constants/popup.constants';
import { USER_ROLE } from 'constants/user.constants';

import userInfoType from 'types/userInfo.type';

const MenuItems = {
    Logout: 'logout',
    ShiftHandover: 'shiftHandover',
    ChangeBetShop: 'changeBetShop',
    Language: 'language'
}

/** Header Profile Component */
const Profile = ({
    userInfo,
    changePopupVisibility,
    languages
}) => {

    const { t } = useTranslation();

    /** Flag to show/hide languages dropdown */
    const [showLanguage, setShowLanguage] = useState(false);

    const userBalance = userInfo?.balance ?? 0;

    /** Language Code */
    const langCode = LanguageUtils.getSelectedLanguage();
    /** Language Name */
    const langName = languages[langCode] || "en";

    /** Function which will fire on profile dropdown menu item click
       * @function
       * @param { string } value - clicked menu item value
       * @memberOf Profile
   */
    const handleClick = value => {
        switch (value) {
            case MenuItems.Logout:
                logout();
                break;
            case MenuItems.ShiftHandover:
                if(userBalance > 0){
                    changePopupVisibility({ key: POPUP_TYPE.SHIFT_HANDOVER })
                }
                break;
            case MenuItems.ChangeBetShop:
                changePopupVisibility({ key: POPUP_TYPE.CHANGE_BETSHOP })
                break;
            case MenuItems.Language:
                setShowLanguage(true)
                break;
            default:
                onLanguageChange(value)
                break;

        }
    }

    /** Dropdown menu items */
    const menuItems = !showLanguage ? [
        ...(getUser()?.role === USER_ROLE.CASHIER ? [
            {
                key: MenuItems.ChangeBetShop,
                label: t(`common.${MenuItems.ChangeBetShop}`),
            }
        ]: []),
        ...(getUser()?.role !== USER_ROLE.MANAGER ? [
            {
                key: MenuItems.ShiftHandover,
                label: t(`common.${MenuItems.ShiftHandover}`),
                disabled: userBalance === 0
            }
        ]: []),
        {
            key: MenuItems.Language,
            label: <span>{`${t(`common.${MenuItems.Language}`)}:`} <b className='rt--font-medium'>{t(`common.${langName.toLocaleLowerCase()}`)}</b></span>,
            hasSub: true
        },
        {
            key: MenuItems.Logout,
            label: t(`common.${MenuItems.Logout}`),
        }
    ] : Object.keys(languages).map(ln => {
        const langName = languages[ln].toLocaleLowerCase();
        return {
            key: ln,
            label: (
                <div className='rt--flex rt--align-center'>
                    <Flag code={ln} />
                    <span className="rt--title rt--font-big rt--font-regular rt--pl-8">{t(`common.${langName}`, languages[ln])}</span>
                </div>)
        }
    });

    /** Function which will fire on languages dropdown menu item click
       * @function
       * @param { string } value - clicked item value
       * @memberOf Profile
   */
    const onLanguageChange = value => {
        setUrlParam('language', value)
        LanguageUtils.setSelectedLanguageToStorage(value);
        window.location.reload()
    }

    return (
        <Dropdown
            header={
                !showLanguage ? (
                    <div className='rt--flex rt--align-center rt--cashier-header-profile-dropdown rt--pl-8 rt-pr-8 rt--pb-8 rt--pt-8'>
                        <div className='rt--cashier-header-profile-dropdown-avatar rt--flex rt--align-center rt--justify-center'>
                            <i className='icon-user rt--font-bigest' />
                        </div>
                        <div className='rt--flex-equal rt--ml-8'>
                            <b className='rt--title rt--font-big rt--font-regular rt--pb-4'>{`${userInfo?.cashier?.userName ?? ""}`}</b>
                            <span className='rt--title rt--font-normal rt--font-regular'>{userInfo?.betShopName ?? ""}</span>
                        </div>
                    </div>
                ) : (
                    <div className='rt--flex rt--align-center rt--cursor-pointer rt--cashier-header-profile-dropdown-back' onClick={() => setShowLanguage(false)}>
                        <i className='icon-left rt--font-bigest' />
                        <span className='rt--title rt--font-normal rt--font-regular rt--pl-4 rt--font-capitalize'>{t("common.languages")}</span>
                    </div>
                )
            }
            element={
                <div className='rt--flex rt--align-center rt--cashier-header-profile rt--cursor-pointer rt--ml-24'>
                    <div className='rt--cashier-header-profile-avatar rt--flex rt--align-center rt--justify-center'>
                        <i className='icon-user rt--font-bigest' />
                    </div>
                    <i className='icon-down rt--font-bigest' />
                </div>
            }
            items={menuItems}
            onClick={handleClick}
            onClose={() => setShowLanguage(false)}
        />

    )
}

/** Profile propTypes
    * PropTypes
*/
Profile.propTypes = {
    /** Redux state property, current user info */
    userInfo: userInfoType,
    /** Redux action to open popup */
    changePopupVisibility: PropTypes.func,
    /** Redux state property, system languages */
    languages: PropTypes.object
}

const mapStateToProps = state => {
    return {
        userInfo: state.profile.userInfo,
        languages: state.auth.languages
    }
}
const mapDispatchToProps = dispatch => (
    {
        changePopupVisibility: (data) => {
            dispatch(changePopupVisibility(data))
        }
    }
)
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Profile
);
