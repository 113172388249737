export const USER_GENDER = {
    MALE: 1,
    FEMALE: 2,
    NONE: 4
}

export const USER_ROLE = {
    CASHIER: "Cashier",
    MANAGER: "BetShopManager"
}

export const USER_TYPE = {
    ADMIN: 1,
    AGENT: 2,
    CASHIER: 4,
    SUPERAGENT: 8,
    BETSHOP_MANAGER: 16,
    AGENT_PLAYER: 32
}