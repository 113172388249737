import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal } from 'antd';

import Loader from "components/common/loader";

import { getAvailableCashiersForHandover, handover } from 'store/actions/dashboard/handover.action';

import useFormat from 'hooks/useFormat';

import { POPUP_SIZE } from "constants/popup.constants";

import userInfoType from 'types/userInfo.type';
import betshopAvailableCashierType from 'types/betshopAvailableCashier.type';

import noCashierImg from "assets/images/noCashier.png";


/** Shift Handover Popup Component */
const ShiftHandoverPopup = ({
    handleCloseModal,
    getAvailableCashiersForHandover,
    availableCashiersForHandover,
    isLoading,
    handover,
    isSaving,
    userInfo
}) => {
    const { t } = useTranslation()

    const [selected, setSelected] = useState(null);

    const { formatAmount } = useFormat();

    const currency = userInfo?.currencies?.[0] ?? null;

    /** Get available cashiers for handover */
    useEffect(() => {
        getAvailableCashiersForHandover(userInfo.betShopId)
    }, [])

    /** Set default cashier */
    useEffect(() => {
        if (availableCashiersForHandover.length > 0) {
            setSelected(availableCashiersForHandover[0])
        }
    }, [availableCashiersForHandover])

    return (
        <Modal
            className="rt--modal"
            title={(
                <div className='rt--flex rt--justify-between rt--align-center'>
                    <span className='rt--title rt--font-normal rt--font-regular'>{t("common.shiftHandover")}</span>
                    <i className='icon-close rt--font-bigest rt--cursor-pointer' onClick={handleCloseModal} />
                </div>
            )}
            visible={true}
            onCancel={() => handleCloseModal()}
            maskClosable={false}
            closable={false}
            onOk={() => {
                handover(selected, handleCloseModal)
            }}
            cancelButtonProps={{ className: 'rt--button-secondary' }}
            okButtonProps={{ disabled: isSaving || availableCashiersForHandover.length === 0 }}
            cancelText={t('common.cancel')}
            okText={t('common.handover')}
            getContainer={() => document.getElementById('rt--modal-root')}
            width={POPUP_SIZE.NORMAL}
            centered
        >
            <div className='rt--modal-select-cashier rt--modal-handover'>
                {
                    !isLoading ? availableCashiersForHandover.length > 0 ? (
                        <Fragment>
                            <div className='rt--modal-form-item rt--flex rt--flex-col rt--mb-24'>
                                <span className='rt--title rt--font-regular rt--font-big rt--mb-8'>{t("common.balanceToTransfer")}</span>
                                <div className='rt--modal-handover-info rt--flex rt--align-center rt--pl-16 rt--pr-16'>
                                    <i className='icon-coins rt--font-bigest' />
                                    <span className='rt--title rt--pl-4 rt--pr-4 rt--font-normal rt--font-regular'>
                                        {
                                            `${formatAmount(userInfo?.balance ?? 0, currency?.code)} ${currency?.code ?? ""}`
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className='rt--mb-8'>
                                <b className='rt--title rt--font-regular rt--font-big'>{t("common.selectCashier")}</b>
                            </div>
                            <span className='rt--title rt--font-regular rt--font-big rt--modal-desc'>{t("common.selectCashierToHandoverInfo")}</span>
                            <div className='rt--flex rt--flex-wrap'>
                                {
                                    availableCashiersForHandover.map(cashier => (
                                        <div
                                            className={'rt--modal-select-cashier-item rt--mt-16 rt--mr-16 rt--pt-12 rt--pb-12 rt--pl-16 rt--pr-16' + (selected && selected.id === cashier.id ? " rt--modal-select-cashier-item-selected" : "")}
                                            key={cashier.id}
                                            onClick={() => setSelected(cashier)}
                                        >
                                            <div className='rt--flex rt--align-center rt--justify-between'>
                                                <div className='rt--flex rt--flex-equal rt--hide-overflow'>
                                                    <div className='rt--modal-select-cashier-item-img rt--flex rt--justify-center rt--align-center rt--mr-8'>
                                                        <i className='icon-user rt--font-bigest'></i>
                                                    </div>
                                                    <div className='rt--flex rt--flex-col rt--hide-overflow'>
                                                        <span className='rt--title rt--font-normal rt--font-regular rt--mb-2' title={`${cashier.firstName} ${cashier.lastName}`}>{`${cashier.firstName} ${cashier.lastName}`}</span>
                                                        <span className='rt--title rt--font-small rt--font-regular' title={cashier.userName}>{cashier.userName}</span>
                                                    </div>
                                                </div>
                                                {
                                                    selected && selected.id === cashier.id && (
                                                        <div className='rt--ml-8 rt--modal-select-cashier-item-circle'>
                                                            <i className='icon-checked-circle rt--font-bigest'></i>
                                                        </div>
                                                    )
                                                }

                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </Fragment>
                    ) : (
                        <div className='rt--modal-select-cashier-empty rt--flex rt--flex-col rt--align-center rt--justify-center rt--pt-24 rt--pb-24'>
                            <img alt={t("common.noCashiersFound")} src={noCashierImg} />
                            <span className='rt--title rt--pt-8 rt--text-center rt--font-medium rt--font-big'>{t("common.noCashiersFound")}</span>
                        </div>
                    ) : <Loader />
                }


            </div>
        </Modal>
    )
}

/** ShiftHandoverPopup propTypes
    * PropTypes
*/
ShiftHandoverPopup.propTypes = {
    /** Function to close popup */
    handleCloseModal: PropTypes.func,
    /** Redux state property, current user info */
    userInfo: userInfoType,
    /** Redux state property, is true when loading available cashiers for handover  */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when lhandover request is in process  */
    isSaving: PropTypes.bool,
    /** Redux action to handover */
    handover: PropTypes.func,
    /** Redux action to get available cashiers for handover */
    getAvailableCashiersForHandover: PropTypes.func,
    /** Redux state property , available cashiers for handover */
    availableCashiersForHandover: PropTypes.arrayOf(betshopAvailableCashierType)
}

const mapStateToProps = state => {
    return {
        availableCashiersForHandover: state.handover.availableCashiersForHandover,
        isLoading: state.handover.isLoading,
        isSaving: state.handover.isSaving,
        userInfo: state.profile.userInfo
    }
}

const mapDispatchToProps = dispatch => (
    {
        getAvailableCashiersForHandover: id => {
            dispatch(getAvailableCashiersForHandover(id))
        },

        handover: (cashier, onSuccess) => {
            dispatch(handover(cashier, onSuccess))
        }
    }
)


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ShiftHandoverPopup
);