import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment/moment';

import { Modal, Form, Input, Select, DatePicker } from 'antd';

import Loader from 'components/common/loader';
import InputPasswordWithoutAutocomplete from 'components/auth/login/inputPassword';
import ImageUploader from 'components/common/imageUploader';

import { getPlayerAvailableCurrencies, getPlayerRegistrationForm, createPlayer } from 'store/actions/dashboard/players.action';

import { POPUP_SIZE } from "constants/popup.constants";
import { REGISTRATION_FORM_CONTROL_RULE, REGISTRATION_FORM_CONTROL_TYPE, REGISTRATION_FORM_PREVALIDATION_RULE, PLAYER_DOCUMENT_TYPE } from 'constants/registrationForm.constants';
import { USER_GENDER } from "constants/user.constants";
import { ENVIRONMENT_TYPE, DATE_FORMAT } from 'constants/common.constants'

import { toLowerCaseFirstLetter } from "utils/common";
import { getUser } from "utils/auth";

import countries from "systemData/countries";

import registrationFormType from 'types/registrationForm.type';
import autoSuggestionType from 'types/autoSuggestion.type';

const addConfirmPassword = (registrationFormFields) => {
    return registrationFormFields.reduce((acc, filedRules) => {
        if (filedRules.name === "Password") {
            const confirmPasswordRules = {
                ...filedRules,
                name: "ConfirmPassword",
            }

            return [
                ...acc,
                filedRules,
                confirmPasswordRules,
            ]
        }

        return [...acc, filedRules];
    }, [])
}

/** Register Player Popup Component */
const RegisterPlayerComponent = ({
    isLoading,
    isSaving,
    registrationForm,
    getPlayerRegistrationForm,
    getPlayerAvailableCurrencies,
    availableCurrencies,
    createPlayer,
    handleCloseModal,
}) => {

    const { t } = useTranslation();

    const [formInstance] = Form.useForm();
    const { validateFields, getFieldValue } = formInstance;

    /** Form fields order */

    const fieldsOrder = ["UserName", "Email", "PhoneNumber", "Password", "ConfirmPassword", "CurrencyCode", "FirstName", "MiddleName", "LastName", "BirthDate", "Gender", "Country", "City", "Address", "ZipCode", "DocumentType", "DocumentNumber", "Attachment"]

    /** Load player registration form */
    useEffect(() => {
        getPlayerRegistrationForm()
    }, [])

    /** Load player available currencies */
    useEffect(() => {
        if (registrationForm.find(control => control.name === 'CurrencyCode')) {
            getPlayerAvailableCurrencies()
        }
    }, [registrationForm])


    /** Function, to get validation rules for control
         * @function
         * @param {object} control
         * @returns {array} rules
         * @memberOf RegisterPlayerComponent
    */
    const getRulesForField = control => {
        const rules = [];

        if (control.selectedRule === REGISTRATION_FORM_CONTROL_RULE.REQUIRED) {
            rules.push({ required: true, message: t('validation.field_required') })
        }

        /* Validate Confirm Password */
        if (control.name === "ConfirmPassword") {
            rules.push(
                ({ getFieldValue }) => ({
                    validator(rule, value) {
                        if (control.selectedRule !== REGISTRATION_FORM_CONTROL_RULE.REQUIRED) {
                            if (!getFieldValue("password")) {
                                return Promise.resolve()
                            }
                        }

                        if (value !== getFieldValue("password")) {
                            return Promise.reject(t('validation.passwords_do_not_match'))
                        }
                        return Promise.resolve();
                    }
                })
            )
        }

        /* Validate Regex */
        if (control.preValidationRule === REGISTRATION_FORM_PREVALIDATION_RULE.REGEXP) {
            rules.push({ pattern: control.regExp, message: t('validation.field_invalid') });
        }

        return rules;
    }

    /** Function, to get select controls data
         * @function
         * @param {object} control
         * @returns {object} data
         * @memberOf RegisterPlayerComponent
    */
    const getSelectData = control => {
        const data = {
            "CurrencyCode": {
                items: (availableCurrencies.map(cur => ({
                    value: cur.key.toUpperCase(),
                    name: cur.value
                }))),
                showSearch: false
            },
            "Country": {
                items: countries.map(c => ({
                    value: c.iso1,
                    name: c.name
                })),
                showSearch: true,
            },
            "Gender": {
                items: [
                    { value: USER_GENDER.MALE, name: t("common.male") },
                    { value: USER_GENDER.FEMALE, name: t("common.female") },
                    { value: USER_GENDER.NONE, name: t("common.other") }
                ],
                showSearch: false,
            },
            "DocumentType": {
                items: [
                    { value: PLAYER_DOCUMENT_TYPE.PASSPORT, name: t("common.passport") },
                    { value: PLAYER_DOCUMENT_TYPE.ID, name: t("common.passportID") },
                    { value: PLAYER_DOCUMENT_TYPE.DRIVING_LICENCES, name: t("common.drivingLicences") }
                ],
                showSearch: false,
            },
        };
        return data[control.name];
    }

    /** Function, to make control label
         * @function
         * @param {object} control
         * @returns {string} data
         * @memberOf RegisterPlayerComponent
    */
    const makeLabel = control => {
        switch (control.name) {
            case "UserName":
                return t("common.username");
            case "CurrencyCode":
                return t("common.currency");
            default:
                return t('common.' + toLowerCaseFirstLetter(control.name));
        }
    }

    /** Sort controls */
    registrationForm.sort((c1, c2) => fieldsOrder.indexOf(c1.name) < fieldsOrder.indexOf(c2.name) ? -1 : 1)


    /** Function, to call on form submit
         * @function
         * @memberOf RegisterPlayerComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                const d = { ...data };
                if (data.birthDate) {
                    d.birthDate = moment(data.birthDate).format(DATE_FORMAT)
                }
                createPlayer(d, handleCloseModal);
            }).catch(() => {
                console.log("catch")
            })
    }

    const formRules = addConfirmPassword(registrationForm)

    return (
        <Modal
            className='rt--modal rt--modal-register-player-modal'
            title={(
                <div className='rt--flex rt--justify-between rt--align-center'>
                    <span className='rt--title rt--font-normal rt--font-regular'>{t("common.registerPlayer")}</span>
                    <i className='icon-close rt--font-bigest rt--cursor-pointer' onClick={handleCloseModal} />
                </div>
            )}
            visible={true}
            onCancel={handleCloseModal}
            closable={false}
            maskClosable={false}
            getContainer={() => document.getElementById('rt--modal-root')}
            cancelButtonProps={{ className: 'rt--button-secondary' }}
            okButtonProps={{ disabled: isSaving, loading: isSaving }}
            onOk={handleForm}
            cancelText={t('common.cancel')}
            okText={t('common.register')}
            width={getUser()?.environmentType === ENVIRONMENT_TYPE.AGENT_SYSTEM ? POPUP_SIZE.NORMAL : POPUP_SIZE.BIG}
            centered
        >
            <div className='rt--modal-register-player rt--flex rt--flex-col'>
                {
                    isLoading ? <Loader /> : (
                        <Form
                            className="rt--form"
                            form={formInstance}
                            colon={false}
                            hideRequiredMark={true}
                            layout="vertical"
                        >
                            <div className='rt--modal-register-player-form rt--flex rt--align-center'>
                                {
                                    formRules
                                        .filter(control => (
                                            control.selectedRule !== REGISTRATION_FORM_CONTROL_RULE.HIDDEN &&
                                            control.name !== "ParentId"
                                        ))
                                        .map(control => (
                                            <div className='rt--modal-register-player-form-item'
                                                key={control.name}
                                                data-env={getUser()?.environmentType}
                                                data-type={control.controlType}
                                            >
                                                <Form.Item
                                                    className='rt--modal-form-item rt--flex rt--flex-col'
                                                    label={
                                                        (
                                                            <span className='rt--title rt--font-regular rt--font-normal'>
                                                                {
                                                                    `${makeLabel(control)} ${control.selectedRule === REGISTRATION_FORM_CONTROL_RULE.REQUIRED ? '*' : ''}`
                                                                }
                                                            </span>
                                                        )
                                                    }
                                                    name={toLowerCaseFirstLetter(control.name)}
                                                    rules={getRulesForField(control)}
                                                    validateFirst
                                                >
                                                    {
                                                        control.controlType === REGISTRATION_FORM_CONTROL_TYPE.INPUT ?
                                                            (
                                                                <Input
                                                                    placeholder={`${t('common.enter')} ${makeLabel(control)}`}
                                                                    className='rt--input'
                                                                />
                                                            ) : control.controlType === REGISTRATION_FORM_CONTROL_TYPE.PASSWORD ? (
                                                                <InputPasswordWithoutAutocomplete
                                                                    className='rt--input'
                                                                    placeholder={`${t('common.enter')} ${makeLabel(control)}`}
                                                                    onPaste={control.name === "ConfirmPassword" ? e => e.preventDefault() : undefined}
                                                                    onContextMenu={control.name === "ConfirmPassword" ? e => e.preventDefault() : undefined}
                                                                    onCopy={control.name === "ConfirmPassword" ? e => e.preventDefault() : undefined}
                                                                    onChange={control.name === "Password" ? () => {
                                                                        setTimeout(() => {
                                                                            if (getFieldValue('confirmPassword') !== "")
                                                                                validateFields(['confirmPassword'])
                                                                        }, 0)
                                                                    } : undefined}
                                                                />

                                                            ) : control.controlType === REGISTRATION_FORM_CONTROL_TYPE.SELECT ? (
                                                                <Select
                                                                    placeholder={`${t('common.select')} ${makeLabel(control)}`}
                                                                    showSearch={getSelectData(control).showSearch}
                                                                    optionFilterProp="children"
                                                                    suffixIcon={<i className="icon-down rt--font-bigest"></i>}
                                                                    getPopupContainer={trigger => trigger.parentNode}
                                                                    className="rt--select"
                                                                >
                                                                    {
                                                                        getSelectData(control).items.map(it => (
                                                                            <Select.Option key={it.value} value={it.value}>{it.name}</Select.Option>
                                                                        ))
                                                                    }
                                                                </Select>
                                                            ) : control.controlType === REGISTRATION_FORM_CONTROL_TYPE.DATEPICKER ? (
                                                                <DatePicker
                                                                    placeholder={`${t('common.select')} ${makeLabel(control)}`}
                                                                    format={DATE_FORMAT}
                                                                    showTime={false}
                                                                    className="rt--datepicker"
                                                                    suffixIcon={<i className='icon-calendar rt--font-bigest' />}
                                                                    allowClear={false}
                                                                    showToday={false}
                                                                    disabledDate={(current) => {
                                                                        return (
                                                                            moment().add(-18, "year") <= current || moment().add(-100, "year") >= current ||
                                                                            moment().add("day") <= current
                                                                        );
                                                                    }}
                                                                />
                                                            ) : control.controlType === REGISTRATION_FORM_CONTROL_TYPE.FILE_UPLOAD ? (
                                                                <ImageUploader
                                                                    size={1024 * 1024 * 3}
                                                                    remove={control.selectedRule !== REGISTRATION_FORM_CONTROL_RULE.REQUIRED}
                                                                />
                                                            ) : null
                                                    }

                                                </Form.Item>
                                            </div>
                                        ))
                                }
                            </div>
                        </Form>
                    )
                }
            </div>
        </Modal>
    )
}

/** RegisterPlayerComponent propTypes
    * PropTypes
*/
RegisterPlayerComponent.propTypes = {
    /** Function to close popup */
    handleCloseModal: PropTypes.func,
    /** Redux state property is true when player registration form is loading */
    isLoading: PropTypes.bool,
    /** Redux state property is true when player creating request is in process */
    isSaving: PropTypes.bool,
    /** Redux state property, player registration form */
    registrationForm: PropTypes.arrayOf(registrationFormType),
    /** Redux state property, available currencies */
    availableCurrencies: PropTypes.arrayOf(autoSuggestionType),
    /** Redux action to get player registration form */
    getPlayerRegistrationForm: PropTypes.func,
    /** Redux action to get player available currencies */
    getPlayerAvailableCurrencies: PropTypes.func,
    /** Redux action to get register player */
    createPlayer: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getPlayerRegistrationForm: () => {
            dispatch(getPlayerRegistrationForm());
        },

        getPlayerAvailableCurrencies: () => {
            dispatch(getPlayerAvailableCurrencies());
        },

        createPlayer: (data, onSuccess) => {
            dispatch(createPlayer(data, onSuccess));
        }
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.players.isLoading,
        registrationForm: state.players.registrationForm,
        availableCurrencies: state.players.availableCurrencies,
        isSaving: state.players.isSaving
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    RegisterPlayerComponent
);
