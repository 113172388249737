import {
    SET_TICKET_INFO, 
    SET_TICKET_INFO_ACTION_BEFORE,
    SET_TICKET_INFO_ACTION_FINISH,
    SET_PAYOUT_ACTION,
    SET_PAYOUT_ACTION_BEFORE,
    SET_PAYOUT_ACTION_FINISH,
    RESET_PAYOUT_ACTION
} from "store/actionTypes";


export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_TICKET_INFO_ACTION_BEFORE:
            return {
                ...state,
                isTicketInfoLoading: true,
            };
        case SET_TICKET_INFO_ACTION_FINISH:
            return {
                ...state,
                isTicketInfoLoading: false,
            };
        case SET_TICKET_INFO:
            return {
                ...state,
                ticketInfo: payload.data
            };
        case SET_PAYOUT_ACTION_BEFORE:
            return {
                ...state,
                isPayoutLoading: true,
            };
        case SET_PAYOUT_ACTION_FINISH:
            return {
                ...state,
                isPayoutLoading: false,
            };
        case SET_PAYOUT_ACTION:
            return {
                ...state,
                payoutTicket: payload.data,
                ticketInfo: state.ticketInfo.map(ticket => ({
                    ...ticket,
                    paidoutTime: payload.data.some(t => t.id === ticket.id) ? payload.data.find(t => t.id === ticket.id).paidoutTime : ticket.paidoutTime,
                    paidoutBy: payload.data.some(t => t.id === ticket.id) ? payload.userName : ticket.paidoutTime
                }))
            };
        case RESET_PAYOUT_ACTION:
            return {
                ...state,
                payoutTicket: []
            };
        default:
            return state;
    }
};
