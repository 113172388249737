import React, { useState, useEffect } from 'react';

import moment from 'moment/moment';

import { DATE_FORMAT, TIME_FORMAT } from 'constants/common.constants';

let interval;

/** Header Clock component */
const Clock = () => {

    const [date, setDate] = useState(new Date());

    /** Run the clock */
    useEffect(() => {
        interval = setInterval(() => { setDate(new Date()) }, 0)
        return () => clearInterval(interval)
    }, [])


    return (
        <div className='rt--flex rt--align-center'>
            <i className='icon-clock rt--font-bigest'></i>
            <span className='rt--title rt--font-biger rt--font-medium rt--ml-4'>{moment.utc(date).local().format(DATE_FORMAT + " " + TIME_FORMAT)}</span>
        </div>
    )

}

export default Clock;
