import PropTypes from 'prop-types';

import { TRANSACTION_TYPE, WALLET_TYPE, WALLET_OWNER_TYPE } from "constants/common.constants";

const transactionItem = PropTypes.shape({
    after: PropTypes.number,
    before: PropTypes.number,
    ownerName: PropTypes.string,
    ownerType: PropTypes.oneOf(Object.values(WALLET_OWNER_TYPE)), 
    walletType: PropTypes.oneOf(Object.values(WALLET_TYPE)), 
})

const transactionType = PropTypes.shape({
    transactionId: PropTypes.number,
    transactionTime: PropTypes.string,
    transactionType: PropTypes.oneOf(Object.values(TRANSACTION_TYPE)),
    transactionItems: PropTypes.arrayOf(transactionItem)
})

export default PropTypes.shape({
    currencyCode: PropTypes.string,
    groupId: PropTypes.number,
    transactionAmount: PropTypes.number,
    transactionTime: PropTypes.string,
    transactionType: PropTypes.oneOf(Object.values(TRANSACTION_TYPE)),
    participants: PropTypes.arrayOf(PropTypes.string),
    transactions: PropTypes.arrayOf(transactionType)
})