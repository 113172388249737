import React, { useState } from 'react';

import { Input } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

import { classNames } from 'utils/common';

const InputPasswordWithoutAutocomplete = ({ className, ...restProps }) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleEyeIconClick = () => {
        setShowPassword(!showPassword)
    }

    return (
        <div className='rt--auth-form-password'>
            <Input
                type={"text"}
                autoComplete="off"
                className={classNames(
                    showPassword ? "rt--input rt--input-password" : 'rt--input-password rt--input rt--auth-form-password-hide',
                    Boolean(className) && className
                )}
                { ...restProps }
            />

            <div className='rt--auth-form-password-showHide' onClick={handleEyeIconClick}>
                {
                    showPassword
                        ? <EyeOutlined />
                        : <EyeInvisibleOutlined />
                }
            </div>
        </div>
    )
}

export default InputPasswordWithoutAutocomplete