import React from 'react';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import LoginComponent from 'components/auth/login';

import withAnanymous from 'hocs/withAnanymous';

import { CAPTCHA_KEY } from 'constants/common.constants';

const LoginRoute = () => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA_KEY}>
            <LoginComponent /> 
        </GoogleReCaptchaProvider>
    )
}

export default withAnanymous(LoginRoute);