import PropTypes from 'prop-types';

import { USER_STATE } from "constants/common.constants" 

export default PropTypes.shape({
    id: PropTypes.string,
    longId: PropTypes.number,
    name: PropTypes.string,
    code: PropTypes.string,
    address: PropTypes.string,
    currencyId: PropTypes.string,
    balance: PropTypes.number,
    limit: PropTypes.number,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    enabled: PropTypes.bool,
    currentUserId: PropTypes.string,
    timeZoneId: PropTypes.string,
    agentId: PropTypes.string,
    state: PropTypes.oneOf(Object.values(USER_STATE)),
    betShopMaxLimit: PropTypes.number,
    cashiers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        userName: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string
    }))
})