import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useNavigate } from "react-router-dom";

import Table from "components/common/table";
import ColumnsButton from 'components/common/columnsButton';
import Export from "components/common/export";

import {
    getCashierPerformanceReport
} from 'store/actions/dashboard/cashierPerformanceReport.action';

import PATHS from 'constants/path.constants';
import ApiUrls from "constants/api.constants";

import performanceReportType from 'types/performanceReport.type';

import noContentImg from "assets/images/noContent.png";


/** Betshop Manager Cashier Performance Report Page Table Component */

const CashierPerformanceReport = ({
    getCashierPerformanceReport,
    isLoading,
    report,
    columns
}) => {

    const { t } = useTranslation();

    const searchParams = useParams();
    const { search } = useLocation();

    const navigate = useNavigate();

    let entityName = null;
    let periodFrom = null;
    let periodTo = null;
    try{
        const data = JSON.parse(atob((new URLSearchParams(search)).get("data")));
        entityName = data.name;
        periodFrom = data.from;
        periodTo = data.to;
    }catch(ex){
        console.log(ex)
    }

    const loadCashierPerformanceReport = () => {
        getCashierPerformanceReport(searchParams.id, periodFrom, periodTo)
    }

    return (
        <Fragment>
            <div className='rt--filters-header rt--mt-32 rt--pb-16 rt--mb-30'>
                <div className='rt--flex rt--justify-between rt--align-center'>
                    <div className='rt--flex rt--align-center rt--filters-header-title'>
                        <i
                            className='icon-left rt--font-bigest rt--cursor-pointer'
                            onClick={() => navigate(PATHS.MANAGER_BETSHOP_PERFORMANCE_REPORT)}
                        />
                        <span className='rt--title rt--font-big rt--font-regular rt--pl-4'>{entityName}</span>
                    </div>

                    <div className='rt--flex'>
                        <Export
                            filters={{
                                BetShopNameOrId: searchParams.id,
                                from: periodFrom,
                                to: periodTo,
                                filterType: 1
                            }}
                            title={t("common.betshopPerformanceReport")}
                            columns={columns.mainTableColumns}
                            url={ApiUrls.EXPORT_CASHIER_PERFORMANCE_REPORT}
                            tableName={entityName}
                        />

                        <ColumnsButton
                            columns={columns.columnsThatCanBeIncluded}
                            onApply={columns.keepAppliedColumns}
                            defaultSelectedColumns={columns.includedColumns}
                        />
                    </div>
                </div>
            </div>
            <Table
                name="betshopPerformanceReport"
                loadFn={loadCashierPerformanceReport}
                columns={columns.mainTableColumns}
                data={report}
                isLoading={isLoading}
                emptyImg={noContentImg}
                emptyText={t("common.noDataFound")}
                enableReload={true}
                actions={[]}
            />
        </Fragment>

    )
}

/** CashierPerformanceReport propTypes
    * PropTypes
*/
CashierPerformanceReport.propTypes = {
    /** Redux action to get cashier performance report */
    getCashierPerformanceReport: PropTypes.func,
    /** Redux state property, is true when loading cashier performance report */
    isLoading: PropTypes.bool,
    /** Redux state property, the array of betshop performance report */
    report: PropTypes.arrayOf(performanceReportType),
    /** Columns data */
    columns: PropTypes.object
}

const mapDispatchToProps = dispatch => (
    {
        getCashierPerformanceReport: (betShopNameOrId, from, to) => {
            dispatch(getCashierPerformanceReport(betShopNameOrId, from, to));
        }
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.cashierPerformanceReport.isLoading,
        report: state.cashierPerformanceReport.report
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    CashierPerformanceReport
);
