import {
    SET_MANAGER_BETSHOPS_ACTION_BEFORE,
    SET_MANAGER_BETSHOPS_ACTION_FINISH,
    SET_MANAGER_BETSHOPS,
    SET_MANAGER_BETSHOPS_SORTING,
    SET_MANAGER_BETSHOPS_FILTERS,
    SET_MANAGER_BETSHOPS_SAVE_ACTION_BEFORE,
    SET_MANAGER_BETSHOPS_SAVE_ACTION_FINISH,
    SET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_ACTION_BEFORE,
    SET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_ACTION_FINISH,
    SET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_FOR_ADD,
    SET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_FOR_TRANSFER,
    SET_MANAGER_BETSHOP_AVAILABLE_BETSHOPS_ACTION_BEFORE,
    SET_MANAGER_BETSHOP_AVAILABLE_BETSHOPS_ACTION_FINISH,
    SET_MANAGER_BETSHOP_AVAILABLE_BETSHOPS
} from "store/actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_MANAGER_BETSHOPS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_MANAGER_BETSHOPS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_MANAGER_BETSHOPS:
            return {
                ...state,
                betshops: payload.betshops.item2,
                total: payload.betshops.item1,
            };

        case SET_MANAGER_BETSHOPS_FILTERS:
            return {
                ...state,
                filters: payload.filters,
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            };
        case SET_MANAGER_BETSHOPS_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        case SET_MANAGER_BETSHOPS_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_MANAGER_BETSHOPS_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_ACTION_BEFORE:
            return {
                ...state,
                isAvailableCashiersLoading: true,
            };
        case SET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_ACTION_FINISH:
            return {
                ...state,
                isAvailableCashiersLoading: false,
            };
        case SET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_FOR_ADD:
            return {
                ...state,
                availableCashiersForAdd: payload.cashiers,
            };
        case SET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_FOR_TRANSFER:
            return {
                ...state,
                availableCashiersForTransfer: payload.cashiers,
            };
        case SET_MANAGER_BETSHOP_AVAILABLE_BETSHOPS_ACTION_BEFORE:
            return {
                ...state,
                isAvailableBetshopsLoading: true,
            };
        case SET_MANAGER_BETSHOP_AVAILABLE_BETSHOPS_ACTION_FINISH:
            return {
                ...state,
                isAvailableBetshopsLoading: false,
            };
        case SET_MANAGER_BETSHOP_AVAILABLE_BETSHOPS:
            return {
                ...state,
                availableBetshops: payload.betshops,
            };
        default:
            return state;
    }
};
