import React from 'react';
import PropTypes from 'prop-types';

/** Checkbox Component */
const Checkbox = ({
    checked,
    onChange
}) => {

    return (
        <div className='rt--checkbox'>
            <input type="checkbox" checked={checked} onChange={e => onChange(e.target.checked)}/>
            <div className='rt--checkbox-inner rt--flex rt--align-center rt--justify-center'>
                <i className='icon-ok rt--font-big' />
            </div>
        </div>
    )
}

/** Checkbox propTypes
    * PropTypes
*/
Checkbox.propTypes = {
    /** If input is checked */
    checked: PropTypes.bool,
    /** Function fires, on checkbox change */
    onChange: PropTypes.func
}

export default Checkbox;