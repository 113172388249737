import React, {Fragment, useMemo} from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import Table from "components/common/table";
import Pagination from "components/common/pagination";
import Totals from "components/common/totals";

import {
    getBetshopPerformanceReport,
    getBetshopPerformanceTotals,
    setBetshopPerformanceReportFilters,
    setBetshopPerformanceReportSorting
} from 'store/actions/dashboard/betshopPerformanceReport.action';

import PATHS from 'constants/path.constants';

import sortingType from 'types/sorting.type';
import performanceReportType from 'types/performanceReport.type';

import noContentImg from "assets/images/noContent.png";

/** Betshop Manager Betshop Performance Report Page Table Component */

const BetshopPerformanceReport = ({
    getBetshopPerformanceReport,
    getBetshopPerformanceTotals,
    setBetshopPerformanceReportSorting,
    setBetshopPerformanceReportFilters,
    filters,
    isLoading,
    report,
    sorting,
    total,
    columns,
    isTotalsLoading,
    totals
}) => {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const mappedTotals = useMemo(() => {
        if (filters.currencies) {
            const currencyTotal = totals[filters.currencies.toLowerCase()]

            if (currencyTotal) {
                return {
                    [filters.currencies]: currencyTotal
                }
            }
        }

        return totals;
    }, [totals, filters])

    const handleRightArrowIconClick = (record) => {
        const d = {
            from : filters.from,
            to : filters.to,
            name : record.betShopName
        }
        navigate(
            `${PATHS.MANAGER_CASHIER_PERFORMANCE_REPORT}/${record.betShopName}` +
            `?data=${btoa(JSON.stringify(d))}`
        )
    }

    const totalsColumns = [
        {
            key: 'currencyCode',
            title: t("common.currency"),
        },
        {
            key: 'depositCount',
            title: t("common.depositCount"),
            isNumeric: true,
        },
        {
            key: 'depositAmount',
            title: t("common.depositAmount"),
        },
        {
            key: 'withdrawCount',
            title: t("common.withdrawCount"),
            isNumeric: true,
        },
        {
            key: 'withdrawAmount',
            title: t("common.withdrawAmount"),
        },
        {
            key: 'netDepositAmount',
            title: t("common.netDepositAmount"),
        },
        {
            key: 'betCount',
            title: t("common.betCount"),
            isNumeric: true,
        },
        {
            key: 'betAmount',
            title: t("common.betAmount"),
        },
        {
            key: 'wonCount',
            title: t("common.wonCount"),
            isNumeric: true,
        },
        {
            key: 'wonAmount',
            title: t("common.wonAmount"),
        },
        {
            key: 'paidoutCount',
            title: t("common.paidoutCount"),
            isNumeric: true,
        },
        {
            key: 'paidoutAmount',
            title: t("common.paidoutAmount"),
        },
        {
            key: 'bonusBetAmount',
            title: t("common.bonusBetAmount"),
        },
        {
            key: 'bonusWonAmount',
            title: t("common.bonusWonAmount"),
        },
        {
            key: 'bonusRedeemAmount',
            title: t("common.bonusRedeemAmount"),
        },
        {
            key: 'wonNotClaimedBetCount',
            title: t("common.wonNotClaimedBetCount"),
            isNumeric: true,
        },
        {
            key: 'wonNotClaimedBetAmount',
            title: t("common.wonNotClaimedBetAmount"),
        },
        {
            key: 'cancelledBetsCount',
            title: t("common.cancelledBetCount"),
            isNumeric: true,
        },
        {
            key: 'cancelledBetsAmount',
            title: t("common.cancelledBetAmount"),
        },
        {
            key: 'playerSignups',
            title: t("common.playerSignups"),
            isNumeric: true,
        },
        {
            key: 'taxAmount',
            title: t("common.taxAmount"),
        },
        {
            key: 'totalInAmount',
            title: t("common.totalInAmount"),
        },
        {
            key: 'totalOutAmount',
            title: t("common.totalOutAmount"),
        },
        {
            key: 'ggr',
            title: t("common.ggr"),
        },
        {
            key: 'ngr',
            title: t("common.ngr"),
        }
    ]

    return (
        <Fragment>
            <Totals
                name="betshopPerformanceTotals"
                columns={totalsColumns}
                totals={mappedTotals}
                isLoading={isTotalsLoading}
                loadFn={getBetshopPerformanceTotals}
            />
            <Table
                name="betshopPerformanceReport"
                loadFn={getBetshopPerformanceReport}
                setSortingFn={setBetshopPerformanceReportSorting}
                columns={columns.mainTableColumns}
                data={report}
                isLoading={isLoading}
                sorting={sorting}
                emptyImg={noContentImg}
                emptyText={t("common.noDataFound")}
                enableReload={true}
                setFiltersFn={setBetshopPerformanceReportFilters}
                filters={filters}
                actions={
                    [{
                        icon: "icon-right",
                        onClick: handleRightArrowIconClick
                    }]
                }
            />
            <Pagination
                total={total}
                onChange={value => setBetshopPerformanceReportSorting({
                    ...sorting,
                    ...value
                })}
                page={sorting.page}
                limit={sorting.limit}
                loadFn={getBetshopPerformanceReport}
            />
        </Fragment>

    )
}

/** BetshopPerformanceReport propTypes
    * PropTypes
*/
BetshopPerformanceReport.propTypes = {
    /** Redux action to get betshop performance report */
    getBetshopPerformanceReport: PropTypes.func,
    /** Redux action to get betshop performance report totals */
    getBetshopPerformanceTotals: PropTypes.func,
    /** Redux action to set betshop performance report sorting */
    setBetshopPerformanceReportSorting: PropTypes.func,
    /** Redux action to set betshop performance report filters */
    setBetshopPerformanceReportFilters: PropTypes.func,
    /** Redux state property, is true when loading betshop performance report */
    isLoading: PropTypes.bool,
    /** Redux state property, betshop performance report sorting */
    sorting: sortingType,
    /** Redux state property, the array of betshop performance report */
    report: PropTypes.arrayOf(performanceReportType),
    /** Redux state property, betshop performance report total count */
    total: PropTypes.number,
    /** Redux state property, betshop performance report filters */
    filters: PropTypes.object,
    /** Columns data */
    columns: PropTypes.object,
    /** Redux state property, betshop performance report totals */
    totals: PropTypes.object,
    /** Redux state property, is true when loading betshop performance report totals */
    isTotalsLoading: PropTypes.bool,
}

const mapDispatchToProps = dispatch => (
    {
        getBetshopPerformanceReport: () => {
            dispatch(getBetshopPerformanceReport());
        },

        getBetshopPerformanceTotals: () => {
            dispatch(getBetshopPerformanceTotals());
        },

        setBetshopPerformanceReportSorting: sorting => {
            dispatch(setBetshopPerformanceReportSorting(sorting));
        },

        setBetshopPerformanceReportFilters: filters => {
            dispatch(setBetshopPerformanceReportFilters(filters));
        },
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.betshopPerformanceReport.isLoading,
        sorting: state.betshopPerformanceReport.sorting,
        report: state.betshopPerformanceReport.report,
        filters: state.betshopPerformanceReport.filters,
        total: state.betshopPerformanceReport.total,
        totals: state.betshopPerformanceReport.totals.totals,
        isTotalsLoading: state.betshopPerformanceReport.totals.isLoading,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    BetshopPerformanceReport
);
