import React, { Fragment, useEffect } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import moment from 'moment/moment';

import { useTranslation } from 'react-i18next';

import BarCode from "../barCode";

import useFormat from 'hooks/useFormat';

import { resetPayoutData } from 'store/actions/dashboard/ticket.action';

import { binaryToFlags, multiPrintElement, getBetStateText } from 'utils/common';
import { getUser } from 'utils/auth';

import { TICKET_GENERAL_DETAILS, TICKET_BET_DETAILS } from 'constants/ticket.constants';
import { BET_TYPE } from 'constants/bets.constants';
import { DATE_TIME_FORMAT } from 'constants/common.constants';

import userInfoType from 'types/userInfo.type';
import payoutTicketType from 'types/payoutTicket.type';

import Logo from "assets/images/logo-blue.svg"

/** PrintTicket Component */
const PrintTicket = ({
    userInfo,
    payoutTicket,
    resetPayoutData
}) => {
    const { t } = useTranslation();

    const companyLongId = getUser()?.companyLongId;
    const projectLongId = getUser()?.projectLongId;

    const { formatAmount } = useFormat();

    const currency = userInfo?.currencies?.[0] ?? null;

    /** Ticket details */
    const ticketDetails = userInfo.payoutTicket;

    /** Ticket details general sections */
    const generalDetails = binaryToFlags(Object.values(TICKET_GENERAL_DETAILS), ticketDetails.generalDetails);

    /** Ticket details bet details sections */
    const betDetails = binaryToFlags(Object.values(TICKET_BET_DETAILS), ticketDetails.betDetails);

    /** Print Ticket */
    useEffect(() => {
        if (ticketDetails.printPayoutTickets) {
            multiPrintElement(
                payoutTicket.map(ticket => ticket.id),
                (id) => {
                    if (id === payoutTicket[payoutTicket.length - 1].id) {
                        setTimeout(() => {
                            resetPayoutData()
                        }, 0)
                    }
                }
            )
        }
    }, [])

    return (
        <Fragment>
            {
                payoutTicket.map(ticket => (
                    <div id={ticket.id} key={ticket.id} className="rt--print-wrapper">
                        <div className="rt--print">
                            <div className="rt--print-header rt--mb-16">
                                {
                                    ticketDetails.ticketLogoId ?
                                    <img src={`${import.meta.env.SYSTEM_CDN_URL}/companies/${companyLongId}/projects/${projectLongId}/images/${ticketDetails.ticketLogoId}_ticket_logo.png`} alt="logo" /> :
                                    <img src={Logo} alt="logo" />
                                }
                            </div>

                            {
                                (
                                    generalDetails.includes(TICKET_GENERAL_DETAILS.BETSHOP_NAME) ||
                                    generalDetails.includes(TICKET_GENERAL_DETAILS.BETSHOP_ADDRESS) ||
                                    generalDetails.includes(TICKET_GENERAL_DETAILS.TICKET_ID) ||
                                    generalDetails.includes(TICKET_GENERAL_DETAILS.CASHIER_USERNAME)
                                ) && (
                                    <div className="rt--print-info rt--print-info-dashed rt--pt-4 rt--pb-4">
                                        {
                                            generalDetails.includes(TICKET_GENERAL_DETAILS.BETSHOP_NAME) && (
                                                <div className="rt--print-info-row rt--mb-4">
                                                    <span>{t('common.betshopName')}</span><span>{userInfo.betShopName}</span>
                                                </div>
                                            )
                                        }
                                        {
                                            generalDetails.includes(TICKET_GENERAL_DETAILS.BETSHOP_ADDRESS) && (
                                                <div className="rt--print-info-row rt--mb-4">
                                                    <span>{t('common.address')}</span><span>{userInfo.betShopAddress}</span>
                                                </div>
                                            )
                                        }
                                        {
                                            generalDetails.includes(TICKET_GENERAL_DETAILS.TICKET_ID) && (
                                                <div className="rt--print-info-row rt--mb-4">
                                                    <span>{t('common.ticketId')}</span><b>{ticket.id}</b>
                                                </div>
                                            )
                                        }
                                        {
                                            generalDetails.includes(TICKET_GENERAL_DETAILS.CASHIER_USERNAME) && (
                                                <div className="rt--print-info-row rt--mb-4">
                                                    <span>{t('common.cashier')}</span><span>{userInfo?.cashier?.userName ?? ""}</span>
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            }

                            {
                                (
                                    betDetails.includes(TICKET_BET_DETAILS.BET_AMOUNT) ||
                                    betDetails.includes(TICKET_BET_DETAILS.BET_TYPE) ||
                                    betDetails.includes(TICKET_BET_DETAILS.FACTOR) ||
                                    betDetails.includes(TICKET_BET_DETAILS.POSSIBLE_WIN) ||
                                    betDetails.includes(TICKET_BET_DETAILS.WIN_AMOUNT) ||
                                    betDetails.includes(TICKET_BET_DETAILS.CALCULATION_DATE) ||
                                    betDetails.includes(TICKET_BET_DETAILS.BET_STATUS)
                                ) && (
                                    <div className="rt--print-info rt--print-info-dashed rt--pt-4 rt--pb-4">
                                        {
                                            betDetails.includes(TICKET_BET_DETAILS.BET_TYPE) && (
                                                <div className="rt--print-info-row rt--mb-4">
                                                    <span>{t('common.betType')}</span>
                                                    <b>{
                                                        ticket.betType === BET_TYPE.SINGLE ? t('common.single') :
                                                        ticket.betType === BET_TYPE.MULTI ? t('common.multi') : t('common.system')
                                                    }</b>
                                                </div>
                                            )
                                        }

                                        {
                                            betDetails.includes(TICKET_BET_DETAILS.BET_AMOUNT) && (
                                                <div className="rt--print-info-row rt--mb-4">
                                                    <span>{t('common.stake')}</span>
                                                    <b>{formatAmount(ticket.amount, currency?.code)}</b>
                                                </div>
                                            )
                                        }

                                        {
                                            betDetails.includes(TICKET_BET_DETAILS.FACTOR) && (
                                                <div className="rt--print-info-row rt--mb-4">
                                                    <span>{t('common.factor')}</span>
                                                    <b>{ticket.factor}</b>
                                                </div>
                                            )
                                        }

                                        {
                                            betDetails.includes(TICKET_BET_DETAILS.POSSIBLE_WIN) && (
                                                <div className="rt--print-info-row rt--mb-4">
                                                    <span>{t('common.possibleWin')}</span>
                                                    <b>{formatAmount(ticket.possibleWin, currency?.code)}</b>
                                                </div>
                                            )
                                        }

                                        {
                                            betDetails.includes(TICKET_BET_DETAILS.WIN_AMOUNT) && (
                                                <div className="rt--print-info-row rt--mb-4">
                                                    <span>{t('common.win')}</span>
                                                    <b>{formatAmount(ticket.winning, currency?.code)}</b>
                                                </div>
                                            )
                                        }

                                        {
                                            betDetails.includes(TICKET_BET_DETAILS.CALCULATION_DATE) && (
                                                <div className="rt--print-info-row rt--mb-4">
                                                    <span>{t('common.calculationDate')}</span>
                                                    <b>{moment.utc(ticket.calculateTime).local().format(DATE_TIME_FORMAT)}</b>
                                                </div>
                                            )
                                        }

                                        {
                                            betDetails.includes(TICKET_BET_DETAILS.BET_STATUS) && (
                                                <div className="rt--print-info-row rt--mb-4">
                                                    <span>{t('common.status')}</span>
                                                    <b>{getBetStateText(ticket.status)}</b>
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            }

                            {
                                (
                                    betDetails.includes(TICKET_BET_DETAILS.PAYOUT_TIME) ||
                                    betDetails.includes(TICKET_BET_DETAILS.PAYOUT_BY)
                                ) && (
                                    <div className="rt--print-info rt--print-info-dashed rt--pt-4 rt--pb-4">
                                        {
                                            betDetails.includes(TICKET_BET_DETAILS.PAYOUT_TIME) && (
                                                <div className="rt--print-info-row">
                                                    <span>{t('common.payoutTime')}</span>
                                                    <b>{moment.utc(ticket.paidoutTime).local().format(DATE_TIME_FORMAT)}</b>
                                                </div>
                                            )
                                        }

                                        {
                                            betDetails.includes(TICKET_BET_DETAILS.PAYOUT_BY) && (
                                                <div className="rt--print-info-row">
                                                    <span>{t('common.payoutBy')}</span>
                                                    <b>{ticket.payoutBy}</b>
                                                </div>
                                            )
                                        }

                                    </div>
                                )
                            }

                            <div className="rt--print-footer rt--pt-4">
                                <div className="rt--print-footer-inner">
                                    <div className="rt--print-footer-barcode">
                                        {
                                            ticketDetails.enableBarcodePrinting && (
                                                <BarCode value={ticket.id} />
                                            )
                                        }
                                    </div>
                                </div>
                                {ticketDetails.customText && <span className="rt--print-footer-custom-text">{ticketDetails.customText}</span>}
                            </div>
                        </div>
                    </div>
                ))
            }
        </Fragment>

    )
}

/** PrintTicket propTypes
    * PropTypes
*/
PrintTicket.propTypes = {
    /** Redux action to reset payout ticket data */
    resetPayoutData: PropTypes.func,
    /** Redux state property, current user info */
    userInfo: userInfoType,
    /** Redux state property, array of paidout tickets */
    payoutTicket: PropTypes.arrayOf(payoutTicketType)
}

const mapStateToProps = state => {
    return {
        userInfo: state.profile.userInfo,
        payoutTicket: state.ticket.payoutTicket
    }
}

const mapDispatchToProps = dispatch => (
    {
        resetPayoutData: () => {
            dispatch(resetPayoutData());
        }
    }
)



export default connect(mapStateToProps, mapDispatchToProps)(PrintTicket)
