import axios from "axios";

import Methods from "constants/httpMethods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import {
    SET_TICKET_INFO,
    SET_TICKET_INFO_ACTION_FINISH,
    SET_TICKET_INFO_ACTION_BEFORE,
    SET_PAYOUT_ACTION_FINISH,
    SET_PAYOUT_ACTION_BEFORE,
    SET_PAYOUT_ACTION,
    RESET_PAYOUT_ACTION
} from "store/actionTypes";


const setTicketInfoFinished = () => ({
    type: SET_TICKET_INFO_ACTION_FINISH,
});

const setTicketInfoBefore = () => ({
    type: SET_TICKET_INFO_ACTION_BEFORE,
});

const setTicketInfo = data => ({
    type: SET_TICKET_INFO,
    payload: {data}
});

const setPayoutActionFinished = () => ({
    type: SET_PAYOUT_ACTION_FINISH,
});

const setPayoutActionBefore = () => ({
    type: SET_PAYOUT_ACTION_BEFORE,
});

const setPayoutAction = (data, userName) => ({
    type: SET_PAYOUT_ACTION,
    payload: { data, userName }
});

export const resetPayoutData = () => ({
    type: RESET_PAYOUT_ACTION,
})

export const payout = (data, onSuccess) => {

    return (dispatch, getState) => {
        dispatch(setPayoutActionBefore());
        return axios({
            url: ApiUrls.PAYOUT,
            method: Methods.POST,
            data: data
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    const userName = getState()?.profile?.userInfo?.cashier?.userName ?? "";
                    dispatch(setPayoutAction(data.value, userName));
                    dispatch(setPayoutActionFinished());

                    onSuccess && onSuccess()
                }
            })
            .catch((ex) => {
                dispatch(setPayoutActionFinished());
            })
    }
}

export const getTicketInfo = (barcode, onSuccess) => {
    return dispatch => {
        dispatch(setTicketInfoBefore());
        return axios({
            url: ApiUrls.GET_BARCODE_INFO,
            method: Methods.GET,
            params: {
                barcode
            }
        })
            .then(({ data: { value: info } }) => {
                dispatch(setTicketInfo(info))
                onSuccess && onSuccess(info);

                dispatch(setTicketInfoFinished());
            })
            .catch((ex) => {
                onSuccess && onSuccess([]);
                dispatch(setTicketInfoFinished());
            })
    }
}