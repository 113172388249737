import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button } from "antd";

const UnselectedPlayerView = ({ onClick }) => {
    const { t } = useTranslation();

    return (
        <Button
            className="rt--button rt--button-primary"
            onClick={(e) => onClick?.(e)}
        >
            <span className="rt--font-normal rt--font-regular rt--pr-16 rt--pl-16">
                {t("common.getPlayer")}
            </span>
        </Button>
    );
};

UnselectedPlayerView.propTypes = {
    onClick: PropTypes.func,
};

export default UnselectedPlayerView;
