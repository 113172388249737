import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Dropdown from 'components/common/dropdown';

import { changePopupVisibility } from 'store/actions/dashboard/common.action';

import { POPUP_TYPE } from 'constants/popup.constants';
import { PLAYER_TRANSACTION_TYPE } from 'constants/common.constants';

import { hasPaymentAccess, hasPlayerCreateAccess } from 'utils/access';

import userInfoType from 'types/userInfo.type';

const MenuItems = {
    RegisterPlayer: 'registerPlayer',
    DepositPlayer: 'depositPlayer',
    WithdrawPlayer: 'withdrawPlayer'
}

/** Player section in header */
const Player = ({
    changePopupVisibility,
    userInfo
}) => {
    const { t } = useTranslation();

    /** Menu items of dropdown */
    const operationMenuItems = [
        {
            key: MenuItems.RegisterPlayer,
            label: t(`common.${MenuItems.RegisterPlayer}`),
            disabled: !hasPlayerCreateAccess(userInfo)
        },
        {
            key: MenuItems.DepositPlayer,
            label: t(`common.${MenuItems.DepositPlayer}`),
            disabled: !hasPaymentAccess(userInfo)
        },
        {
            key: MenuItems.WithdrawPlayer,
            label: t(`common.${MenuItems.WithdrawPlayer}`),
            disabled: !hasPaymentAccess(userInfo)
        }
    ];

    /** Function to call on dropdown menu item click
		 * @function
         * @param {string} value - menu item value
		 * @memberOf Player
    */
    const handleOperationClick = value => {
        switch (value) {
            case MenuItems.RegisterPlayer:
                if(hasPlayerCreateAccess(userInfo)){
                    changePopupVisibility({ key: POPUP_TYPE.REGISTER_PLAYER })
                }
                break;
            case MenuItems.DepositPlayer:
                if(hasPaymentAccess(userInfo)){
                    changePopupVisibility({ key: POPUP_TYPE.TRANSFER_PLAYER, data: PLAYER_TRANSACTION_TYPE.DEPOSIT })
                }
                break;
            case MenuItems.WithdrawPlayer:
                if(hasPaymentAccess(userInfo)){
                    changePopupVisibility({ key: POPUP_TYPE.TRANSFER_PLAYER, data: PLAYER_TRANSACTION_TYPE.WITHDRAW })
                }
                break;
            default:
                break;
        }
    }

    return (
        <div className='rt--cashier-header-list rt--flex rt--flex-col'>
            <div className='rt--cashier-header-list-header rt--flex rt--align-center rt--justify-center'>
                <span className='rt--title rt--font-normal rt--font-regular'>{t('common.player')}</span>
            </div>
            <div className='rt--cashier-header-list-content rt--justify-between rt--flex rt--flex-equal'>
                <div
                    className='rt--cashier-header-list-content-item rt--flex rt--align-center rt--justify-center rt--flex-equal rt--cursor-pointer'
                    onClick= {() => changePopupVisibility({ key: POPUP_TYPE.TRANSFER_HISTORY })}
                >
                    <span className='rt--title rt--font-normal rt--font-big rt--text-center'>{t('common.transactionHistory')}</span>
                </div>
                <div
                    className={'rt--cashier-header-list-content-item rt--flex rt--align-center rt--justify-center rt--flex-equal rt--cursor-pointer'}
                >
                    <Dropdown
                        element={
                            <Fragment>
                                <span className='rt--title rt--font-normal rt--font-big rt--text-center'>{t('common.operations')}</span>
                                <i className='icon-down rt--font-bigest' />
                            </Fragment>
                        }
                        items={operationMenuItems.filter(i => !i.disabled)}
                        onClick={handleOperationClick}
                    />

                </div>
            </div>
        </div>
    )
}

/** Player propTypes
    * PropTypes
*/
Player.propTypes = {
    /** Redux action to open popup */
    changePopupVisibility: PropTypes.func,
    /** Redux state property, current user info */
    userInfo: userInfoType
}

const mapStateToProps = state => {
    return {
        userInfo: state.profile.userInfo
    }
}

const mapDispatchToProps = dispatch => (
    {
        changePopupVisibility: (data) => {
            dispatch(changePopupVisibility(data))
        }
    }
)
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Player
);
