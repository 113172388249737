import store from 'store/configureStore';

import { getUrlVars } from "./common";
import LocalStorageUtils from "./localStorage"

export default {

	defaultLang: 'en',

	/** Get current selected language
		 * @memberof LanguageUtils      
		 * @function
		 * @returns {string}
	 */
	getSelectedLanguageFromStorage: function () {

		const selectedLang = LocalStorageUtils.get('selected_language');
		if (selectedLang) {
			return selectedLang;
		}
		return this.defaultLang;
	},

	/** Set current language
		 * @memberof LanguageUtils      
		 * @function
		 * @param {string} langCode - language
	 */
	setSelectedLanguageToStorage: function (langCode) {
		const supportedLanguges = store.getState().auth.languages;
		if (supportedLanguges[langCode]) {
			LocalStorageUtils.set("selected_language", langCode);
		}
	},



	/** Get current selected language from url searsh param or localStorage
	   * @memberof LanguageUtils      
	   * @function
	   * @returns {string}
   */
	getSelectedLanguage: function () {
		return getUrlVars()["language"] || this.getSelectedLanguageFromStorage();
	}
}