import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Modal } from 'antd';

import PerformanceReport from "components/common/performanceReport"

import { POPUP_SIZE } from "constants/popup.constants";

/** Performance Report Component */
const PerformanceReportComponent = ({
    handleCloseModal,
}) => {

    const { t } = useTranslation();

    return (
        <Modal
            className={'rt--modal rt--modal-full-height'}
            title={(
                <div className='rt--flex rt--justify-between rt--align-center'>
                    <span className='rt--title rt--font-normal rt--font-regular'>{t("common.report")}</span>
                    <i className='icon-close rt--font-bigest rt--cursor-pointer' onClick={handleCloseModal} />
                </div>
            )}
            visible={true}
            onCancel={handleCloseModal}
            closable={false}
            maskClosable={false}
            getContainer={() => document.getElementById('rt--modal-root')}
            width={POPUP_SIZE.BIGEST}
            centered
            footer={null}
        >
            <div className='rt--modal-performance-report rt--flex rt--flex-col'>
                <PerformanceReport />
            </div>
        </Modal>
    )
}

/** PerformanceReportComponent propTypes
    * PropTypes
*/
PerformanceReportComponent.propTypes = {
    /** Function to close popup */
    handleCloseModal: PropTypes.func
}

export default PerformanceReportComponent;