import i18n from 'translations/config';

import {
    PASSWORD_PATTERN,
    PASSWORD_PATTERN_CONTAINS,
    PASSWORD_PATTERN_RULE
} from 'constants/password.constants';

/** Validate function for password
     * @function
     * @param {string} value - value of password field
     * @param {object} passwordSettings - password validation settings
 */
export const validatePasword = (value, passwordSettings) => {

    if (value.length < passwordSettings.passwordMinLimit || value.length > passwordSettings.passwordMaxLimit) {
        return Promise.reject(i18n.t('validation.passwordRulesMustBeBetween').replace("%X%", passwordSettings.passwordMinLimit).replace("%Y%", passwordSettings.passwordMaxLimit))
    }

    if ( passwordSettings.pattern?.type === PASSWORD_PATTERN.CONTAINS) {
        const passwordSettingsContain = passwordSettings.pattern?.contain ?? [];
        for (let i = 0; i < passwordSettingsContain.length; i++) {
            let result;
            switch (passwordSettingsContain[i].type) {
                case PASSWORD_PATTERN_CONTAINS.UPPERCASE:
                    result = (/[A-Z]/.test(value));
                    break;
                case PASSWORD_PATTERN_CONTAINS.LOWERCASE:
                    result = (/[a-z]/.test(value));
                    break;
                case PASSWORD_PATTERN_CONTAINS.DIGITS:
                    result = (/[0-9]/.test(value));
                    break;
                case PASSWORD_PATTERN_CONTAINS.DASH:
                    result = (/-/.test(value));
                    break;
                case PASSWORD_PATTERN_CONTAINS.UNDERSCORE:
                    result = (/_/.test(value));
                    break;
                case PASSWORD_PATTERN_CONTAINS.SPACE:
                    result = (/\s/.test(value));
                    break;
                case PASSWORD_PATTERN_CONTAINS.SPECIAL:
                    result = (/[!#$%&*+,./:;=?@\\^`|~'"]/.test(value));
                    break;
                case PASSWORD_PATTERN_CONTAINS.BRACKETS:
                    result = (/[{([<>\])}]/.test(value));
                    break;
            }

            if (result && passwordSettingsContain[i].value === PASSWORD_PATTERN_RULE.NONE) {
                return Promise.reject(i18n.t('validation.passwordRulesMustNotContain').replace("%X%", i18n.t(`common.passwordSettingsContainType${passwordSettingsContain[i].type}`)));
            } else if (!result && passwordSettingsContain[i].value === PASSWORD_PATTERN_RULE.MUST) {
                return Promise.reject(i18n.t('validation.passwordRulesMustContain').replace("%X%", i18n.t(`common.passwordSettingsContainType${passwordSettingsContain[i].type}`)));
            }
        }

        const passwordSettingsAlsoIncludeCharacters = passwordSettings.pattern?.alsoIncludeCharacters ?? "";

        if (passwordSettingsAlsoIncludeCharacters) {
            if (!passwordSettingsAlsoIncludeCharacters
                .split('')
                .every(char => value.includes(char))) {
                return Promise.reject(i18n.t('validation.passwordRulesMustInclude').replace("%X%", passwordSettingsAlsoIncludeCharacters))
            }
        }

    } else if (passwordSettings.pattern?.type === PASSWORD_PATTERN.PATTERN) {

        const passwordSettingsRegularExpression = passwordSettings.pattern?.regularExpression ?? "";

        const reg = new RegExp(passwordSettingsRegularExpression, 'g');
        if (!reg.test(value)) {
            return Promise.reject(i18n.t('validation.passwordRulesMustMatchRegularExpression').replace("%X%", passwordSettingsRegularExpression));
        }
    }

    return Promise.resolve();
}