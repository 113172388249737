const PATHS = {
    AUTHENTICATE: "/Account/Login",
    REFRESH_TOKEN: "/Account/RefreshToken",
    RESET_PASSWORD: "/Account/ResetPassword",
    SET_PASSWORD: "/Account/SetPassword",
    GET_USER_INFO: "/Account/Info",
    GET_PASSWORD_SETTINGS: "/Account/PasswordSettings",
    GET_AVAILABLE_CURRENCIES: "/Account/AvailableCurrencies",

    SET_PLAYER: "/Cashier/SetPlayer",
    RESET_PLAYER: "/Cashier/ResetPlayer",

    GET_SYSTEM_LANGUAGES: "/Language/SystemAvailable",

    GET_BETSHOPS: "/Account/BetShops",
    CHANGE_BETSHOP: "/Account/ChangeBetShop",

    LAUNCH_PROVIDER: "/Retail/Launch",

    GET_BARCODE_INFO: "/Bet/Barcode",
    PAYOUT: "/Bet/Payout",
    GET_BET_HISTORY: "/Bet/History",
    GET_BET_HISTORY_TOTALS: "/Bet/RetailBetsTotals",
    EXPORT_BET_HISTORY: "/Bet/ExportHistory",
    GET_PENDING_BETS: "/Bet/PendingBets",

    CREATE_PLAYER: "/Player/Create",
    GET_PLAYER_REGISTRATION_FORM: "/Player/RegistrationForm",
    GET_PLAYER_AVAILABLE_CURRENCIES: "/Player/AvailableCurrencies",
    GET_PLAYER_INFO: "/Player/GeneralInfo",
    GET_PLAYER_WITHDRAWALS: "/Player/Withdrawals",

    GET_MANAGER_BETSHOPS: "/BetShop/All",
    EXPORT_MANAGER_BETSHOPS: "/BetShop/Export",
    GET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_FOR_ADD: "/BetShop/AvailableCashiersForAdd",
    GET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_FOR_TRANSFER: "/BetShop/AvailableCashiersForTransfer",
    GET_MANAGER_BETSHOP_AVAILABLE_BETSHOPS: "/BetShop/Available",

    GET_CASHIERS: "/Cashier/All",
    EXPORT_CASHIERS: "/Cashier/Export",
    CASHIER_ADD_BETSHOP_ACCESS: "/Cashier/AddBetShopAccess",
    CASHIER_REMOVE_BETSHOP_ACCESS: "/Cashier/RemoveBetShopAccess",
    CASHIER_CHANGE_STATE: "/Cashier/ChangeState",

    GET_AVAILABLE_CASHIERS_FOR_HANDOVER: "/Cashier/AvailableCashiersForHandover",
    CASHIER_HANDOVER: "/Cashier/Handover",
    CASHIER_REJECT_HANDOVER: "/Cashier/RejectHandover",

    GET_MANAGER_AVAILABLE_CASHIERS: "/Cashier/AvailableCashiers",

    GET_CASHIER_SESSIONS: "/Session/CashiersSessions",
    EXPORT_CASHIER_SESSIONS: "/Session/Export",

    WALLET_CASHIER_TRANSACTION: "/Wallet/CashierTransaction",
    WALLET_RESET_BETSHOP_LIMIT: "/Wallet/ResetBetShopLimit",
    PLAYER_DEPOSIT: "/Wallet/PlayerDeposit",
    PLAYER_WITHDRAW: "/Wallet/PlayerWithdraw",

    GET_CASHIER_TRANSACTIONS: "/Report/CashierTransactions",
    GET_BETSHOP_TRANSACTIONS: "/Report/BetShopTransactions",
    EXPORT_BETSHOP_TRANSACTIONS: "/Report/ExportBetShopTransactions",
    GET_BETSHOP_PERFORMANCE_REPORT: "/Report/BetShopPerformance",
    GET_BETSHOP_PERFORMANCE_TOTALS: "/Report/BetShopPerformanceTotals",
    EXPORT_BETSHOP_PERFORMANCE_REPORT: "/Report/ExportBetshopPerformance",
    GET_CASHIER_PERFORMANCE_REPORT: "/Report/CashierPerformance",
    EXPORT_CASHIER_PERFORMANCE_REPORT: "/Report/ExportCashierPerformance",
}

export default PATHS;
