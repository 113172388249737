import React from 'react'
import PropTypes from 'prop-types';

import BarcodeScanner from './scanner'

import { connect } from 'react-redux'

import { changePopupVisibility } from 'store/actions/dashboard/common.action';

import { POPUP_TYPE } from 'constants/popup.constants'

/** BarCode Reader Component */
const BarCodeReaderComponent = ({
	changePopupVisibility
}) => {
	
	/** Function to fire on scan
		 * @function
		 * @param {string} data - scaned data
		 * @memberOf BarCodeReader
    */
	const handleScan = data => {
		changePopupVisibility({ key: POPUP_TYPE.CHECK_TICKET, data: data })
	}

	return (
		<BarcodeScanner
			onError={err => console.log(err)}
			onScan={handleScan}
		/>
	)

}

/** BarCodeReaderComponent propTypes
    * PropTypes
*/
BarCodeReaderComponent.propTypes = {
    /** Redux action to open popup */
    changePopupVisibility: PropTypes.func
}

const mapDispatchToProps = dispatch => (
	{
		changePopupVisibility: data => {
			dispatch(changePopupVisibility(data))
		}
	}
)

export default connect(
	null,
	mapDispatchToProps
)(
	BarCodeReaderComponent
);
