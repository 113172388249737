import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment/moment';

import localStorageUtils from 'utils/localStorage';
import { isHandoverActive } from 'utils/auth';

import { HANDOVER_EXPIRATION_MINUTES } from 'constants/auth.constants';

let interval = null;

/** Handover CountDown component */
const HandoverCountDown = ({
    onEnd
}) => {

    /** Countdown seconds */
    const [seconds, setSeconds] = useState("");

    /** Function to start interval
       * @function
       * @memberOf HandoverCountDown
   */
    const startInterval = () => {
        clearInterval(interval);
        interval = setInterval(() => {
            updateInterval()
        }, 1000)
    }

    /** Function to update interval
       * @function
       * @memberOf HandoverCountDown
   */
    const updateInterval = () => {
        const handover = localStorageUtils.get("handover");
        if(!handover) {
            return clearInterval(interval);
        }
        let requestTime = moment.utc(handover.requestTime).local();

        requestTime.add(HANDOVER_EXPIRATION_MINUTES, 'minutes');

        const now = moment(new Date());
        const diff = moment.duration(requestTime.diff(now)).asSeconds();
        if(diff < 0){
            clearInterval(interval);
            onEnd && onEnd();
            return;
        }
        const formatted = moment.utc(diff*1000).format("mm:ss");

        setSeconds(formatted);
    }

    /** Start countdown interval */
    useEffect(() => {
        if(isHandoverActive()){
            startInterval()
        }

        return () => {
            clearInterval(interval)
        }
    }, [])

    return (
        <div className='rt--auth-handover-countdown rt--mb-24'>
            <span className='rt--title rt--font-extra-biger rt--font-regular'>{ seconds }</span>
        </div>
    )
}

/** HandoverCountDown propTypes
    * PropTypes
*/
HandoverCountDown.propTypes = {
    /** Function to call on countdown finish */
    onEnd: PropTypes.func
}

export default HandoverCountDown;
