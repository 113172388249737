export default {
    /** Set in sessionStorage
       * @memberof sessionStorageUtils      
       * @function
       * @param {string} key - key
       * @param {Object} value - value
   */
    set: (key, value) => {
        sessionStorage.setItem(key, JSON.stringify(value));
    },

    /** Clear sessionStorage
       * @memberof sessionStorageUtils      
       * @function
   */
    clear: () => {
        sessionStorage.clear();
    },

    /** Remove from sessionStorage
       * @memberof sessionStorageUtils      
       * @function
       * @param {string} key - key
   */
    remove: key => {
        sessionStorage.removeItem(key);
    },

    /** Get from sessionStorage
       * @memberof sessionStorageUtils      
       * @function
       * @param {string} key - key
       * @returns {Object}
   */
    get: key => {
        var data = sessionStorage.getItem(key);
        return JSON.parse(data);
    }
}