import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button } from "antd";

const SelectedPlayerView = ({ onClick, userName, id }) => {
    const { t } = useTranslation();

    return (
        <div className="rt--cashier-header-playerSelector-selected">
            <div className="rt--cashier-header-playerSelector-selected-separator" />

            <i className="icon-user rt--font-bigest" />

            <div className="rt--flex rt--flex-col">
                <span className="rt--cashier-header-playerSelector-selected-playerId rt--title rt--font-normal rt--font-regular">
                    ID: {id}
                </span>
                <span className="rt--title rt--font-normal rt--font-regular">
                    {userName}
                </span>
            </div>

            <Button
                className="rt--button rt--button-secondary"
                onClick={() => onClick?.()}
            >
                <span className="rt--font-normal rt--font-regular">
                    {t("common.backToCashier")}
                </span>
            </Button>
        </div>
    );
};

SelectedPlayerView.propTypes = {
    onClick: PropTypes.func,
    userName: PropTypes.string,
    id: PropTypes.number,
};

export default SelectedPlayerView;
