import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Dropdown from "components/common/dropdown";
import Flag from 'components/common/flag';

import LanguageUtils from 'utils/languages';
import { setUrlParam } from 'utils/common';


/** Languages Dropdown component */
const LanguagesDropdown = ({
    languages,
    onChange
}) => {
    const { t } = useTranslation();

    const currentLn = LanguageUtils.getSelectedLanguage(); 

    /** The menu items */
    const menuItems = Object.keys(languages)
        .map(ln => {
            const langName = languages[ln].toLocaleLowerCase();
            return {
                key: ln,
                label: (
                    <div className='rt--flex rt--align-center'>
                        <Flag code={ln} />
                        <span className="rt--title rt--font-big rt--font-regular rt--pl-8">{t(`common.${langName}`, languages[ln])}</span>
                    </div>)
            }
        });

    /** Fires on dropdown item click
     * @function
     * @param {string} value - selected item value
     * @memberOf LanguageList
    */
    const handleClick = value => {

        setUrlParam('language', value)
        LanguageUtils.setSelectedLanguageToStorage(value);
        onChange(value);
    }

    return (
        <div className='rt--auth-languages'>
            <Dropdown
                element={
                    <div className='rt--auth-languages-element rt--flex rt--align-center'>
                        <Flag code={currentLn} />
                        <span className='rt--title rt--font-big rt--font-medium rt--pl-8'>{`${t(`common.${(languages[currentLn] || "").toLocaleLowerCase()}`, languages[currentLn])}`}</span>
                        <i className='icon-down rt--font-bigest' />
                    </div>
                }
                items={menuItems}
                onClick={handleClick}
            />
        </div>
    )
}

/** LanguagesDropdown propTypes
    * PropTypes
*/
LanguagesDropdown.propTypes = {
    /** Redux state property, system languages */
    languages: PropTypes.object,
    /** Function to fire on dropdown change */
    onChange: PropTypes.func
}

const mapStateToProps = state => {
    return {
        languages: state.auth.languages,
        languagesLoaded: state.auth.languagesLoaded
    }
}

export default connect(
    mapStateToProps,
    null
)(
    LanguagesDropdown
);