import React from 'react';

import BetshopPerformanceReportComponent from 'components/manager/betshopPerformanceReport';

import withAuth from 'hocs/withAuth';

const ManagerCashierPerformanceReportRoute = () => {
    return (
        <BetshopPerformanceReportComponent isCashierReport={true} /> 
    )
}

export default withAuth(ManagerCashierPerformanceReportRoute);