import React, { Fragment } from 'react';

import PerformanceReportTable from "./performanceReport";
import Filters from "./filters";

/** Performance Report Component */
const PerformanceReport = () => {
    return (
        <Fragment>
            <Filters />
            <PerformanceReportTable />
        </Fragment>
    )
}

export default PerformanceReport;
