import React from 'react';
import PropTypes from "prop-types";

const Tag = ({color, children}) => {
    return <span className="rt--tag" style={{color}}>{children}</span>;
};

Tag.propTypes = {
    color: PropTypes.string,
    children: PropTypes.node.isRequired,
}

export default Tag;
