import React, { useEffect } from 'react';

import { Provider } from 'react-redux';

import { RouterProvider } from 'react-router-dom';

import 'translations/config';

import store from 'store/configureStore';
import router from 'routes/router';

import 'utils/axios';

store.subscribe(() => { });

const App = () => {

	const scale = () => {
		const coificient = window.innerWidth / 1920
		if (coificient <= 1) {
			document.body.style.transform = "scale(" + coificient + ")";
			document.body.style.height = window.innerHeight / coificient + "px";
			document.documentElement.style.width = "auto"
		}
	}

	/** Scale functionality */
	useEffect(() => {
		scale()
		window.addEventListener("resize", scale);
		return () => {
			window.removeEventListener("resize", scale);
		}

	}, []);

	return (
		<Provider store={store}>
			<div className="App">
				<RouterProvider router={router} />
			</div>
		</Provider>
	)
}



export default App;
