import PropTypes from 'prop-types';

export default PropTypes.shape({
    id: PropTypes.number,
    userId: PropTypes.number,
    userName: PropTypes.string,
    ip: PropTypes.string,
    country: PropTypes.string,
    companyId: PropTypes.number,
    projectId: PropTypes.number,
    projectName: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    userRole: PropTypes.number
})