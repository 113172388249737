import {TRANSACTION_TYPE} from "constants/common.constants";

/** Function to make transaction text depend on transaction type
 * @function
 * @param {number} transactionType
 * @returns {string}
 * @memberOf Transactions
 */
export const makeTransactionText = (transactionType, t) => {
    const typeMap = {
        [TRANSACTION_TYPE.BET]: t("common.bet"),
        [TRANSACTION_TYPE.CANCEL_BET]: t("common.cancelBet"),
        [TRANSACTION_TYPE.PAIDOUT]: t("common.payout"),
        [TRANSACTION_TYPE.WON]: t("common.won"),
        [TRANSACTION_TYPE.ROLLBACK]: t("common.rollback"),
        [TRANSACTION_TYPE.REJECT_BET]: t("common.rejected"),
        [TRANSACTION_TYPE.RECALCULATE]: t("common.recalculate"),
        [TRANSACTION_TYPE.PLAYER_DEPOSIT]: t("common.depositToPlayer"),
        [TRANSACTION_TYPE.PLAYER_WITHDRAWAL]: t("common.withdrawFromPlayer"),
        [TRANSACTION_TYPE.BETSHOP_MANAGER_DEPOSIT_CASHIER]: t("common.depositToCashier"),
        [TRANSACTION_TYPE.BETSHOP_MANAGER_WITHDRAW_CASHIER]: t("common.withdrawFromCashier"),
        [TRANSACTION_TYPE.BETSHOP_CASHIER_HANDOVER_CASHIER]: t("common.shiftHandover"),
        [TRANSACTION_TYPE.BETSHOP_LIMIT_AUTO_ADJUSTMENT]: t("common.betShopLimitAutoAdjustment"),
        [TRANSACTION_TYPE.BETSHOP_LIMIT_MANUAL_ADJUSTMENT]: t("common.betShopLimitManualAdjustment"),
    };

    return typeMap[transactionType] || "";
}
