import axios from 'axios';
import { isResponseSuccess } from "utils/request";
import LocalStorageUtils  from "utils/localStorage"

import Methods from 'constants/httpMethods.constants';
import ApiUrls from 'constants/api.constants';

import {
    AUTHENTICATE_ACTION_BEFORE,
    AUTHENTICATE_ACTION_FINISH,
    AUTHENTICATE_ACTION_SET_EXPIRE
} from 'store/actionTypes';

const setAuthenticateActionBefore = () => ({
    type: AUTHENTICATE_ACTION_BEFORE
})

const setAuthenticateActionFinished = () => ({
    type: AUTHENTICATE_ACTION_FINISH
})

const setAuthenticateActionSetExpire = expires => ({
    type: AUTHENTICATE_ACTION_SET_EXPIRE,
    payload: { expires }
})

export const setTokenExpiration = expires => {
    return dispatch => {
        dispatch(setAuthenticateActionSetExpire(expires));
    }
}

export const authenticate = (userName, password, token) => {
    const betShopData = LocalStorageUtils.get('betshopData') || {};
    return dispatch => {
        dispatch(setAuthenticateActionBefore());
        return axios({
            url: ApiUrls.AUTHENTICATE,
            method: Methods.POST,
            data: { userName, password, token,...(betShopData[userName] ? {betShopId:betShopData[userName]}:{}) }
        })
            .then(({ data }) => {
                dispatch(setAuthenticateActionFinished());
                if(isResponseSuccess(data)) {
                    return { ... ( data?.value ?? {} ), userName: userName};
                }
            })
            .catch(({ response }) => {
                dispatch(setAuthenticateActionFinished());
                throw response;
            })
    }
};
