import React, { Fragment } from 'react';

import {useTranslation} from "react-i18next";

import moment from "moment";

import Sessions from "./sessions";
import Filters from "./filters";

import { DATE_TIME_FORMAT } from 'constants/common.constants';

/** Betshop Manager Sessions Page */

const BetshopManagerSessions = () => {
    const { t } = useTranslation();

    /** Table columns */
    const columns = [
        {
            key: "userName",
            title: t("common.cashier")
        },
        {
            key: "betShopName",
            title: t("common.betshop")
        },
        {
            key: "id",
            title: t("common.sessionid"),
        },
        {
            key: "startTime",
            title: t("common.sessionStart"),
            render: value => <span className='rt--title rt--font-normal rt--font-regular'>{value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "-"}</span>
        },
        {
            key: "endTime",
            title: t("common.sessionEnd"),
            render: value => <span className='rt--title rt--font-normal rt--font-regular'>{value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "-"}</span>
        },
        {
            key: "duration",
            title: t("common.sessionDuration"),
            disabled: true,
            render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{record.startTime ? moment.duration(moment(record.endTime).diff(moment(record.startTime)), 'milliseconds').humanize() : "-"}</span>
        },
        {
            key: "ip",
            title: t("common.ip")
        }
    ]

    return (
        <Fragment>
            <Filters columns={columns} />
            <Sessions columns={columns} />
        </Fragment>
    )
}

export default BetshopManagerSessions;
