import {
    AUTHENTICATE_ACTION_SET_EXPIRE,
    AUTHENTICATE_ACTION_BEFORE,
    AUTHENTICATE_ACTION_FINISH,
    SET_PASSWORD_SETTINGS_ACTION_BEFORE,
    SET_PASSWORD_SETTINGS_ACTION_FINISH,
    SET_PASSWORD_SETTINGS,
    SET_SET_PASSWORD_ACTION_BEFORE,
    SET_SET_PASSWORD_ACTION_FINISH,
    SET_LANGUAGES_BEFORE,
    SET_LANGUAGES_AFTER,
    SET_LANGUAGES,
    SET_TRANSLATIONS_LOADED
} from 'store/actionTypes';


export default (state = {}, { type, payload }) => {
    switch (type) {
        case AUTHENTICATE_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case AUTHENTICATE_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case AUTHENTICATE_ACTION_SET_EXPIRE:
            return {
                ...state,
                expires: payload.expires
            }
        case SET_PASSWORD_SETTINGS_ACTION_BEFORE:
            return {
                ...state,
                passwordSettings: {
                    ...state.passwordSettings,
                    isLoading: true
                }
            }
        case SET_PASSWORD_SETTINGS_ACTION_FINISH:
            return {
                ...state,
                passwordSettings: {
                    ...state.passwordSettings,
                    isLoading: false
                }
            }
        case SET_PASSWORD_SETTINGS:
            return {
                ...state,
                passwordSettings: {
                    ...state.passwordSettings,
                    passwordSettings: payload.passwordSettings
                }
            }
        case SET_SET_PASSWORD_ACTION_BEFORE:
            return {
                ...state,
                resetPassword: {
                    ...state.resetPassword,
                    isReseting: true
                }
            }
        case SET_SET_PASSWORD_ACTION_FINISH:
            return {
                ...state,
                resetPassword: {
                    ...state.resetPassword,
                    isReseting: false
                }
            }
        case SET_LANGUAGES_BEFORE:
            return {
                ...state,
                isLanguagesLoading: true
            }
        case SET_LANGUAGES_AFTER:
            return {
                ...state,
                isLanguagesLoading: false
            }
        case SET_LANGUAGES:
            return {
                ...state,
                languages: payload.languages,
                languagesLoaded: true
            }
        case SET_TRANSLATIONS_LOADED:
            return {
                ...state,
                translationsLoaded: true
            }
        default:
            return state;
    }
}