import {
    SET_CASHIER_PERFORMANCE_REPORT_ACTION_BEFORE,
    SET_CASHIER_PERFORMANCE_REPORT_ACTION_FINISH,
    SET_CASHIER_PERFORMANCE_REPORT,
    SET_CASHIER_PERFORMANCE_REPORT_FILTERS,
	 SET_PENDING_BETS
} from "store/actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_CASHIER_PERFORMANCE_REPORT_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_CASHIER_PERFORMANCE_REPORT_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_CASHIER_PERFORMANCE_REPORT:
            return {
                ...state,
                report: payload.report.item2,
                total: payload.report.item1,
            };
        case SET_CASHIER_PERFORMANCE_REPORT_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...payload.filters
                }
            };
        case SET_PENDING_BETS:
            return {
                ...state,
                pendingBets: payload.pendingBets
            };
        default:
            return state;
    }
};