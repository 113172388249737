import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal } from 'antd';

import Loader from "components/common/loader";

import { getBetshops, changeBetshop } from 'store/actions/auth/betshops.action';

import { loginUser } from 'utils/auth';
import LocalStorageUtils from "utils/localStorage"
import sessionStorage from 'utils/sessionStorage';

import { POPUP_SIZE } from 'constants/popup.constants';

import userInfoType from 'types/userInfo.type';
import accountBetshopType from 'types/accountBetshop.type';

import noBetshopImg from "assets/images/noBetshop.png";

/** Change Betshop  Component */
const ChangeBetShopComponent = ({
    getBetshops,
    changeBetshop,
    betshops,
    isLoading,
    isSaving,
    handleCloseModal,
    userInfo
}) => {

    const { t } = useTranslation();

    const [selectedBetShop, setSelectedBetShop] = useState(false);

    /** Get Betshops */
    useEffect(() => {
        !betshops.length && getBetshops();
    }, [])


    /** Function, to call on ok button click
		 * @function
		 * @memberOf ChangeBetShopComponent
    */
    const handleOK = () => {
        changeBetshop(null, selectedBetShop, (data) => {
            const userName = userInfo.cashier.userName; 
            const storageData = LocalStorageUtils.get('betshopData') || {};
            storageData[userName] = selectedBetShop
            LocalStorageUtils.set('betshopData', storageData);
            sessionStorage.remove("playerInfo");
            loginUser(data);
            handleCloseModal()
            setTimeout(() => {
                window.location.reload();
            }, 0)
        });
    }

    /** Filtered betshops data */
    const data = betshops.filter(b => b.id !== userInfo.betShopId)

    return (
        <Modal
            className='rt--modal'
            title={(
                <div className='rt--flex rt--justify-between rt--align-center'>
                    <span className='rt--title rt--font-normal rt--font-regular'>{t("common.changeBetShop")}</span>
                    <i className='icon-close rt--font-bigest rt--cursor-pointer' onClick={handleCloseModal} />
                </div>
            )}
            visible={true}
            onCancel={() => handleCloseModal()}
            closable={false}
            maskClosable={false}
            onOk={handleOK}
            okButtonProps={{ className: !selectedBetShop ? 'rt--button-disabled rt--target-btn' : 'rt--target-btn' }}
            cancelButtonProps={{ className: 'rt--button-secondary' }}
            cancelText={t('common.cancel')}
            okText={t('common.change')}
            getContainer={() => document.getElementById('rt--modal-root')}
            width={POPUP_SIZE.NORMAL}
            centered
        >
            <div className='rt--modal-change-betshop'>
                <div className='rt--modal-change-betshop-header rt--mb-24 rt--pl-16 rt--pr-12 rt--flex rt--align-center'>
                    <div className='rt--modal-change-betshop-header-icon rt--flex rt--align-center rt--justify-center'>
                        <i className='icon-location rt--font-bigest' />
                    </div>
                    <div className='rt--flex rt--flex-col rt--justify-center rt--ml-8 rt--modal-change-betshop-header-info'>
                        <b className='rt--title rt--font-normal rt--font-medium'>{`${userInfo.betShopName}`}</b>
                        <span className='rt--title rt--pt-4 rt--font-regular rt--font-small rt--ellipsis'>{`${userInfo.betShopAddress}`}</span>
                    </div>
                    <div className='rt--modal-change-betshop-header-badge rt--pl-8 rt--pr-8'>
                        <span className='rt--title rt--font-normal rt--font-regular'>{t("common.currentBetShop")}</span>
                    </div>
                </div>
                <span className='rt--title rt--font-regular rt--font-big rt--modal-desc'>{t("common.changeBetShopInfo")}</span>
                <div className="rt--select-betshop rt--flex rt--flex-wrap">
                    {
                        (isLoading || isSaving) ? <Loader /> : data.length > 0 ? data.map(shop => (
                            <div
                                className='rt--select-betshop-item rt--cursor-pointer rt--pl-16 rt--pr-16 rt--flex rt--align-center rt--justify-between rt--mr-16 rt--mt-16'
                                onClick={() => setSelectedBetShop(shop.id)}
                                key={shop.id}
                            >
                                <div className='rt--flex rt--flex-col'>
                                    <span className='rt--title rt--font-normal rt--font-regular rt--mb-4' title={shop.name}>{shop.name}</span>
                                    <span className='rt--title rt--font-small rt--font-regular' title={shop.address}>{shop.address}</span>
                                </div>
                                {
                                    selectedBetShop === shop.id && (
                                        <div className='rt--ml-8 rt--select-betshop-item-circle'>
                                            <i className='icon-checked-circle rt--font-bigest' />
                                        </div>
                                    )
                                }

                            </div>
                        )) : (
                            <div className='rt--select-betshop-empty rt--flex rt--flex-col rt--align-center rt--justify-center rt--pt-24 rt--pb-24'>
                                <img alt={t("common.noBetshopsFound")} src={noBetshopImg} />
                                <span className='rt--title rt--pt-8 rt--text-center rt--font-medium rt--font-big'>{t("common.noBetshopsFound")}</span>
                            </div>
                        )
                    }
                </div>
            </div>
        </Modal>
    )
}

/** ChangeBetShopComponent propTypes
    * PropTypes
*/
ChangeBetShopComponent.propTypes = {
    /** Function to close popup */
    handleCloseModal: PropTypes.func,
    /** Redux action to change betshop */
    changeBetshop: PropTypes.func,
    /** Redux action to get betshops */
    getBetshops: PropTypes.func,
    /** Redux state property, current user info */
    userInfo: userInfoType,
    /** Redux state property, is true when betshop change request is in process */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when loading betshops */
    isLoading: PropTypes.bool,
    /** Redux state property, available betshops for account */
    betshops: PropTypes.arrayOf(accountBetshopType)
}

const mapDispatchToProps = dispatch => (
    {
        changeBetshop: (token, id, onSuccess) => {
            dispatch(changeBetshop(token, id, onSuccess))
        },
        getBetshops: token => {
            dispatch(getBetshops(token))
        }
    }
)

const mapStateToProps = state => {
    return {
        betshops: state.betshops.betshops,
        userInfo: state.profile.userInfo,
        isSaving: state.betshops.isSaving,
        isLoading: state.betshops.isLoading
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ChangeBetShopComponent
);