import axios from 'axios';
import moment from 'moment/moment';

import sessionStorageUtils from './sessionStorage';
import localStorageUtils from './localStorage';

import Paths from 'constants/path.constants';
import ApiUrls from 'constants/api.constants';
import Methods from 'constants/httpMethods.constants';
import { HANDOVER_EXPIRATION_MINUTES, TOKEN_TYPE } from 'constants/auth.constants';
import { USER_STATE } from 'constants/common.constants';


/** Checks if user is Authenticated
     * @function
     * @returns {boolean}
 */
export const isAuthenticated = () => {
    const authData = sessionStorageUtils.get('authorizationData');
    return !!authData;
}

/** Get current user
     * @function
     * @returns {Object}
 */
export const getUser = () => {
    const authData = sessionStorageUtils.get('authorizationData');
    return authData ? {
        isLoggedIn: true,
        token: authData.token,
        wsToken: authData.wsToken,
        refreshToken: authData.refreshToken,
        userName: authData.userName,
        userType: authData.userType,
        expiresAt: authData.expiresAt,
        sessionId:authData.sessionId,
        betShopId: authData.betShopId,
        role: authData.role,
        userState: authData.userState,
        environmentType: authData.environmentType,
        companyLongId: authData.companyLongId,
        projectLongId: authData.projectLongId
    } : null;
}

/** Function to Login user
     * @function
     * @param {Object} user - user data got from user authenticate request
 */
export const loginUser = user => {
    sessionStorageUtils.set('authorizationData', user);
    if(user.ticketId){
        const handoverData = localStorageUtils.get("handover");
        handoverData.ticketId = user.ticketId;
        localStorageUtils.set("handover", handoverData);
    }
}

/** Function to Logout user
     * @function
 */
export const logout = () => {
    sessionStorageUtils.remove("authorizationData");
    sessionStorageUtils.remove("playerInfo");

    window.location.href = isHandoverActive() ? Paths.HANDOVER : Paths.LOGIN;
}

const pendingRequests = [];
let isRefreshingToken = false;

/** Sends request to server to refresh token
     * @function
     * @param {string} refresh_token - refresh token
     * @param {string} userId - user id
     * @param {string} userName - user name
     * @param {Object} requestConfig - the request configuration which will be sends, when new tokens got from server
     * @returns {Promise}
*/
export const refreshToken = (refresh_token, userName, requestConfig) => {


    if (!isRefreshingToken) {
        isRefreshingToken = true;
        return axios({
            url: ApiUrls.REFRESH_TOKEN,
            method: Methods.POST,
            data: { refreshToken: refresh_token },
            headers: { grant_type: "refresh_token" }
        }).then(({ status, data: { value: authData } }) => {
            if (status === 200 && authData.tokenType === TOKEN_TYPE.NONE) {
                loginUser({ ...authData, userName });
                isRefreshingToken = false;
                pendingRequests.forEach(req => {
                    req.request && axios.request(req.request).then(d => req.resolve(d)).catch(err => req.reject(err));
                });
                pendingRequests.splice(0, pendingRequests.length);
                return requestConfig && axios.request(requestConfig);
            } else {
                logout();
            }
        }).catch(() => {
            isRefreshingToken = false;
        })
    } else {
        const obj = {
            request: requestConfig
        }
        const promise = new Promise((resolve, reject) => {
            obj.resolve = resolve;
            obj.reject = reject;
            pendingRequests.push(obj);
        });
        obj.promise = promise;
        return promise;
    }

}

/** Checks if user blocked
     * @function
     * @returns {bool}
*/
export const isUserBlocked = () => getUser()?.userState === USER_STATE.BLOCKED

/** Checks if handover is expired
     * @function
     * @returns {bool}
*/
export const isHandoverExpired = () => {
    const handover = localStorageUtils.get("handover");
    if(!handover) return true;
    let requestTime = moment.utc(handover.requestTime).local();

    requestTime.add(HANDOVER_EXPIRATION_MINUTES, 'minutes');

    const now = moment(new Date());
    const isExpired = requestTime.isBefore(now);

    return isExpired;
}

/** Checks if handover is active
     * @function
     * @returns {bool}
*/
export const isHandoverActive = () => !isHandoverExpired()
