import axios from "axios";

import Methods from "constants/httpMethods.constants";
import ApiUrls from "constants/api.constants";

import LanguageUtils from 'utils/languages';

import {
    SET_MANAGER_BETSHOPS_ACTION_BEFORE,
    SET_MANAGER_BETSHOPS_ACTION_FINISH,
    SET_MANAGER_BETSHOPS_SAVE_ACTION_BEFORE,
    SET_MANAGER_BETSHOPS_SAVE_ACTION_FINISH,
    SET_MANAGER_BETSHOPS,
    SET_MANAGER_BETSHOPS_SORTING,
    SET_MANAGER_BETSHOPS_FILTERS,
    SET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_ACTION_BEFORE,
    SET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_ACTION_FINISH,
    SET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_FOR_ADD,
    SET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_FOR_TRANSFER,
    SET_MANAGER_BETSHOP_AVAILABLE_BETSHOPS_ACTION_BEFORE,
    SET_MANAGER_BETSHOP_AVAILABLE_BETSHOPS_ACTION_FINISH,
    SET_MANAGER_BETSHOP_AVAILABLE_BETSHOPS
} from "store/actionTypes";

const setManagerBetshopsActionBefore = () => ({
    type: SET_MANAGER_BETSHOPS_ACTION_BEFORE,
});

const setManagerBetshopsActionFinished = () => ({
    type: SET_MANAGER_BETSHOPS_ACTION_FINISH,
});

const setManagerBetshopsSaveActionBefore = () => ({
    type: SET_MANAGER_BETSHOPS_SAVE_ACTION_BEFORE,
});

const setManagerBetshopsSaveActionFinished = () => ({
    type: SET_MANAGER_BETSHOPS_SAVE_ACTION_FINISH,
});

const setManagerBetshops = betshops => ({
    type: SET_MANAGER_BETSHOPS,
    payload: { betshops },
});

export const setManagerBetshopsSorting = sorting => ({
    type: SET_MANAGER_BETSHOPS_SORTING,
    payload: { sorting },
});

export const setManagerBetshopsFilters = filters => ({
    type: SET_MANAGER_BETSHOPS_FILTERS,
    payload: { filters },
});


export const getManagerBetshops = () => {
    return (dispatch, getState) => {
        const languageCode = LanguageUtils.getSelectedLanguage();

        let params = {
            ...getState().managerBetshops.sorting,
            ...getState().managerBetshops.filters,
            languageCode
        };
        
        dispatch(setManagerBetshopsActionBefore());

        return axios({
            url: ApiUrls.GET_MANAGER_BETSHOPS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: betshops } }) => {
                dispatch(setManagerBetshops(betshops));
                dispatch(setManagerBetshopsActionFinished());
            })
            .catch((ex) => {
                dispatch(setManagerBetshopsActionFinished());
            });
    }
}


const setManagerBetshopAvailableCashiersActionBefore = () => ({
    type: SET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_ACTION_BEFORE,
});

const setManagerBetshopAvailableCashiersActionFinished = () => ({
    type: SET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_ACTION_FINISH,
});

const setManagerBetshopAvailableCashiersForAdd = cashiers => ({
    type: SET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_FOR_ADD,
    payload: { cashiers },
});

const setManagerBetshopAvailableCashiersForTransfer = cashiers => ({
    type: SET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_FOR_TRANSFER,
    payload: { cashiers },
});

export const getManagerBetshopAvailableCashiersForAdd = id => {
    return dispatch => {
        
        dispatch(setManagerBetshopAvailableCashiersActionBefore());

        return axios({
            url: ApiUrls.GET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_FOR_ADD,
            method: Methods.GET,
            params: { id },
        })
            .then(({ data: { value: cashiers } }) => {
                dispatch(setManagerBetshopAvailableCashiersForAdd(cashiers));
                dispatch(setManagerBetshopAvailableCashiersActionFinished());
            })
            .catch((ex) => {
                dispatch(setManagerBetshopAvailableCashiersActionFinished());
            });
    }
}

export const getManagerBetshopAvailableCashiersForTransfer = id => {
    return dispatch => {
        
        dispatch(setManagerBetshopAvailableCashiersActionBefore());

        return axios({
            url: ApiUrls.GET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_FOR_TRANSFER,
            method: Methods.GET,
            params: { id },
        })
            .then(({ data: { value: cashiers } }) => {
                dispatch(setManagerBetshopAvailableCashiersForTransfer(cashiers));
                dispatch(setManagerBetshopAvailableCashiersActionFinished());
            })
            .catch((ex) => {
                dispatch(setManagerBetshopAvailableCashiersActionFinished());
            });
    }
}

/** Manager avaialable Betshops */

const setManagerAvailableBetshopsActionBefore = () => ({
    type: SET_MANAGER_BETSHOP_AVAILABLE_BETSHOPS_ACTION_BEFORE,
});

const setManagerAvailableBetshopsActionFinished = () => ({
    type: SET_MANAGER_BETSHOP_AVAILABLE_BETSHOPS_ACTION_FINISH,
});

const setManagerAvailableBetshops = betshops => ({
    type: SET_MANAGER_BETSHOP_AVAILABLE_BETSHOPS,
    payload: { betshops },
});

export const getManagerAvailableBetshops = () => {
    return dispatch => {
        const languageCode = LanguageUtils.getSelectedLanguage();
        
        dispatch(setManagerAvailableBetshopsActionBefore());

        return axios({
            url: ApiUrls.GET_MANAGER_BETSHOP_AVAILABLE_BETSHOPS,
            method: Methods.GET,
            params: {
                languageCode
            },
        })
            .then(({ data: { value: betshops } }) => {
                dispatch(setManagerAvailableBetshops(betshops));
                dispatch(setManagerAvailableBetshopsActionFinished());
            })
            .catch((ex) => {
                dispatch(setManagerAvailableBetshopsActionFinished());
            });
    }
}