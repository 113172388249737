import {
    CHANGE_POPUP_VISIBILITY
} from "store/actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
     
        case CHANGE_POPUP_VISIBILITY:
            return {
                ...state,
                popup: {
                  ...payload
                }
            }
        default:
            return state;
    }
};
