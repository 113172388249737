import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import { AutoComplete as AntAutoComplete, Input } from 'antd';
import autoSuggestionType from 'types/autoSuggestion.type';

/** AutoComplete Component */
const AutoComplete = ({
    placeholder,
    items,
    onSelect,
    ...props
}) => {
    const [search, setSearch] = useState("");

    const cleared = useRef(false);

    useEffect(() => {
        if (!search && !cleared.current) {
            cleared.current = true;
            onSelect?.('');
        }
    }, [search]);

    const handleSelect = (value) => {
        cleared.current = value === "";
        onSelect?.(value);
    }

    /** Get Filtered Options
       * @function
       * @returns {array}
       * @description filters items by search value, and sort them according to search value position on string
       * @memberOf AutoComplete
   */
     const getOptions = () => {

        const shouldInclude = item => {
            if(!item) return false;
            const findValue = search.toLowerCase();
            const idValue = item.id?.toString()?.toLowerCase() ?? "";
            const longIdValue = item.longId?.toString()?.toLowerCase() ?? "";
            const nameValue = item.name?.toString()?.toLowerCase() ?? "";
            return (
                idValue === findValue ||
                longIdValue === findValue ||
                nameValue.includes(findValue)
            )
        }

        const filtered = search ? items.filter(shouldInclude): [];

        filtered.sort((a, b) => {
            let aIndex = 1000000; // too big number
            let bIndex = 1000000;
            const findValue = search.toLowerCase();
            const aNameValue = a.name?.toString()?.toLowerCase() ?? "";
            const bNameValue = b.name?.toString()?.toLowerCase() ?? "";

            if (aNameValue.includes(findValue)) {
                aIndex = aNameValue.indexOf(findValue);
            }
            if (bNameValue.includes(findValue)) {
                bIndex = bNameValue.indexOf(findValue);
            }

            return aIndex >= bIndex ? 1 : -1;
        });

        return filtered.map(f => ({value: f.name}));
    }

    return (
        <AntAutoComplete
            options={getOptions()}
            onSearch= {setSearch}
            onSelect={handleSelect}
            allowClear={true}
            onClear={() => {
                handleSelect("")
            }}
            getPopupContainer={trigger => trigger.parentNode}
            { ...props }
        >
            <Input
                className='rt--input'
                placeholder={ placeholder }
                // onPressEnter={e => {
                //     const value = e.target.value;
                //     onSelect && onSelect(value)
                // }}
            />
        </AntAutoComplete>
    )
}

/** AutoComplete propTypes
    * PropTypes
*/
AutoComplete.propTypes = {
    /** Autocomplete data */
    items: PropTypes.arrayOf(autoSuggestionType),
    /** Placeholder of input */
    placeholder: PropTypes.string,
    /** Selected option */
    value: PropTypes.string,
    /** Called when select an option or input value change, or value of input is changed */
    onChange: PropTypes.func,
    /** Called when select an option  */
    onSelect: PropTypes.func
}

export default AutoComplete
