import React, { useEffect, useState } from 'react';

import { listenBroadcast } from 'utils/postMessage';
import LocalStorageUtils from "utils/localStorage"

import { BROADCAST_CHANNEL_NAME } from 'constants/common.constants';

/** Mirror Page Component */
const MirrorComponent = () => {
    /** Screenshot src */
    const [imgSrc, setimgSrc] = useState('')
    
    /** Add broadcast listener, to get images from main page */
    useEffect(() => {
        let count = +(LocalStorageUtils.get(BROADCAST_CHANNEL_NAME) || 0)
        LocalStorageUtils.set(BROADCAST_CHANNEL_NAME, ++count)
        
        listenBroadcast(({ payload, type }) => {
            if (type === 'update') {
                setimgSrc(payload)
            }
        })

        /** Decrease opened page counter on window close */
        window.addEventListener('beforeunload', (evt) => {
            let count = +(LocalStorageUtils.get(BROADCAST_CHANNEL_NAME) || 0)
            LocalStorageUtils.set(BROADCAST_CHANNEL_NAME, --count)
        });
    
    }, [])

    /** Disable scaling */
    useEffect(() => {
        document.body.classList.add("rt--disable-scale")
    }, [])

    return (
        <div className="rt--mirror rt--flex rt--align-center rt--justify-center">
            { imgSrc && <img className='rt--mirror-img' src={imgSrc} alt="" /> }
        </div>
    )
}

export default MirrorComponent;