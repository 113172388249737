import { ORDER_DIRECTION } from 'constants/common.constants';

import { getDefaultDate } from "utils/dateTime";

import {
    SET_TRANSACTIONS_ACTION_BEFORE,
    SET_TRANSACTIONS_ACTION_FINISH,
    SET_TRANSACTIONS,
    SET_TRANSACTIONS_SORTING,
    SET_TRANSACTIONS_FILTERS,
    RESET_TRANSACTIONS
} from "store/actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_TRANSACTIONS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_TRANSACTIONS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_TRANSACTIONS:
            return {
                ...state,
                transactions: payload.transactions.item2,
                total: payload.transactions.item1,
            };

        case SET_TRANSACTIONS_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [payload.type]: {
                        ...state.filters[payload.type],
                        ...payload.filters
                    }
                },
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            };
        case SET_TRANSACTIONS_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        case RESET_TRANSACTIONS:
            return {
                transactions: [],
                isLoading: false,
                total: 0,
                sorting: {
                    page: 1,
                    limit: 20,
                    orderBy: "TransactionTime",
                    orderDirection: ORDER_DIRECTION.DESC
                },
                filters: {
                    ...state.filters,
                    [payload.type]: {
                        transactionId: "",
                        betShopId: "",
                        transactionTypes: "",
                        cashier: "",
                        period: "24_hours",
                        ...getDefaultDate()
                    }
                },
            };
        default:
            return state;
    }
};