import React from "react";
import { useTranslation } from "react-i18next";

import NoTicketImg from "assets/images/noTicket.png"

const NotFoundView = () => {
    const { t } = useTranslation();

    return (
        <div className="rt--flex rt--flex-col rt--align-center rt--justify-center rt--pt-20 rt--pb-20">
            <img alt="icon" src={NoTicketImg} />
            <b className="rt--title rt--font-normal rt--font-medium rt--pt-8 rt--pb-8 rt--modal-transfer-player-search-info">
                {t("common.playerNotFound")}
            </b>
        </div>
    );
};

export default NotFoundView;
