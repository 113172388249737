import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { launch } from 'store/actions/dashboard/providers.action';

import { PROJECT_PROVIDER_TYPE } from 'constants/common.constants';

import LanguageUtils from 'utils/languages';

/** Virtual Sport Iframe Component */
const VirtualSportComponent = ({
    providers,
    launch,
}) => {

    /** Current language */
    const langCode = LanguageUtils.getSelectedLanguage();

    //launch(PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS, langCode);

    /** Virtual Sport provider data */
    const data = providers[PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS];

    const launchUrl = data?.launchUrl ?? null;

    /** Detect logout */
    useEffect(() => {
        window.addEventListener("message", event => {
            if (event && event.data) {
                try {
                    const type = event.data.type;
                    if (type === "vs-logout") {
                        launch(PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS, langCode)
                    }
                } catch (ex) { 
                    return;
                }
            }
        })
    }, [])

    return (
        <div className='rt--provider-virtual-sport'>
            {
                launchUrl && (
                    <iframe
                        src={launchUrl}
                        allowFullScreen
                        frameBorder="0"
                        title="Virtual Sport"
                    ></iframe>
                )
            }

        </div>

    )
}

/** VirtualSportComponent propTypes
    * PropTypes
*/
VirtualSportComponent.propTypes = {
    /** Redux state property, providers data */
    providers: PropTypes.object,
    /** Redux action to launch provider */
    launch: PropTypes.func,
}

const mapStateToProps = state => {
    return {
        providers: state.providers.providers,
    }
}

const mapDispatchToProps = dispatch => (
    {
        launch: (id, langCode) => {
            dispatch(launch(id, langCode))
        }
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(VirtualSportComponent)