import React from 'react';

import BetshopPerformanceReportComponent from 'components/manager/betshopPerformanceReport';

import withAuth from 'hocs/withAuth';

const ManagerBetshopPerformanceReportRoute = () => {
    return (
        <BetshopPerformanceReportComponent isCashierReport={false} /> 
    )
}

export default withAuth(ManagerBetshopPerformanceReportRoute);