import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Outlet } from "react-router-dom";
import { ConfigProvider } from 'antd'
import moment from "moment";

import Global from "components/common/global";
import Loader from 'components/common/loader';
import PopupComponent from 'components/common/popup';
import LeftBarMenu from 'components/manager/leftBar';
import BetshopHeader from 'components/manager/header';

import LanguageUtils from 'utils/languages';

import 'moment/dist/locale/ru';
import 'moment/dist/locale/fr';
import 'moment/dist/locale/es';
import 'moment/dist/locale/fa';
import 'moment/dist/locale/en-gb';
import 'moment/dist/locale/ko';
import 'moment/dist/locale/zh-cn';
import 'moment/dist/locale/hi';
import 'moment/dist/locale/bn-bd';
import 'moment/dist/locale/he';

import enLang from 'antd/es/locale/en_US';
import frLang from 'antd/es/locale/fr_FR';
import esLang from 'antd/es/locale/es_ES';
import ruLang from 'antd/es/locale/ru_RU';
import faLang from 'antd/es/locale/fa_IR';
import koLang from 'antd/es/locale/ko_KR';
import zhLang from 'antd/es/locale/zh_CN';
import hiLang from 'antd/es/locale/hi_IN';
import bnLang from 'antd/es/locale/bn_BD';
import heLang from 'antd/es/locale/he_IL';

const languageCode = LanguageUtils.getSelectedLanguage().toLowerCase();
const languagesMapping = {
    "en": enLang,
    "fr": frLang,
    "es": esLang,
    "ru": ruLang,
    "fa": faLang,
    "ko": koLang,
    "zh": zhLang,
    "hi": hiLang,
    "bn": bnLang,
    "he": heLang,
}

const mapLocaleToMomentLocale = {
    "en": "en",
    "fr": "fr",
    "es": "es",
    "ru": "ru",
    "fa": "fa",
    "ar": "ar",
    "ko": "ko",
    "zh": "zh-cn",
    "hi": "hi",
    "bn": "bn-bd",
    "he": "he",
}

const locale = languagesMapping[languageCode] || enLang;
moment.locale(mapLocaleToMomentLocale[languageCode]);

/** Betshop Manager Layout Component */
const Layout = ({
    translationsLoaded
}) => {
    return (
        (
            <ConfigProvider
                getPopupContainer={trigger => trigger && trigger.parentNode ? trigger.parentNode : document.body}
                locale={locale}
            >
                <div className='rt--main-layout'>
                    <Global />
                    {
                        translationsLoaded ? (
                            <div className='rt--betshop-manager rt--flex'>
                                <LeftBarMenu />
                                <div className='rt--betshop-manager-block rt--flex rt--flex-col rt--flex-equal'>
                                    <BetshopHeader />
                                    <div className='rt--betshop-manager-content rt--pr-16 rt--pl-24 rt--flex rt--flex-col rt--flex-equal'>
                                        <Outlet />
                                    </div>
                                </div>
                            </div>
                        ) : <Loader fixed={true} />
                    }
                </div>
                <PopupComponent />
            </ConfigProvider>
        )
    )

}

/** Layout propTypes
    * PropTypes
*/
Layout.propTypes = {
    /** Redux state property , is true when translations are loaded*/
    translationsLoaded: PropTypes.bool
}

const mapStateToProps = state => {
    return {
        translationsLoaded: state.auth.translationsLoaded
    }
}

export default connect(mapStateToProps, null)(Layout);

