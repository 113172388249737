import React, { useEffect, useState,useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';

import OutsideAlerter from 'components/common/outsideAlerter';

/** Dropdown Component */
const Dropdown = forwardRef(({
    element,
    items,
    onClick,
    header,
    onClose,
    dropdownContent,
    overlayClassName=""
}, ref) => {

    /** Flag to show/hide menu */
    const [showMenu, setShowMenu] = useState(false)

    /** Function, handler for menu click
		 * @function
		 * @param {string} key - clicked item key
		 * @memberOf Dropdown
    */
    const handleClick = key => {
        onClick && onClick(key);

        const item = items.find(i => i.key === key);
        if (item) {
            if (!item.hasSub) {
                setShowMenu(false);
            }
        }
    }

    /** Call onClose function on menu close */
    useEffect(() => {
        if(!showMenu){
            onClose && onClose()
        }
    }, [showMenu, onClose])


    const renderDropdownContent = () => {
        if(dropdownContent){
            return (
                <div className={'rt--dropdown-menu ' + overlayClassName}>
                    { dropdownContent }
                </div>
            )
        } 

        return (
            <div className={'rt--dropdown-menu ' + overlayClassName}>
                {
                    header && (
                        <div className='rt--dropdown-menu-header rt--pl-8 rt--pr-8 rt--pb-8 rt--pt-8'>
                            {header}
                        </div>
                    )
                }
                <div className='rt--dropdown-menu-lang rt--pt-8 rt--pb-8'>
                    {
                        items.map(item => (
                            <div
                                className={
                                    'rt--dropdown-menu-item rt--pl-16 rt--pr-8 rt--flex rt--align-center rt--justify-between' +
                                    (item.disabled ? " rt--dropdown-menu-item-disabled" : "")
                                }
                                key={item.key}
                                onClick={() => handleClick(item.key)}
                            >
                                <span className='rt--title rt--font-normal rt--font-regular'>{item.label}</span>
                                {
                                    item.hasSub && (
                                        <i className='icon-right rt--font-bigest' />
                                    )
                                }
                            </div>
                        ))
                    }
                </div>

            </div>
        )
    }

    const canOpenDropdown = dropdownContent || items.length > 0

    useImperativeHandle(ref, () => {
        return {
          close() {
            setShowMenu(false)
          },
          open() {
            setShowMenu(true)
          },
        };
      }, []);


    return (
        <OutsideAlerter
            callback={() => setShowMenu(false)}
        >
            <div className={'rt--dropdown' + (showMenu ? " rt--dropdown-opened" : "")}>
                <div 
                    className={ 'rt--dropdown-element rt--flex rt--align-center' + (!canOpenDropdown ? " rt--dropdown-element-disabled" : "") }
                    onClick={() => {
                        if(canOpenDropdown){
                            setShowMenu(!showMenu)
                        }
                    }}
                >
                    {element}
                </div>
                {
                    showMenu && renderDropdownContent()
                }

            </div>

        </OutsideAlerter>
    )
})

/** Dropdown propTypes
    * PropTypes
*/
Dropdown.propTypes = {
    /** Drowdown clickable element */
    element: PropTypes.node,
    /** Menu items array */
    items: PropTypes.arrayOf(PropTypes.shape({
        /** Item element key */
        key: PropTypes.string,
        /** Item element label */
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        /** Does the element has sub items */
        hasSub: PropTypes.bool,
    })),
    /** Function to call on menu item click */
    onClick: PropTypes.func,
    /** Header element for menu */
    header: PropTypes.node,
    /** Function to call on menu close */
    onClose: PropTypes.func,
    /** DropdownContent */
    dropdownContent: PropTypes.node,
    /** Overlay Classname */
    overlayClassName: PropTypes.string
}

export default Dropdown;