export const TICKET_GENERAL_DETAILS = {
    BETSHOP_NAME: 1,
    BETSHOP_ADDRESS: 2,
    CASHIER_USERNAME: 4,
    TICKET_ID: 8
}

export const TICKET_BET_DETAILS = {
    BET_AMOUNT: 1,
    BET_TYPE: 2,
    FACTOR: 4,
    POSSIBLE_WIN: 8,
    WIN_AMOUNT: 16,
    CALCULATION_DATE: 32,
    BET_STATUS: 64,
    PAYOUT_TIME: 128,
    PAYOUT_BY: 256
}
