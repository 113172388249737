import React, { Fragment, useEffect } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import moment from 'moment/moment';

import { useTranslation } from 'react-i18next';

import { printElement } from 'utils/common';
import { getUser } from 'utils/auth';

import { PLAYER_TRANSACTION_TYPE, DATE_TIME_FORMAT } from 'constants/common.constants';

import userInfoType from 'types/userInfo.type';
import playerInfoType from 'types/playerInfo.type';

import Logo from "assets/images/logo-blue.svg"

/** Transfer Ticket Component */
const TransferTicket = ({
    userInfo,
    playerInfo,
    amount,
    transferType
}) => {
    const { t } = useTranslation();

    const companyLongId = getUser()?.companyLongId ?? null;
    const projectLongId = getUser()?.projectLongId ?? null;

    /** Ticket details */
    const ticketDetails = userInfo.payoutTicket;

    /** Print Ticket */
    useEffect(() => {
        if (amount && playerInfo.id) {
            printElement('rt--print-wrapper', () => {
                console.log("printed")
            })
        }
    }, [])

    return (
        <Fragment>
            {
                <div id="rt--print-wrapper" className="rt--print-wrapper">
                    <div className="rt--print">
                        <div className="rt--print-header rt--mb-16">
                            {
                                ticketDetails.ticketLogoId ?
                                <img src={`${import.meta.env.SYSTEM_CDN_URL}/companies/${companyLongId}/projects/${projectLongId}/images/${ticketDetails.ticketLogoId}_ticket_logo.png`} alt="logo" /> :
                                <img src={Logo} alt="logo" />
                            }
                            <div className='rt--mt-16'>
                                <b className='rt--print-header-title rt--font-big rt--font-bold'>{transferType === PLAYER_TRANSACTION_TYPE.DEPOSIT ? t("common.deposit") : t("common.withdraw")}</b>
                            </div>

                        </div>

                        <div className="rt--print-info rt--print-info-dashed rt--pt-4 rt--pb-4">

                            <div className="rt--print-info-row rt--mb-4">
                                <span>{t('common.betshopName')}</span><span>{userInfo.betShopName}</span>
                            </div>

                            <div className="rt--print-info-row rt--mb-4">
                                <span>{t('common.date')}</span><span>{moment.utc().local().format(DATE_TIME_FORMAT)}</span>
                            </div>

                            <div className="rt--print-info-row rt--mb-4">
                                <span>{t('common.cashier')}</span><span>{userInfo.cashier?.userName ?? ""}</span>
                            </div>

                            <div className="rt--print-info-row rt--mb-4">
                                <span>{t('common.status')}</span><span>{t('common.successfull')}</span>
                            </div>
                        </div>

                        <div className="rt--print-info rt--print-info-dashed rt--pt-4 rt--pb-4">
                            <div className="rt--print-info-row rt--mb-4">
                                <b>{t("common.playerDetails")}</b>
                            </div>

                            {
                                playerInfo.firstName && playerInfo.lastName && (
                                    <div className="rt--print-info-row rt--mb-4">
                                        <span>{t('common.name')}</span><span>{`${playerInfo.firstName} ${playerInfo.lastName}`}</span>
                                    </div>
                                )
                            }

                            <div className="rt--print-info-row rt--mb-4">
                                <span>{t('common.username')}</span><span>{playerInfo.userName}</span>
                            </div>
                            <div className="rt--print-info-row rt--mb-4">
                                <span>{t('common.currency')}</span><span>{playerInfo.currencyCode}</span>
                            </div>
                        </div>

                        <div className="rt--print-info rt--pt-8 rt--pb-8">
                            <div className="rt--print-info-row">
                                <span>{t('common.amount')}</span>
                                <b>{`${amount} ${playerInfo.currencyCode}`}</b>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Fragment>

    )
}

/** TransferTicket propTypes
    * PropTypes
*/
TransferTicket.propTypes = {
    /** Redux state property, current user info */
    userInfo: userInfoType,
    /** Redux state property, player info */
    playerInfo: playerInfoType,
    /** Transfered amount */
    amount: PropTypes.number,
    /** Transfer type(Deposit/Withdraw) */
    transferType: PropTypes.oneOf(Object.values(PLAYER_TRANSACTION_TYPE))
}

const mapStateToProps = state => {
    return {
        userInfo: state.profile.userInfo,
        playerInfo: state.players.playerInfo
    }
}


export default connect(mapStateToProps, null)(TransferTicket)
