import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Input } from "antd";

import Withdrawals from './withdrawals';
import FavoriteAmounts from 'components/common/favoriteAmounts';

import { getUser } from 'utils/auth';
import { hasDotAndDotIsAtTheEnd } from 'utils/common';

import { ENVIRONMENT_TYPE, WALLET_OWNER_TYPE } from 'constants/common.constants';
import { INTEGER_AND_DECIMAL_NUMBERS_REGEX } from 'constants/regex.constants';

import userInfoType from 'types/userInfo.type';

/** Player Transfer Withdraw Form Component */
const WithDrawForm = ({
    setFormValues,
    verificationCode,
    userInfo
}) => {
    const { t } = useTranslation();

    const currency = userInfo?.currencies?.[0] ?? null;

    /** Amount field value */
    const [amount, setAmount] = useState("");
    /** Note field value */
    const [note, setNote] = useState("");
    /** Withdraw Id field value */
    const [withdrawId, setWithdrawId] = useState(null);


    /** Update form values for parent component */
    useEffect(() => {
        setFormValues({
            amount: amount,
            note: note,
            withdrawId: withdrawId
        })
    }, [amount, note, withdrawId])

    /** Function, handler for amount field change
         * @function
         * @param {string} id - ticket ID
         * @memberOf WithDrawForm
    */
    const handleAmountChange = value => {
        if (value === '' || hasDotAndDotIsAtTheEnd(value) || INTEGER_AND_DECIMAL_NUMBERS_REGEX.test(value)) {
            setAmount(value)
        }
    }

    /** Function, handler for withdrawal field change
         * @function
         * @param {object} withdrawal - withdrawal
         * @memberOf WithDrawForm
    */
    const handleWithdrawalChange = withdrawal => {
        if(withdrawal){
            setWithdrawId(withdrawal.id);
            setAmount(withdrawal.amount);
        }
    }

    const handleFavAmountClick = value => {
        const currentValue = amount ?? 0;
        setAmount(Number(currentValue) + value);
    }

    return (
        <div>
            {
                getUser()?.environmentType === ENVIRONMENT_TYPE.RETAIL ? (
                    <Withdrawals
                        onChange={handleWithdrawalChange}
                        verificationCode={verificationCode}
                    />
                ) : (
                    <div className='rt--modal-form-item rt--flex rt--flex-col rt--mb-16'>
                        <span className='rt--title rt--font-regular rt--font-normal rt--mb-8'>{t("common.amount")}</span>
                        <Input
                            className='rt--input'
                            placeholder={`${t("common.enter")} ${t("common.amount")}`}
                            value={amount}
                            onChange={e => handleAmountChange(e.target.value)}
                        />
                    </div>
                )
            }

            <div className='rt--modal-form-item rt--flex rt--flex-col'>
                <FavoriteAmounts 
                    currencyCode={currency?.code}
                    handleClick={handleFavAmountClick}
                    walletUserType={WALLET_OWNER_TYPE.PLAYER}
                />
            </div>


            <div className='rt--modal-form-item rt--flex rt--flex-col'>
                <span className='rt--title rt--font-regular rt--font-normal rt--mb-8'>{t("common.note")}</span>
                <Input.TextArea
                    className='rt--input'
                    placeholder={`${t("common.enter")} ${t("common.note")}`}
                    value={note}
                    onChange={e => setNote(e.target.value)}
                />
            </div>
        </div>
    )
}

/** WithDrawForm propTypes
    * PropTypes
*/
WithDrawForm.propTypes = {
    /** Function to call to update form values in parent component */
    setFormValues: PropTypes.func,
    /** The verification code */
    verificationCode: PropTypes.string,
    /** Redux state property, current user info */
    userInfo: userInfoType
}

const mapStateToProps = state => {
    return {
        userInfo: state.profile.userInfo
    }
}

export default connect(
    mapStateToProps,
    null
)(
    WithDrawForm
);