import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Modal, Input, Select, Form, Button, message } from "antd";

import { getPlayerInfo } from "store/actions/dashboard/players.action";
import { setPlayer } from "store/actions/dashboard/userInfo.action";

import NotFoundView from "./notFoundView";

import { getUser } from "utils/auth";

import { ENVIRONMENT_TYPE, PLAYER_FIND_BY } from "constants/common.constants";
import { POPUP_SIZE } from "constants/popup.constants";
import {
    EMAIL_REGEX,
    TEL_REGEX,
    USERNAME_REGEX,
} from "constants/regex.constants";

const INPUT_DATA_BY_SEARCH_WAY = {
    [PLAYER_FIND_BY.ID]: {
        label: "playerId",
        fieldName: "externalId",
        rule: { max: 100 },
    },
    [PLAYER_FIND_BY.USERNAME]: {
        label: "username",
        fieldName: "userName",
        rule: { pattern: USERNAME_REGEX },
    },
    [PLAYER_FIND_BY.EMAIL]: {
        label: "email",
        fieldName: "email",
        rule: { pattern: EMAIL_REGEX },
    },
    [PLAYER_FIND_BY.MOBILE]: {
        label: "phoneNumber",
        fieldName: "mobile",
        rule: { pattern: TEL_REGEX },
    },
    [PLAYER_FIND_BY.DOCUMENT]: {
        label: "document",
        fieldName: "documentNumber",
        rule: { max: 100 },
    },
};

const GetPlayerPopupComponent = ({
    handleCloseModal,
    isLoading,
    getPlayerInfo,
    setPlayer,
}) => {
    const { t } = useTranslation();

    const [isNotFoundViewVisible, setIsNotFoundViewVisible] = useState(false);

    const isRetail = getUser()?.environmentType === ENVIRONMENT_TYPE.RETAIL;

    const handleFormFinish = (data) => {
        const handleSuccessfulSearch = (playerInfo) => {
            if (!playerInfo) {
                setIsNotFoundViewVisible(true);
                return;
            }

            setPlayer({
                id: playerInfo.id,
                longId: playerInfo.longId,
                userName: playerInfo.userName,
            });
            handleCloseModal();
        };

        getPlayerInfo(data, handleSuccessfulSearch);
    };

    return (
        <Modal
            className="rt--modal rt--modal-withdrawals"
            title={
                <div className="rt--flex rt--justify-between rt--align-center">
                    <span className="rt--title rt--font-normal rt--font-regular">
                        {t("common.getPlayer")}
                    </span>
                    <i
                        className="icon-close rt--font-bigest rt--cursor-pointer"
                        onClick={() => handleCloseModal()}
                    />
                </div>
            }
            visible={true}
            closable={false}
            maskClosable={false}
            getContainer={() => document.getElementById("rt--modal-root")}
            width={POPUP_SIZE.SMALL}
            centered
            footer={null}
        >
            <Form
                className="rt--form"
                onFinish={handleFormFinish}
                colon={false}
                requiredMark={false}
                layout="vertical"
                preserve={false}
                initialValues={{
                    findBy: PLAYER_FIND_BY.ID,
                }}
            >
                <Form.Item
                    name="findBy"
                    className="rt--modal-form-item"
                    validateFirst
                    label={
                        <span className="rt--title rt--font-regular rt--font-normal">
                            {t("common.findBy")}
                        </span>
                    }
                >
                    <Select
                        placeholder={`${t("common.select")} ${t("common.findBy")}`}
                        className="rt--select"
                        showSearch={false}
                        suffixIcon={<i className="icon-down rt--font-bigest" />}
                        getPopupContainer={(trigger) => trigger.parentNode}
                    >
                        <Select.Option value={PLAYER_FIND_BY.ID}>
                            {t("common.playerId")}
                        </Select.Option>
                        <Select.Option value={PLAYER_FIND_BY.USERNAME}>
                            {t("common.username")}
                        </Select.Option>
                        {isRetail && (
                            <Select.Option value={PLAYER_FIND_BY.EMAIL}>
                                {t("common.email")}
                            </Select.Option>
                        )}
                        {isRetail && (
                            <Select.Option value={PLAYER_FIND_BY.MOBILE}>
                                {t("common.phoneNumber")}
                            </Select.Option>
                        )}
                        {isRetail && (
                            <Select.Option value={PLAYER_FIND_BY.DOCUMENT}>
                                {t("common.document")}
                            </Select.Option>
                        )}
                    </Select>
                </Form.Item>

                <Form.Item shouldUpdate noStyle>
                    {({ getFieldValue }) => {
                        const findBy = getFieldValue("findBy");

                        const inputData = INPUT_DATA_BY_SEARCH_WAY[findBy];

                        return (
                            <Form.Item
                                name={inputData.fieldName}
                                className="rt--modal-form-item"
                                validateFirst
                                label={
                                    <span className="rt--title rt--font-regular rt--font-normal">
                                        {`${t(`common.${inputData.label}`)} *`}
                                    </span>
                                }
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: t("validation.field_required"),
                                    },
                                    { ...inputData.rule, message: t("validation.field_invalid") },
                                ]}
                            >
                                <Input
                                    placeholder={`${t("common.select")} ${t(
                                        `common.${inputData.label}`
                                    )}`}
                                    className="rt--input"
                                />
                            </Form.Item>
                        );
                    }}
                </Form.Item>

                <Form.Item>
                    <Button
                        className="rt--button rt--button-primary rt--width-100 rt--mt-10"
                        htmlType="submit"
                        loading={isLoading}
                    >
                        <span className="rt--font-normal rt--font-regular">
                            {t("common.getPlayer")}
                        </span>
                    </Button>
                </Form.Item>
            </Form>

            {isNotFoundViewVisible && <NotFoundView />}
        </Modal>
    );
};

GetPlayerPopupComponent.propTypes = {
    /** Function to close popup */
    handleCloseModal: PropTypes.func,
    /** Redux state property, is true when loading player info  */
    isLoading: PropTypes.bool,
    /** Redux action to load player info */
    getPlayerInfo: PropTypes.func,
    /** Redux action to set current player info */
    setPlayer: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
    getPlayerInfo: (data, onSuccess) => {
        dispatch(getPlayerInfo(data, onSuccess));
    },
    setPlayer: (playerInfo) => {
        dispatch(setPlayer(playerInfo));
    },
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.players.isLoading,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GetPlayerPopupComponent);
