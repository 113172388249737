import PropTypes from 'prop-types';

import { PROJECT_PROVIDER_TYPE, ENVIRONMENT_TYPE } from 'constants/common.constants'

export default PropTypes.shape({
    languageId: PropTypes.string,
    languageFilePath: PropTypes.string,
    currency: PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
        symbol: PropTypes.string
    }),
    cashier: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        userName: PropTypes.string
    }),
    betShopId: PropTypes.string,
    projectId: PropTypes.string,
    balance: PropTypes.number,
    limit: PropTypes.number,
    betShopName: PropTypes.string,
    address: PropTypes.string,
    projectType: PropTypes.number,
    enviromentType: PropTypes.oneOf(Object.values(ENVIRONMENT_TYPE)),
    providers: PropTypes.arrayOf(PropTypes.oneOf(Object.values(PROJECT_PROVIDER_TYPE))),
    securitySettings: PropTypes.shape({
        desktop: PropTypes.bool,
        android: PropTypes.bool,
        ios: PropTypes.bool
    }),
    cssId: PropTypes.number,
    logoId: PropTypes.number,
    payoutTicket: PropTypes.shape({
        generalDetails: PropTypes.number,
        betDetails: PropTypes.number,
        customText: PropTypes.string,
        enableBarcodePrinting: PropTypes.bool,
        ticketLogoId: PropTypes.number,
        printPayoutTickets: PropTypes.bool
    }),
    isAgentBetShop: PropTypes.bool,
    additionalAccess: PropTypes.number
})